import * as React from 'react'

import styles from './Progress.module.css'

export const Progress = () => {
  return (
    <div
      className={styles.wrapper}
      role='progressbar'
      aria-label='loading...'
      data-testid='loading-progress'
    >
      <svg className={styles.svg} viewBox='-200 -200 400 400'>
        <g className={styles.g}>
          <circle className={styles.circle} r='130' pathLength='100' />
        </g>
      </svg>
    </div>
  )
}
