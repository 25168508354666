import { usePartiesByCheck, useParty } from '@local/cornucopia-subscriptions'
import { JoinOrCreatePartyMode } from '@local/shared-types'
import { track } from '@toasttab/do-secundo-analytics'
import { OPTPartyV3, joinOrCreateParty } from 'cornucopia-apis'
import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { constructRedirectURL } from '../../components/JoinCheck/JoinCheck.helpers'

import {
  CONTACT_VARIANT,
  ContactForm,
  ContactInfoValues,
  getContactFormInitialValues,
  validationSchema
} from '../../components/Contact'
import { JoinTabButton } from '../../components/Contact/ContactForm/JoinTabButton'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { JoinCheckHeader } from '../../components/JoinCheck/JoinCheckHeader'

export const JoinOpenTabPage = () => {
  const initialValues = getContactFormInitialValues()
  const { partyRefresh, refetch } = useParty()
  const { uniqueIdentifier } = useDDIGlobals()
  const { partiesByCheck } = usePartiesByCheck()
  const [searchParams] = useSearchParams()
  const [initialParty, setInitialParty] = React.useState<OPTPartyV3>()

  const handleOnSubmit = (
    values: ContactInfoValues,
    helpers: FormikHelpers<ContactInfoValues>
  ) => {
    joinOrCreateParty({
      user: { name: values.fullName, phoneNumber: values.phone },
      checkGuid: searchParams.get('checkGuid')!,
      mode: JoinOrCreatePartyMode.CREATE
    }).subscribe((result) => {
      track('Party started')
      if (result.kind === 'OK') {
        if (refetch) {
          refetch()
        }
      } else {
        helpers.setSubmitting(false)
      }
    })
  }

  React.useEffect(() => {
    if (partiesByCheck) {
      setInitialParty(partiesByCheck[0])
    }
  }, [setInitialParty, partiesByCheck])

  React.useEffect(() => {
    if (
      partyRefresh?.party?.guid &&
      partyRefresh?.party?.partyMemberGuid &&
      partyRefresh?.party?.memberAuthToken &&
      initialParty?.tableName
    ) {
      window.location.replace(
        constructRedirectURL({ party: partyRefresh.party, uniqueIdentifier })
      )
    }
  }, [partyRefresh, initialParty, uniqueIdentifier])

  return (
    <div data-testid='join-check-page'>
      <JoinCheckHeader party={initialParty} />
      {partyRefresh && !partyRefresh?.party?.tableName && (
        <div
          data-testid='no-table-cta'
          className='mx-6 px-4 py-3 bg-info-0 rounded type-subhead font-normal'
        >
          Oops, looks like there's no table assigned to this tab. Please ask
          your server for assistance.
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikBag) => handleOnSubmit(values, formikBag)}
        validationSchema={validationSchema}
      >
        <ContactForm
          disabled={initialParty && !initialParty?.tableName}
          submitButton={<JoinTabButton />}
          variant={CONTACT_VARIANT.QUICK_TABS}
        />
      </Formik>
    </div>
  )
}
