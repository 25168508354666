import { FormatCurrency } from '@local/format-currency'
import * as React from 'react'

interface SubtotalProps {
  subtotal: number
  className?: string
  subtotalString?: string
}

export const Subtotal = ({
  subtotal,
  className = 'flex justify-between px-4 py-3 bg-corn-gray-25',
  subtotalString = 'Subtotal'
}: SubtotalProps) => {
  return (
    <tr>
      <td>
        <div className={className}>
          <div className='font-bold type-default'>{subtotalString}</div>

          <div
            className='pl-2 font-bold text-right'
            data-testid='subtotal-price'
          >
            <FormatCurrency amount={subtotal} />
          </div>
        </div>
      </td>
    </tr>
  )
}
