import { Maybe } from '@local/shared-types'
import { addEventProperties as heapAddEventProperties } from '@toasttab/do-secundo-analytics'
import { authContext$, AuthContextType } from 'cornucopia-apis'
import * as React from 'react'
import { distinctUntilChanged } from 'rxjs/operators'

export const useAuthContext = () => {
  const [authContext, setAuthContext] =
    React.useState<Maybe<AuthContextType>>(null)

  React.useEffect(() => {
    const authContextSubscription = authContext$
      .pipe(distinctUntilChanged())
      .subscribe((nextContext: any) => {
        if (nextContext) {
          setAuthContext({ ...nextContext })
        }
      })

    if (heapAddEventProperties) {
      heapAddEventProperties({
        passwordless: true
      })
    }

    return () => {
      authContextSubscription.unsubscribe()
    }
    /* eslint-ignore react-hooks/exhaustive-deps */
  }, [])

  return { authContext, loading: !authContext }
}
