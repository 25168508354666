import { OptionButton } from '@local/option-button'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../../../src/app/OrderingRoutes'
import PayForMyItemsIcon from '../../../../src/assets/pay-for-my-items-icon.svg'
import {
  TransitionDirection,
  useTransitionContext
} from '../../../../src/components/TransitionProvider/TransitionContext'

export const SoloDinerPaymentOptions = () => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()

  return (
    <div data-testid='solo-diner-payment-drawer' className='pb-24 mt-8'>
      <div className='mx-6 mb-6 bg-white border border-solid rounded-lg'>
        <OptionButton
          icon={<PayForMyItemsIcon />}
          className='rounded-lg'
          text='Pay tab balance'
          onClick={() => navigate('/tab/close')}
        />
      </div>
      <div className='mx-6 mb-6 bg-white border border-solid rounded-lg'>
        <OptionButton
          className='rounded-lg'
          text='View check'
          onClick={() => {
            setTransition(TransitionDirection.ForwardDefault)
            navigate(ORDERING_ROUTES.CHECK_REVIEW)
          }}
        />
      </div>
    </div>
  )
}
