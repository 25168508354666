import { Button } from '@toasttab/buffet-pui-buttons'
import { PartyRefresh } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@local/cornucopia-subscriptions'
import { OptConfig } from '../../../app/types'
import {
  TransitionDirection,
  useTransitionContext
} from '../../../components/TransitionProvider/TransitionContext'
import { useFlag } from '../../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../../launchdarkly/flags'
import {
  countItemsInPartyCarts,
  getCTA,
  getPrimaryCheckFromOrder,
  getTabSpaRedirectUrl
} from './helpers'

export interface PartyReviewOrderButtonProps {
  optConfig: OptConfig
  partyRefresh: PartyRefresh | null
}

export const PartyReviewOrderButton = ({
  optConfig,
  partyRefresh
}: PartyReviewOrderButtonProps) => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()
  const { currentUser } = useCurrentUser()
  const noauthNoSplashEnabled = useFlag(LDFlags.NO_SPLASH_NOAUTH)

  if (!partyRefresh) {
    return null
  }

  const {
    orderingConfig: { allowJoinableParty, allowTabs },
    paymentConfig: { allowPreauth }
  } = optConfig
  const { carts, order } = partyRefresh

  const primaryCheck = getPrimaryCheckFromOrder(order)

  const tabsSpaRedirectUrl = getTabSpaRedirectUrl({
    primaryCheck,
    allowPreauth,
    allowTabs,
    allowJoinableParty,
    name: currentUser?.name,
    noauthNoSplashEnabled
  })
  const cta = getCTA(carts, allowJoinableParty, allowTabs)
  const numberOfSelections = countItemsInPartyCarts(carts)

  return (
    <Button
      className='flex justify-start w-full m-4 text-left'
      testId='button-checkout-party-tab-spa'
      onClick={() => {
        setTransition(TransitionDirection.ForwardDefault)
        navigate(tabsSpaRedirectUrl)
      }}
    >
      {cta} ({numberOfSelections})
    </Button>
  )
}
