import { Loading } from '@toasttab/do-secundo-loading'
import cx from 'classnames'
import * as React from 'react'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  'data-testid'?: string
  loading?: boolean
  type?: 'submit' | 'button' | 'reset'
}

export const Button = ({
  disabled,
  loading,
  type,
  children,
  ...props
}: React.PropsWithChildren<ButtonProps>) => {
  const buttonDisabled = disabled || loading

  return (
    <button
      className={cx(
        'font-sans w-full bg-primary-75 flex flex-row justify-center content-center py-3 rounded-lg text-white font-semibold leading-submitButton',
        { 'opacity-20': buttonDisabled }
      )}
      type={type}
      disabled={buttonDisabled}
      {...props}
    >
      {loading ? <Loading variant={'primary' as any} /> : children}
    </button>
  )
}
