import { useParty } from '@local/cornucopia-subscriptions'
import * as React from 'react'
import { DDIGlobals } from '../../app/types'
import { LDFlags } from '../../launchdarkly/flags'
import { getCartsWithItems } from '../../utils/cart-utils/helpers'
import { getAvailability } from './helpers'

export const DDIGlobalsContext = React.createContext<DDIGlobals>(
  {} as DDIGlobals
)

export const DDIGlobalsProvider = ({
  ddiGlobals,
  children
}: {
  ddiGlobals: DDIGlobals
  children: React.ReactNode
}) => {
  const unifiedOnboardingEnabled = ddiGlobals.enabledFeatureFlags?.includes(
    LDFlags.UNIFIED_ONBOARDING
  )

  const { partyRefresh } = useParty()

  const cartsWithItems = getCartsWithItems(partyRefresh)
  const hasItemsInCart = cartsWithItems && cartsWithItems.length > 0

  const orderingAvailable = getAvailability(
    ddiGlobals,
    unifiedOnboardingEnabled,
    Boolean(hasItemsInCart)
  )

  const ddiGlobalsWithAvailability = React.useMemo(() => {
    return {
      ...ddiGlobals,
      restaurantConfig: {
        ...ddiGlobals.restaurantConfig,
        orderingAvailable
      }
    }
  }, [orderingAvailable, ddiGlobals])

  return (
    <DDIGlobalsContext.Provider value={ddiGlobalsWithAvailability}>
      {children}
    </DDIGlobalsContext.Provider>
  )
}

export const useDDIGlobals = () => React.useContext(DDIGlobalsContext)
