import { Maybe } from '@local/shared-types'
import {
  ApiData,
  FinalMenuItemV2,
  PopularItem,
  popularItems$
} from 'cornucopia-apis'
import * as React from 'react'
import { normalize } from '../normalize'

export const useGetPopularItems = () => {
  const [popularItemsResponse, setPopularItemsResponse] = React.useState<
    Maybe<
      ApiData<
        Maybe<PopularItem[]>,
        {
          popularMenuItems: FinalMenuItemV2[]
        }
      >
    >
  >(null)

  React.useEffect(() => {
    const subscription = popularItems$.subscribe((popularItemData) => {
      setPopularItemsResponse(popularItemData)
    })

    return () => subscription.unsubscribe()
  })

  return normalize(popularItemsResponse)
}
