import { FormatCurrency } from '@local/format-currency'
import { Modifier } from '@local/shared-types'
import * as React from 'react'

export interface ModifierListProps {
  modifiers: Modifier[]
}

export const ModifierList = ({ modifiers }: ModifierListProps) => {
  return (
    <ul className='pl-5 text-secondary' data-testid='cart-item-modlist'>
      {modifiers.map(({ name, price }, index) => {
        const priceToRender = price ? (
          <span data-testid='modifier-price'>
            (<FormatCurrency amount={price} showLeadingSign />)
          </span>
        ) : null

        return (
          <li
            key={`${index}-${name}`}
            className='pt-1'
            data-testid={`modifier-${name}`}
          >
            {name} {priceToRender}
          </li>
        )
      })}
    </ul>
  )
}
