import { Maybe, Selection } from '@local/shared-types'
import { v4 as uuid } from 'uuid'
import { MenuGroupToRender, MenuToRender } from './MenuPage.helper'

// adds a reorder group of items from tabs to the menus
export const addReorderGroupToMenus = ({
  menus,
  selections,
  orderingAvailable
}: {
  menus: MenuToRender[]
  selections: Maybe<Selection[]>
  orderingAvailable: boolean
}): MenuToRender[] => {
  // if there are multiple menus, we create and entirely new menu
  // because it looks weird to add it to an existing one.
  if (menus.length > 1) {
    const reorderMenu = getReorderMenu({
      selections,
      orderingAvailable,
      id: 'REORDER_MENU'
    })
    if (!reorderMenu) {
      return menus
    }
    return [reorderMenu].concat(menus)
  }

  const menu = menus[0]

  if (!menu) {
    return menus
  }

  if (menu.groups.some((group) => group.guid === 'REORDER_MENU')) {
    return menus
  }

  // if there is 1 menu, we add a new menu group to the front of the menu
  const { id, groups } = menu

  const newGroups = addReorderGroupToMenuGroups({
    menuGroups: groups,
    selections,
    id
  })

  return [
    {
      ...menu,
      groups: newGroups
    }
  ]
}

// add a reorder menu group to the front of a list of menu groups
export const addReorderGroupToMenuGroups = ({
  menuGroups,
  selections,
  id = uuid()
}: {
  menuGroups: MenuGroupToRender[]
  selections: Maybe<Selection[]>
  id?: string
}) => {
  const reorderFromTabGroup = getReorderMenuGroup({ selections, id })
  if (!reorderFromTabGroup) {
    return menuGroups
  }
  return [reorderFromTabGroup].concat(menuGroups)
}

// creates a reorder menu
export const getReorderMenu = ({
  selections,
  id = 'REORDER_MENU',
  orderingAvailable
}: {
  selections: Maybe<Selection[]>
  id?: string
  orderingAvailable: boolean
}): Maybe<MenuToRender> => {
  const reorderFromTabGroup = getReorderMenuGroup({ selections, id })
  if (!reorderFromTabGroup || !orderingAvailable) {
    return null
  }
  return {
    id,
    name: 'Order again',
    isReorder: true,
    groups: [reorderFromTabGroup],
    description: '',
    __typename: 'MenuV2'
  }
}

// creates a reorder menu group
export const getReorderMenuGroup = ({
  selections,
  id = uuid()
}: {
  selections: Maybe<Selection[]>
  id?: string
}): Maybe<MenuGroupToRender> => {
  const selectionGuard = selections ? [...selections] : []
  const items = selectionGuard
    .reverse()
    .filter(
      (selection, index, self) =>
        self.findIndex((sel) => sel.itemGuid === selection.itemGuid) === index
    )
    .map((selection) => {
      return {
        ...selection,
        selectionGuid: selection.guid,
        guid: selection.itemGuid,
        description: selection.modifiers
          .map((modifier) => modifier.name)
          .join(', '),
        isReorder: true,
        price: selection.preDiscountPrice / selection.quantity,
        outOfStock: false, //filing a bug for this because we don't get out of stock status for these items ever
        groupName: 'Order again',
        menuName: 'Order again',
        unitOfMeasure: selection.unitOfMeasure || 'NONE'
      }
    })
  if (!items?.length) {
    return null
  }
  return {
    guid: id,
    name: 'Order again',
    isReorder: true,
    items,
    dietaryItemTags: [],
    groups: [],
    itemTags: [],
    __typename: 'MenuGroupV2'
  }
}
