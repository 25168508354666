import * as React from 'react'
import WarningSvg from '../../assets/warning-icon.svg'

export const PreauthDisclaimer = ({ testId }: { testId: string }) => {
  return (
    <>
      <div data-testid='warning-icon'>
        <WarningSvg />
      </div>
      <div
        data-testid={testId}
        className='text-start leading-5 tracking-[0.2px] text-[14px] text-gray-110'
      >
        Only one member of your group needs to put down a card. This member will
        be responsible for any unpaid balance.
      </div>
    </>
  )
}
