import { DDIGlobals } from '../../app/types'

export const getAvailability = (
  ddiGlobals: DDIGlobals,
  unifiedOnboardingEnabled: boolean,
  hasItemsInCart: boolean
) => {
  const { mode, optConfig } = ddiGlobals

  if (mode === 'MNP' || mode === 'OPT_PREVIEW') {
    return false
  }

  if (unifiedOnboardingEnabled) {
    return optConfig.orderingConfig.orderingEnabled || hasItemsInCart
  }

  // OPT/TTS modes
  return true
}
