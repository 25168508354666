import { Selection } from '@local/shared-types'

export interface SelectionEntry {
  selectionGuid: string
  selectionQuantity: number
}

export interface SelectionMapEntry {
  itemGroupGuid: string
  selections: SelectionEntry[]
  quantity: number
}

export const buildSelectionsMap = (selections?: Selection[] | null) => {
  if (!selections) {
    return null
  }

  return selections.reduce((map, selection) => {
    const {
      itemGuid,
      itemGroupGuid,
      guid,
      externalId,
      quantity: selectionQuantity
    } = selection

    const selectionGuid = guid || externalId

    // items are unique by guid and group guid
    const menuItem =
      map[itemGuid]?.itemGroupGuid === itemGroupGuid && map[itemGuid]

    if (!menuItem) {
      return {
        ...map,
        [itemGuid]: {
          itemGroupGuid,
          selections: [{ selectionGuid, selectionQuantity }],
          quantity: selectionQuantity
        }
      }
    }
    menuItem.quantity += selectionQuantity
    menuItem.selections.push({
      selectionGuid,
      selectionQuantity
    })
    return map
  }, {} as { [key: string]: SelectionMapEntry })
}
