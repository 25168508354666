import { Loading } from '@toasttab/do-secundo-loading'
import * as React from 'react'

interface LoadingPageProps {
  dataTestId?: string
}

export const LoadingPage = ({ dataTestId }: LoadingPageProps) => (
  <div
    data-testid={dataTestId}
    className='flex items-center justify-center h-[100vh]'
  >
    <Loading />
  </div>
)
