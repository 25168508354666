import { useParty } from '@local/cornucopia-subscriptions'
import * as React from 'react'
import { PageWrapper } from '../../components/PageWrapper'
import { useConditionalRedirect } from '../../hooks/core/redirects/useConditionalRedirect'
import { ContactInfoJoinGroupPage } from '../ContactInfoJoinGroupPage'
import { ContactInfoStartGroupPage } from '../ContactInfoStartGroupPage'

export const GroupContactInfoPageContainer = ({
  isStartingTab = false
}: {
  isStartingTab?: boolean
}) => {
  const { partyRefresh } = useParty()

  const partyStarted = Boolean(
    partyRefresh &&
      partyRefresh.party.guid &&
      partyRefresh.party.partyMemberGuid
  )

  useConditionalRedirect({
    condition: partyStarted
  })

  return (
    // animated routes require stable reference to animate, keep the wrapper on top level
    <PageWrapper>
      {isStartingTab ? (
        <ContactInfoStartGroupPage />
      ) : (
        <ContactInfoJoinGroupPage />
      )}
    </PageWrapper>
  )
}
