import { Virtualizer } from '@tanstack/react-virtual'
import * as React from 'react'

export interface MenuVirtualizerContextShape {
  menuVirtualizer: Virtualizer<Window, Element>
  offset: number
}

export const MenuVirtualizerContext = React.createContext(
  {} as MenuVirtualizerContextShape
)

export const useMenuVirtualizer = () => React.useContext(MenuVirtualizerContext)
