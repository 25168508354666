import { FormatCurrency } from '@local/format-currency'
import * as React from 'react'

export interface MenuItemPriceProps {
  price: number
  usesFractionalQuantity: boolean
  unitOfMeasure: string
  outOfStock: boolean
}

export const MenuItemPrice = ({
  price,
  usesFractionalQuantity,
  unitOfMeasure,
  outOfStock
}: MenuItemPriceProps) => (
  <span
    data-testid='menu-item-price'
    className={
      outOfStock ? 'text-gray-75 type-subhead' : 'text-gray-125 type-default'
    }
  >
    <FormatCurrency amount={price} />
    {usesFractionalQuantity && `/${unitOfMeasure.toLowerCase()}`}
  </span>
)
