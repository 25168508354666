import { VirtualItem } from '@tanstack/react-virtual'
import * as React from 'react'

interface VirtualWrapperProps {
  virtualItem: VirtualItem
  children: React.ReactNode
}

export const VirtualItemWrapper = ({
  virtualItem,
  children
}: VirtualWrapperProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${virtualItem.size}px`,
        transform: `translateY(${virtualItem.start}px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children}
    </div>
  )
}
