import * as React from 'react'

import { useRestaurant } from '@local/cornucopia-subscriptions'
import { Progress } from '../../Progress/Progress'

import styles from '../NavigationDrawer.module.css'

export const GiftCardLinks = () => {
  const { restaurant, loading } = useRestaurant()

  if (loading || !restaurant) {
    return <Progress />
  }

  const {
    giftCardLinks: { purchaseLink, checkValueLink, addValueEnabled }
  } = restaurant

  const checkValueLabel =
    'Check ' + (addValueEnabled === true ? 'or add ' : '') + 'gift card value'
  return (
    <>
      {checkValueLink && (
        <li>
          <a
            data-testid='nav-drawer-check-gift-card-balance'
            className={styles.listItem}
            href={checkValueLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            {checkValueLabel}
          </a>
        </li>
      )}
      {purchaseLink && (
        <li>
          <a
            data-testid='nav-drawer-buy-gift-card'
            className={styles.listItem}
            href={purchaseLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            Purchase gift cards
          </a>
        </li>
      )}
    </>
  )
}
