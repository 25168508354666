import * as React from 'react'

export const DisclaimerText = () => {
  return (
    <div className='text-center type-caption text-secondary'>
      The information you provide will be processed pursuant to&nbsp;
      <a
        href='https://pos.toasttab.com/terms-of-service/#diner-tos'
        className='underline type-caption text-secondary'
      >
        Toast's Guest Terms of Service
      </a>
      &nbsp;and&nbsp;
      <a
        href='https://pos.toasttab.com/privacy'
        className='underline type-caption text-secondary'
      >
        Privacy Statement
      </a>
      .
    </div>
  )
}
