import * as React from 'react'
import { Check } from 'cornucopia-apis'
import { Loading } from '@toasttab/do-secundo-loading'
import { FormatCurrency } from '@local/format-currency'
import { ServerAddedItems } from '@local/review-order-table'
import { useParty, useSpotlightMessage } from '@local/cornucopia-subscriptions'
import { RestaurantHeaderV2 } from '../../components/Menus/RestaurantHeaderV2/RestaurantHeaderV2'
import { SpotlightBanner } from '../../components/SpotlightMessage/SpotlightBanner'
import { getPrimaryCheckFromOrder } from '../MenuPage/PartyReviewOrderButton/helpers'
import { ContinueToCheckoutButton } from '../../components/ContinueToCheckoutButton'
import { PoweredByToast } from '../../components/Menus/PoweredByToast/PoweredByToast'

export const PayOnlyLandingPage = () => {
  const { partyRefresh, loading } = useParty()
  const spotlightConfig = useSpotlightMessage()

  const mainCheck =
    partyRefresh && getPrimaryCheckFromOrder(partyRefresh?.order)

  return (
    <div>
      <RestaurantHeaderV2 />
      {spotlightConfig.visible && (
        <SpotlightBanner spotlightConfig={spotlightConfig} />
      )}
      {loading ? (
        <div data-testid='loading' className='mt-24'>
          <Loading />
        </div>
      ) : (
        <PayOnlyPageContent mainCheck={mainCheck} />
      )}
      <div
        data-testid='powered-by-toast'
        className='h-[50vh] flex justify-center items-center'
      >
        <PoweredByToast className='w-[60%] block' />
      </div>
    </div>
  )
}

export const PayOnlyPageContent = ({
  mainCheck
}: {
  mainCheck?: Check | null
}) => {
  const numberOfItems = mainCheck?.selections?.filter(
    (selection) => !selection.voided
  )?.length

  return (
    <div data-testid='pay-only-content'>
      {mainCheck ? (
        <>
          <div className='mt-8 mx-4'>
            <ServerAddedItems
              selections={mainCheck.selections}
              header='Your items'
            />
          </div>
          <div className='-mb-6 pb-24 bottom-0 left-0 right-0 flex z-[20] bg-white content-center text-center'>
            <ContinueToCheckoutButton
              cta={
                <>
                  <div className='whitespace-nowrap'>{`Pay check (${numberOfItems})`}</div>
                  <div className='mx-1'>{`\u00B7`}</div>
                  <FormatCurrency amount={mainCheck.preDiscountedSubtotal} />
                </>
              }
              url='/tab/close'
            />
          </div>
        </>
      ) : (
        <div data-testid='pay-and-go-no-check' className='pt-4'>
          <p className='text-secondary px-6 text-center pt-4'>
            Place an order with your server. Come back here to see your balance
            or pay the check.
          </p>
        </div>
      )}
    </div>
  )
}
