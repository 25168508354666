import { Maybe } from '@local/shared-types'
import { useSentry } from 'banquet-runtime-modules'
import { ApiData, Menu, menus$ } from 'cornucopia-apis'
import * as React from 'react'
import { normalize } from '../normalize'

export const useMenus = () => {
  const [menusResponse, setMenusResponse] =
    React.useState<Maybe<ApiData<Maybe<Menu[]>>>>(null)

  const { captureMessage } = useSentry()

  React.useEffect(() => {
    const subscription = menus$.subscribe(
      (menusData: ApiData<Maybe<Menu[]>>) => {
        setMenusResponse(menusData)
      }
    )

    return () => subscription.unsubscribe()
  })

  React.useEffect(() => {
    if (menusResponse?.result?.error) {
      captureMessage(menusResponse?.result?.error.message)
    }
  }, [menusResponse, captureMessage])

  return normalize(menusResponse)
}
