import { BottomButton } from '@local/button'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

interface ContinueToCheckoutButtonProps {
  cta: React.ReactNode
  url: string
}

export const ContinueToCheckoutButton = ({
  cta,
  url
}: ContinueToCheckoutButtonProps) => {
  const navigate = useNavigate()
  return (
    <BottomButton
      data-testid='continue-to-checkout'
      onClick={() => {
        navigate(url)
      }}
    >
      {cta}
    </BottomButton>
  )
}
