import * as React from 'react'

import styles from '../../app/App.module.css'

import { ORDERING_ROUTES } from '../../app/OrderingRoutes'

const {
  forwardEnter,
  forwardEnterActive,
  verticalExit,
  verticalExitActive,
  backwardExit,
  backwardExitActive,
  noOp,
  forwardExit,
  forwardExitActive
} = styles

export enum TransitionDirection {
  Backward = 'BACKWARD',
  ForwardDefault = 'FORWARD_DEFAULT',
  ForwardWithExit = 'FORWARD_WITH_EXIT',
  Vertical = 'VERTICAL',
  None = 'NONE'
}

const forwardEnterOnlyTransition = {
  enter: forwardEnter,
  enterActive: forwardEnterActive,
  exit: noOp,
  exitActive: noOp
}

const forwardWithExitTransition = {
  enter: forwardEnter,
  enterActive: forwardEnterActive,
  exit: forwardExit,
  exitActive: forwardExitActive
}

const backwardExitOnlyTransitions = {
  enter: noOp,
  enterActive: noOp,
  exit: backwardExit,
  exitActive: backwardExitActive
}

const verticalTransitions = {
  enter: noOp,
  enterActive: noOp,
  exit: verticalExit,
  exitActive: verticalExitActive
}

//https://github.com/toasttab/corn-tabs-spa/pull/258 <-- for explanation behind this
export const transitionsMap = {
  [TransitionDirection.ForwardDefault]: forwardEnterOnlyTransition,
  [TransitionDirection.ForwardWithExit]: forwardWithExitTransition,
  [TransitionDirection.Backward]: backwardExitOnlyTransitions,
  [TransitionDirection.Vertical]: verticalTransitions,
  [TransitionDirection.None]: ''
}

export const getTransitionClass = (
  pathname: string,
  direction: TransitionDirection
) => {
  if (pathname === ORDERING_ROUTES.CONFIRM) {
    return transitionsMap[TransitionDirection.Vertical]
  }

  if (pathname === '/') {
    return transitionsMap[TransitionDirection.None]
  }

  return transitionsMap[direction]
}

interface TransitionContextInterface {
  setTransition: (direction: TransitionDirection) => void
  transition: TransitionDirection
}
const TransitionContext = React.createContext({} as TransitionContextInterface)

export const TransitionProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [transition, setTransition] = React.useState<TransitionDirection>(
    TransitionDirection.None
  )
  return (
    <TransitionContext.Provider value={{ setTransition, transition }}>
      {children}
    </TransitionContext.Provider>
  )
}

export const useTransitionContext = () => React.useContext(TransitionContext)
