import * as React from 'react'
import { Button, ButtonProps } from '../Button'

export const BottomButton = ({
  children,
  ...props
}: React.PropsWithChildren<ButtonProps>) => {
  return (
    <div className='fixed bottom-0 left-0  w-full bg-white shadow border-1'>
      <div className='flex justify-start w-full p-4 text-left'>
        <Button type='submit' {...props}>
          <div className='flex justify-center w-full'>{children}</div>
        </Button>
      </div>
    </div>
  )
}
