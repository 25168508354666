import { useMenus } from '@local/cornucopia-subscriptions'
import { Maybe } from '@local/shared-types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CloseCircleIcon } from '@toasttab/buffet-pui-icons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { useFuseSearch } from '@toasttab/buffet-use-fuse-search'
import { MenuGroup, MenuItem as MenuItemType } from 'cornucopia-apis'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  SelectionMapEntry,
  buildSelectionsMap
} from '../../../utils/cart-helpers'
import { useGetPartyMemberCart } from '../../../utils/cart-utils/helpers'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { MenuSearchBody } from './MenuSearchBody'

interface MenuSearchProps {
  onClose: () => void
  isOpen: boolean
}
export const MenuSearch = ({ onClose, isOpen }: MenuSearchProps) => {
  const { data: menus } = useMenus()
  const {
    restaurantConfig: { orderingAvailable }
  } = useDDIGlobals()
  const { cart } = useGetPartyMemberCart()
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = React.useState('')

  const menuItems = React.useMemo(() => {
    if (!menus) {
      return []
    }
    return menus
      .reduce((groups, menu) => {
        return [...groups, ...menu.groups]
      }, [] as MenuGroup[])
      .reduce((items, group) => {
        return [...items, ...group.items]
      }, [] as MenuItemType[])
  }, [menus])

  const { term, results, setTerm } = useFuseSearch<MenuItemType>({
    keys: ['name', 'description', 'groupName', 'itemTags.name'],
    list: menuItems,
    options: {
      distance: 1000
    }
  })
  React.useEffect(() => {
    const searchTerm = searchParams.get('term')
    if (searchTerm) {
      setInputValue(searchTerm)
      setTerm(searchTerm)
    }
  }, [searchParams, setTerm])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setSearchParams((prev) => {
      prev.set('term', e.target.value)
      return prev
    })
    setTerm(e.target.value)
  }

  const onReset = () => {
    setTerm('')
    setInputValue('')
  }

  const notFound = Boolean(term && menus && results.length === 0)

  const selectionsMap: Maybe<{ [key: string]: SelectionMapEntry }> =
    React.useMemo(
      () => buildSelectionsMap(cart?.order?.checks[0]?.selections || []),
      [cart]
    )

  return (
    <Modal
      hideCloseIcon
      noHeader
      className='w-96 min-h-[600px] max-h-[600px]'
      isOpen={isOpen}
    >
      <div
        data-testid='menu-search-modal'
        className='sticky flex items-stretch top-0 z-10 px-4 py-3 bg-lighten-80 backdrop-blur-md border-solid border-b border-l-0 border-r-0 border-t-0'
      >
        <div className='flex-grow'>
          <SearchInput
            value={inputValue}
            ref={(node) => {
              node?.focus({ preventScroll: true })
            }}
            suffix={
              <div data-testid='reset-button' className='p-3' onClick={onReset}>
                <CloseCircleIcon />
              </div>
            }
            suffixVariant='iconButton'
            placeholder='Search for an item...'
            onChange={onChange}
          />
        </div>
        <Button
          variant='link'
          className='!p-0'
          onClick={() => {
            onReset()
            onClose()
          }}
        >
          Cancel
        </Button>
      </div>
      <Modal.Body>
        <MenuSearchBody
          selectionsMap={selectionsMap}
          notFound={notFound}
          hasInput={Boolean(term)}
          results={results.map(({ item }) => item)}
          orderingAvailable={orderingAvailable}
        />
      </Modal.Body>
    </Modal>
  )
}
