import { FormatCurrency } from '@local/format-currency'
import { FractionalQuantity } from '@local/fractional-quantity'
import { Selection } from '@local/shared-types'
import cx from 'classnames'
import * as React from 'react'
import { ModifierList } from '../../../modifier-list'
import styles from './SelectionCard.module.css'

export interface SelectionCardProps {
  selection: Selection
  'data-testid'?: string
  onDelete?: () => void
  onEdit?: () => void
  disabled?: boolean
  isCurrentUser: boolean
  userName?: string
}

export const SelectionCard = ({
  isCurrentUser,
  onDelete,
  onEdit,
  selection,
  'data-testid': dataTestId,
  disabled,
  userName
}: SelectionCardProps) => {
  const {
    name,
    quantity,
    price,
    preDiscountPrice,
    modifiers,
    usesFractionalQuantity,
    fractionalQuantity
  } = selection

  const buttonStyle = cx(
    'p-0 font-medium bg-transparent border-none',
    disabled ? ' text-gray-50' : 'text-primary-75'
  )

  const showEditDelete = Boolean(onDelete || onEdit)

  // does not display the modifier "For [name]."
  const filteredModifiers = modifiers.filter(
    ({ name: modName }) => modName !== `For ${userName}.`
  )

  return (
    <div data-testid={dataTestId}>
      <div
        className={cx(
          'flex justify-between',
          showEditDelete ? 'px-4 pt-4 pb-2' : 'p-4'
        )}
      >
        <div className='flex gap-2'>
          <div data-testid='cart-item-quantity'>
            {Number(quantity.toFixed(2))}
          </div>
          <div>
            <div className={styles.name} data-testid='cart-item-name'>
              {name}
            </div>
            {filteredModifiers && (
              <ModifierList modifiers={filteredModifiers} />
            )}
          </div>
          {usesFractionalQuantity && (
            <FractionalQuantity
              quantity={fractionalQuantity!.quantity}
              unitOfMeasure={fractionalQuantity!.unitOfMeasure}
            />
          )}
        </div>
        <div
          className='pl-2 font-bold text-right'
          data-testid='cart-item-price'
        >
          <FormatCurrency amount={preDiscountPrice || price || undefined} />
        </div>
      </div>
      {isCurrentUser && showEditDelete && (
        <div className='flex gap-4 mb-4 ml-4'>
          {onEdit && (
            <button
              disabled={disabled}
              className={buttonStyle}
              onClick={onEdit}
              data-testid='edit-item'
            >
              Edit
            </button>
          )}
          {onDelete && (
            <button
              disabled={disabled}
              className={buttonStyle}
              onClick={onDelete}
              data-testid='remove-item'
            >
              Remove
            </button>
          )}
        </div>
      )}
    </div>
  )
}
