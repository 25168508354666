import * as React from 'react'
import { UnitOfMeasure } from '../../../../apollo/generated/OptWebGraphQLOperations'

export interface FractionalQuantityProps {
  unitOfMeasure: UnitOfMeasure
  quantity: number
}

export const FractionalQuantity = ({
  unitOfMeasure,
  quantity
}: FractionalQuantityProps) => {
  const units = unitOfMeasure.toLowerCase()

  return (
    <div data-testid='cart-item-fractional-quantity'>
      <span className='font-semibold'>
        {quantity} {units}
      </span>
    </div>
  )
}
