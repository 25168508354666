import { useParty } from '@local/cornucopia-subscriptions'
import { ReviewCheckTable } from '@local/review-order-table'
import { OrderIcon } from '@toasttab/buffet-pui-icons'
import { PartyRefresh } from 'cornucopia-apis'
import * as React from 'react'

import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { PageWrapper } from '../../components/PageWrapper'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'

export const CheckReviewPage = () => {
  const { partyRefresh, refetch } = useParty()
  const contactInfo = getContactInfoInitialValues()

  React.useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  return (
    <PageWrapper testId='group-check-page'>
      <PageToolbar contactInfo={contactInfo} />
      {getCheckReviewContent(partyRefresh)}
    </PageWrapper>
  )
}

const getCheckReviewContent = (partyRefresh: PartyRefresh | null) => {
  if (!partyRefresh) {
    return null
  }

  const hasOrder = Boolean(partyRefresh.order)
  const isGroupOrder = partyRefresh.party.members.length > 1
  const header = isGroupOrder ? 'Group check' : 'Your check'

  if (hasOrder) {
    return (
      <>
        <div className='mt-28'>
          <PageHeader header={header} />
        </div>
        <div className='mt-8 mx-6'>
          <ReviewCheckTable partyRefresh={partyRefresh} />
        </div>
        <div className='mt-6 mb-8 mx-6 type-subhead  text-center text-secondary'>
          Any restaurant fees, surcharges, and tips will be applied at checkout.
        </div>
      </>
    )
  }

  return (
    <div
      data-testid='no-order-placeholder'
      className='flex flex-col items-center mt-36 mx-6'
    >
      <OrderIcon className='text-disabled' />
      <h1 className='text-center mt-6 type-headline-5 font-semibold'>
        Check hasn't been started
      </h1>
      <div className='mt-2 text-secondary text-center'>
        Once the check has been started, you’ll be able to find previous orders
        here.
      </div>
    </div>
  )
}
