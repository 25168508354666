import cx from 'classnames'
import * as React from 'react'
import ReloadArrows from '../../../assets/arrows-counter-clockwise.svg'
import styles from './ReloadGroupsButton.module.css'

export interface ReloadGroupsButtonProps {
  refetch?: () => Promise<void>
}

export const ReloadGroupsButton = ({ refetch }: ReloadGroupsButtonProps) => {
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (refetch) {
      setLoading(true)
      refetch().then(() => {
        setLoading(false)
      })
    }
  }, [refetch])

  const handleRefetch = async () => {
    setLoading(true)
    if (refetch) {
      await refetch()
      setLoading(false)
    }
  }

  return (
    <div
      data-testid='reload-groups'
      onClick={handleRefetch}
      className={cx(loading && styles.loading, 'pr-5')}
    >
      <ReloadArrows />
    </div>
  )
}
