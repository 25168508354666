import { useUpsells } from '@local/cornucopia-subscriptions'
import cx from 'classnames'
import { MenuItem } from 'cornucopia-apis'
import * as React from 'react'
import { Divider } from '../Divider'
import { UpsellItem } from './UpsellItem'
import styles from './Upsells.module.css'

export const Upsells = () => {
  const { upsells } = useUpsells()
  const itemsToRender = upsells.filter((item) => Boolean(item.imageUrl))

  if (itemsToRender.length < 2) {
    return null
  }

  return (
    <div>
      <div className='my-8'>
        <Divider />
      </div>
      <div data-testid='upsells-wrapper'>
        <div className='mb-5 ml-6 font-sans font-semibold text-hint text-corn-header-sm'>
          COMPLETE YOUR MEAL
        </div>
        <ul
          className={cx(
            styles.carousel,
            'overflow-auto whitespace-nowrap flex gap-2'
          )}
        >
          {itemsToRender.map((item: MenuItem) => {
            return <UpsellItem key={item.guid} item={item} />
          })}
        </ul>
      </div>
    </div>
  )
}
