import * as React from 'react'
import CaretLeft from '../../../assets/caret-left.svg'

import { useLocation, useNavigate } from 'react-router-dom'

import {
  TransitionDirection,
  useTransitionContext
} from '../../TransitionProvider/TransitionContext'

export const BackButton = ({ goTo }: { goTo?: string }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()

  const handleGoBack = () => {
    setTransition(TransitionDirection.Backward)
    if (goTo) {
      navigate(goTo)
    } else if (location.key === 'default') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <div data-testid='back-button'>
      <button
        className='flex items-center p-0 bg-white border-none'
        onClick={handleGoBack}
      >
        <CaretLeft />
      </button>
    </div>
  )
}
