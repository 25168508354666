import * as React from 'react'
import Book from '../../assets/book-icon.svg'

import styles from './NoMenus.module.css'

export const NoMenus = () => (
  <div className={styles.noMenus}>
    <Book className={styles.noMenuIcon} />
    <p className={styles.primary}>Menu not available at this time.</p>
    <p className={styles.secondary}>Items cannot be added to cart.</p>
  </div>
)
