import * as React from 'react'
import { useDDIGlobals } from '../DDIGlobalsProvider'

export interface ShowForUSProps {
  testId?: string | number
  children: React.ReactNode
}
export const ShowForUS = ({ testId, children }: ShowForUSProps) => {
  const {
    restaurantConfig: { country }
  } = useDDIGlobals()

  return country !== 'US' ? null : <div data-testid={testId}>{children}</div>
}
