import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import * as React from 'react'
import CloseIcon from '../../assets/close-thin.svg'

export interface SpotlightDrawerProps {
  open: boolean
  onClose: () => void
  header: string
  message: string
}

export const SpotlightDrawer = ({
  open,
  onClose,
  header,
  message
}: SpotlightDrawerProps) => {
  return (
    <BottomDrawer
      data-corn-tabs
      data-testid='spotlight-message-bottom-drawer'
      customRootId='spotlight-message-bottom-drawer'
      customHeader={
        <div className='relative flex items-center justify-end h-16 m-0'>
          <div className='absolute right-[20px] h-10 w-10' onClick={onClose}>
            <span className='flex items-center justify-center w-full h-full'>
              <CloseIcon />
            </span>
          </div>
        </div>
      }
      isOpen={open}
      onClose={onClose}
    >
      <div className='flex flex-col items-center gap-1 px-8 pt-[72px] pb-[128px]'>
        <div className='font-semibold text-center text-gray-125 type-large'>
          {header}
        </div>
        <div className='type-default text-[#3C3C3C] text-center'>{message}</div>
      </div>
    </BottomDrawer>
  )
}
