import cx from 'classnames'
import * as React from 'react'
import { imgInPreprod } from '../../../utils/img-url/image-utils'
import { RestaurantLogoV2 } from '../RestaurantLogoV2/RestaurantLogoV2'
import styles from './BannerHeader.module.css'

export interface BannerHeaderProps {
  bannerUrl: string
  logoUrl?: string
  restaurantName: string
  tableName: string
}

export const BannerHeader = ({
  bannerUrl,
  logoUrl,
  restaurantName,
  tableName
}: BannerHeaderProps) => {
  return (
    <div className='relative mb-6'>
      <div
        data-testid='banner-header-banner'
        className='w-full h-[280px] flex justify-center bg-gradient-to-b from-transparent to-gray-125 clip-banner'
      >
        <img
          className='object-cover w-full'
          src={imgInPreprod(bannerUrl)}
          alt={`A view of ${restaurantName}`}
        />
      </div>
      <div className={cx('absolute ', styles.icon)}>
        <RestaurantLogoV2 logoUrl={logoUrl} restaurantName={restaurantName} />
      </div>
      <div className='flex flex-col items-center mt-12'>
        <p className='w-4/5 mb-3 text-center type-default !leading-[20px]'>{`Welcome to ${restaurantName}`}</p>
        <p className='text-center type-headline-2'>{`Table ${tableName}`} </p>
      </div>
    </div>
  )
}
