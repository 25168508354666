import { CreditIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { PaymentTypeField } from '../PaymentTypeField'
import { PaymentMethods } from '../StartTabForms.types'

import ApplePayLogo from '../../../assets/apple-pay-logo.svg'

interface PaymentTypeSelectorProps {
  paymentMethod: PaymentMethods
}

export const PaymentTypeSelector = ({
  paymentMethod
}: PaymentTypeSelectorProps) => {
  return (
    <div className='space-y-3'>
      <PaymentTypeField
        name='paymentMethod'
        label={
          <>
            <ApplePayLogo className='h-6' />
          </>
        }
        value={PaymentMethods.APPLE_PAY}
        testId={'payment-type-APPLE_PAY'}
      />
      <PaymentTypeField
        name='paymentMethod'
        label={
          <>
            <CreditIcon
              className={`mr-2 ${
                paymentMethod === PaymentMethods.CREDIT_CARD
                  ? 'text-primary-75'
                  : 'text-secondary'
              }`}
            />
            Debit or Credit
          </>
        }
        value={PaymentMethods.CREDIT_CARD}
        testId={'payment-type-CREDIT_CARD'}
      />
    </div>
  )
}
