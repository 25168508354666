import cx from 'classnames'
import * as React from 'react'

export const emojis = ['🐱', '🐶', '🐸', '🐨', '🐭', '🐙', '🐼', '🐧', '🦄']

let availableEmojis = [...emojis]

// picks a random emoji in emojis and only repeats an emoji if all 9 have been used
export const getRandomEmoji = () => {
  if (!Boolean(availableEmojis.length)) {
    availableEmojis = [...emojis]
  }

  // replaces Math.random() since it is not secure according to Sonar
  const crypto = window.crypto
  const array = new Uint32Array(1)
  crypto.getRandomValues(array)

  const randomIndex = array[0] % availableEmojis.length

  return availableEmojis.splice(randomIndex, 1)[0]
}

export interface PartyIconProps {
  icon: number | string
}

export const PartyIcon = ({ icon }: PartyIconProps) => {
  const isNumber = typeof icon === 'number'

  return (
    <div
      data-testid='party-icon'
      className={cx(
        'w-8 h-8 flex items-center justify-center bg-gray-0 mr-3 type-default rounded-full',
        { 'bg-primary-0 text-primary-75 font-semibold': isNumber }
      )}
    >
      {icon}
    </div>
  )
}
