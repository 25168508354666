import { useAuthContext, useParty } from '@local/cornucopia-subscriptions'
import { track } from '@toasttab/do-secundo-analytics'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { Drawer } from './Drawer/Drawer'
import { GiftCardLinks } from './GiftCardLinks/GiftCardLinks'
import { NavigationDrawerHeader } from './NavigationDrawerHeader/NavigationDrawerHeader'

import styles from './NavigationDrawer.module.css'

interface NavigationDrawerProps {
  onClose: () => void
}

export const NavigationDrawer = ({ onClose }: NavigationDrawerProps) => {
  const { optConfig } = useDDIGlobals()
  const { uniqueIdentifier } = useDDIGlobals()
  const { authContext } = useAuthContext()

  const { partyRefresh, deletePartyProperties } = useParty()

  const authenticated = authContext?.authenticated
  const leavePartyEnabled =
    optConfig.orderingConfig.allowJoinableParty && partyRefresh?.order

  const logoutAndTrack = async () => {
    await authContext?.passwordlessLogout()
    authContext?.setIsAuthenticated(false)

    track('User Account Logout')
  }

  const leaveParty = () => {
    if (deletePartyProperties) {
      deletePartyProperties()
    }
  }

  return (
    <Drawer
      header={<NavigationDrawerHeader onClose={onClose} />}
      onClose={onClose}
      footer={<PoweredByToast className={styles.poweredBy} />}
    >
      <ul className={styles.list}>
        {authenticated && (
          <li>
            <Link
              data-testid='nav-drawer-my-account'
              className={styles.listItem}
              to={'/account'}
              onClick={onClose}
            >
              My account
            </Link>
          </li>
        )}
        <GiftCardLinks />
        <li>
          <a
            data-testid='nav-drawer-link-privacy'
            className={styles.listItem}
            href='https://pos.toasttab.com/privacy'
            target='_blank'
            rel='noreferrer noopener'
          >
            Privacy statement
          </a>
        </li>
        <li>
          <a
            data-testid='nav-drawer-link-terms'
            className={styles.listItem}
            href='https://pos.toasttab.com/terms-of-service'
            target='_blank'
            rel='noreferrer noopener'
          >
            Terms of service
          </a>
        </li>
        {leavePartyEnabled && (
          <li>
            <button
              data-testid='nav-drawer-leave-party'
              className={styles.listItem}
              onClick={() => {
                onClose()
                leaveParty()
              }}
            >
              Leave table {uniqueIdentifier} group
            </button>
          </li>
        )}

        {authenticated && (
          <li>
            <button
              data-testid='nav-drawer-logout'
              className={styles.listItem}
              onClick={logoutAndTrack}
            >
              Log out
            </button>
          </li>
        )}
      </ul>
    </Drawer>
  )
}
