import { Button } from '@local/button'
import { useParty } from '@local/cornucopia-subscriptions'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { ContactInfoValues } from '../Contact.helpers'

export const JoinTabButton = () => {
  const { partyRefresh, loading } = useParty()
  const { isSubmitting, isValid } = useFormikContext<ContactInfoValues>()

  const disabled =
    isSubmitting ||
    !isValid ||
    Boolean(partyRefresh?.party?.memberAuthToken) ||
    loading

  return (
    <div className='mt-4'>
      <Button
        data-testid='join-contact-info-button'
        type='submit'
        disabled={disabled}
      >
        Join tab
      </Button>
    </div>
  )
}
