import { useAuthContext } from '@local/cornucopia-subscriptions'
import { Form, useFormikContext } from 'formik'
import * as React from 'react'
import Lock from '../../../../src/assets/lock-icon.svg'
import { useTrackIdentifyUser } from '../../../hooks/core/useTrackIdentifyUser/useTrackIdentifyUser'
import { DisclaimerText } from '../../DisclaimerText/DisclaimerText'
import { ContactInfo } from '../ContactInfo'
import { FormButton } from '../PaymentForm/FormButton'
import { isContactInfoSame } from '../StartTabForms.helpers'
import { ContactFormValues } from '../StartTabForms.types'

export const ContactForm = ({
  contactValidationSchema
}: {
  contactValidationSchema: any
}) => {
  const { values, isSubmitting, isValid, setFieldValue } =
    useFormikContext<ContactFormValues>()

  const { authContext } = useAuthContext()

  useTrackIdentifyUser()

  React.useEffect(() => {
    const setContactFields = async () => {
      // running multiple setFieldValues causes validation to run on stale values
      // https://github.com/jaredpalmer/formik/issues/2266
      await setFieldValue('firstName', authContext?.user?.firstName)
      await setFieldValue('lastName', authContext?.user?.lastName)
      await setFieldValue('phone', authContext?.user?.phone)
    }
    if (authContext?.user && !isContactInfoSame(authContext.user, values)) {
      setContactFields()
    }
  }, [setFieldValue, authContext?.user, values])

  const isValidContactInfo = contactValidationSchema.isValidSync({
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone
  })

  const disabled = !isValid || isSubmitting

  const shouldRenderContactInfo =
    !authContext?.authenticated || !isValidContactInfo

  return (
    <Form>
      <div data-testid='contact-form' className={`px-6 pb-6 space-y-4`}>
        <div>
          <div className={'space-y-8'}>
            {shouldRenderContactInfo && (
              <>
                <ContactInfo isSubheading={false} showEmail={false} />

                <div className='bg-gray-0 text-secondary px-5 py-4 flex border rounded-lg border-transparent items-center'>
                  <div className='w-11/12'>
                    Details required to send a secure link to manage your tab.
                    Your privacy is respected.
                  </div>
                  <div className='w-1/12 pl-1 pt-1'>
                    <Lock />
                  </div>
                </div>
              </>
            )}
            <FormButton disabled={disabled} loading={isSubmitting} />
          </div>
        </div>
        <div className='mb-24'>
          <DisclaimerText />
        </div>
      </div>
    </Form>
  )
}
