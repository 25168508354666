import { Maybe } from '@local/shared-types'
import { currentUser$, PartyMember } from 'cornucopia-apis'
import * as React from 'react'

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = React.useState<Maybe<PartyMember>>(null)

  React.useEffect(() => {
    const subscription = currentUser$.subscribe((currentUserData) => {
      if (currentUserData) {
        setCurrentUser(currentUserData)
      }
    })

    return () => subscription.unsubscribe()
  })

  return { currentUser }
}
