import { Maybe } from '@local/shared-types'

const preprodCloudfrontBaseUrl = 'dks3o9tl87y4h'
const prodCloudfrontBaseUrl = 'd2s742iet3d3t1'

export const imgInPreprod = (imageUrl: string) =>
  (imageUrl || '').replace(preprodCloudfrontBaseUrl, prodCloudfrontBaseUrl) // without this, we won't see images in preprod

export const getCloudfrontUrl = (image: Maybe<string>, size = 'large') => {
  if (!image) {
    return undefined
  }
  return `https://${prodCloudfrontBaseUrl}.cloudfront.net${image}?size=${size}`
}
