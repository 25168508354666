import { Selection } from '@local/shared-types'
import { PartyMember, PartyRefresh } from 'cornucopia-apis'

export interface GetFormattedGroupCheckProps {
  partyRefresh: PartyRefresh
  partyMembers: PartyMember[]
  currentUser: PartyMember
}

export const getAllCheckSelections = (
  partyRefresh: PartyRefresh
): Selection[] =>
  partyRefresh.order?.checks.find(
    (check) => check.guid === partyRefresh.party.mainCheckGuid
  )?.selections || []

export const getFormattedGroupCheck = ({
  partyRefresh,
  partyMembers,
  currentUser
}: GetFormattedGroupCheckProps) => {
  const allSelections = getAllCheckSelections(partyRefresh)

  return partyMembers
    .map((partyMember) => ({
      partyMember,
      check: {
        selections: allSelections.filter((selection) =>
          partyMember.orderSelectionIds.includes(selection.externalId)
        ),
        hasPaid: Boolean(partyMember.claimedCheckGuids?.length)
      }
    }))
    .sort(({ partyMember }) =>
      partyMember.partyMemberGuid === currentUser.partyMemberGuid ? -1 : 1
    )
}
