import { GroupPartyModeDrawer } from '@local/group-party-mode-drawer'
import * as React from 'react'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { useFlag } from '../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../launchdarkly/flags'
import { JoinOpenTabPage } from './JoinOpenTabPage'

/**
 * This component is responsible for rendering the
 * splash screen based on the mode and configuration of the app.
 * At the moment, it handles TTS and groups in OPT mode,
 * the rest is still managed via opt-web.
 * Include all future logic here, as this will be centralized place for loading, party state management and rejoining.
 */
export const SplashScreenPage = () => {
  const { mode, uniqueIdentifier, optConfig } = useDDIGlobals()
  const isUnifiedOnboarding = useFlag(LDFlags.UNIFIED_ONBOARDING)

  const respectOrderingEnabled =
    optConfig.orderingConfig.orderingEnabled || !isUnifiedOnboarding

  if (
    mode === 'OPT' &&
    respectOrderingEnabled &&
    optConfig?.orderingConfig.allowJoinableParty
  ) {
    return <GroupPartyModeDrawer uniqueIdentifier={uniqueIdentifier} />
  }

  if (mode === 'TTS') {
    return <JoinOpenTabPage />
  }

  return null
}
