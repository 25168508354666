import * as React from 'react'
import { NavigateProps, useNavigate } from 'react-router-dom'

export interface UseConditionalRedirectInput {
  to?: string | NavigateProps['to']
  condition: boolean
  replace?: boolean
  state?: NavigateProps['state']
}

export const useConditionalRedirect = ({
  to = '/',
  condition,
  replace = true,
  state
}: UseConditionalRedirectInput) => {
  const navigate = useNavigate()
  const redirected = React.useRef(false)

  React.useEffect(() => {
    if (!redirected.current && condition) {
      navigate(to, { replace: Boolean(replace), state })
      redirected.current = true
    }
  }, [condition, to, navigate, replace, state])
}
