import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'

import { useUpdateCart } from 'cornucopia-apis'
import { Progress } from '../Progress/Progress'

import styles from './ReorderQuickAdd.module.css'

interface ReorderQuickAddProps {
  selectionGuid: string
  outOfStock: boolean
}

export const ReorderQuickAdd = ({
  selectionGuid,
  outOfStock
}: ReorderQuickAddProps) => {
  const [loading, setLoading] = React.useState(false)

  const updateCart = useUpdateCart()

  const reorderItem = () => {
    setLoading(true)
    updateCart(null, selectionGuid, true).subscribe(() => {
      setLoading(false)
    })
  }

  return (
    <div
      className='relative mr-1'
      data-testid='reorder-quick-add'
      onClick={(e) => {
        // prevent default ensures our parent, a Link, is not triggered
        e.preventDefault()
        e.stopPropagation()
        !loading && reorderItem()
      }}
      tabIndex={0}
    >
      <div className='flex items-center justify-center h-full px-6 no-underline'>
        {loading ? (
          <div data-testid='loading-spinner' className='absolute right-0'>
            <Progress />
          </div>
        ) : (
          <div className='absolute flex justify-end items-center m-auto w-[31px] h-[31px] right-0 box-content'>
            <button
              data-testid='quick-add-link'
              disabled={outOfStock}
              className={cx(
                styles.reorderQuickAdd,
                'box-border p-0 text-black text-[18px] w-[31px] h-[31px] bg-white text-center no-underline border-solid border-[1.5px] border-gray-25 rounded-full font-semibold'
              )}
            >
              +
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ReorderQuickAdd.propTypes = {
  selectionGuid: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool.isRequired
}
