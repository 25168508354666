import { Maybe } from '@local/shared-types'
import { party$, PartyResponse } from 'cornucopia-apis'
import * as React from 'react'

export const useParty = () => {
  const [partyResponse, setPartyResponse] =
    React.useState<Maybe<PartyResponse>>(null)

  React.useEffect(() => {
    const subscription = party$.subscribe((partyData) => {
      setPartyResponse(partyData)
    })

    return () => subscription.unsubscribe()
  })

  return {
    partyRefresh: partyResponse?.partyRefresh || null,
    deletePartyProperties: partyResponse?.deletePartyProperties,
    loading: !partyResponse || partyResponse.loading,
    refetch: partyResponse?.refetch
  }
}
