import { InputField } from '@local/tab-input'
import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import cx from 'classnames'
import { useFormikContext } from 'formik'
import * as React from 'react'

import { getPhoneMaskByCountry } from '../../../utils/intl-utils/getPhoneMaskByCountry'
import { useDDIGlobals } from '../../DDIGlobalsProvider'

import { PaymentFormValues } from '../StartTabForms.types'
export interface ContactInfoProps {
  isSubheading?: boolean
  showEmail?: boolean
}

export const ContactInfo = ({
  isSubheading = false,
  showEmail = true
}: ContactInfoProps) => {
  const { initialValues } = useFormikContext<PaymentFormValues>()
  const shouldAutoFocusFirstName = Boolean(!initialValues.firstName)
  const { restaurantConfig } = useDDIGlobals()
  const rxCountry = restaurantConfig.country as CountryIsoCode
  const phoneMask = getPhoneMaskByCountry(rxCountry)
  const shouldAutoFocusLastName = Boolean(
    initialValues.firstName && !initialValues.lastName
  )

  return (
    <div className='space-y-4'>
      <div
        className={cx(
          ' text-gray-75 font-semibold font-sans',
          isSubheading ? 'text-corn-header-xs' : 'text-corn-header-sm'
        )}
      >
        CONTACT INFO
      </div>
      <div className='space-y-2'>
        <div className='flex flex-row space-x-2'>
          <InputField
            name='firstName'
            inputProps={{
              id: 'firstName',
              label: 'First Name*',
              type: 'string',
              className: 'flex-1',
              autoFocus: shouldAutoFocusFirstName
            }}
          />
          <InputField
            name='lastName'
            inputProps={{
              id: 'lastName',
              label: 'Last Name*',
              type: 'string',
              className: 'flex-1',
              autoFocus: shouldAutoFocusLastName
            }}
          />
        </div>
        <InputField
          name='phone'
          inputProps={{
            id: 'phone',
            label: 'Phone*',
            type: 'tel',
            mask: phoneMask,
            message: {
              text: 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates',
              height: '38px'
            }
          }}
        />
        {showEmail && (
          <InputField
            name='email'
            inputProps={{
              id: 'email',
              label: 'Email for Receipt*',
              type: 'email',
              message: {
                text: 'By providing your email, you agree to receive digital receipts and recurring marketing messages from this restaurant or restaurant group, as applicable',
                height: '28px'
              }
            }}
          />
        )}
      </div>
    </div>
  )
}
