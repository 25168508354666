import { MenuItem, upsells$ } from 'cornucopia-apis'
import * as React from 'react'

export const useUpsells = () => {
  const [upsells, setUpsells] = React.useState<MenuItem[]>([])

  React.useEffect(() => {
    const subscription = upsells$.subscribe((data: MenuItem[]) => {
      setUpsells(data)
    })

    return () => subscription.unsubscribe()
  })

  return { upsells }
}
