import { ImageSet } from 'cornucopia-apis'
import {
  ModifierGroupV2,
  UnitOfMeasure
} from '../../../apollo/generated/OptWebGraphQLOperations'

export type Maybe<T> = T | null | undefined

export type Guid = string

export interface Modifier {
  name: string
  price: number
}

export interface FractionalQuantity {
  quantity: number
  unitOfMeasure: UnitOfMeasure
}

// existing bff selection type only exists for placed orders
export interface Selection {
  name: string
  guid: Guid
  voided?: boolean
  quantity: number
  price: number
  preDiscountPrice: number
  modifiers: Modifier[]
  imageUrls: Maybe<ImageSet>
  usesFractionalQuantity: boolean
  modifierGroups: ModifierGroupV2[]
  specialInstructions: string
  fractionalQuantity?: FractionalQuantity
  itemGuid: Guid
  itemGroupGuid: Guid
  externalId: Guid
  unitOfMeasure?: 'NONE' | 'LB' | 'OZ' | 'KG' | 'G'
}

export type QuickSelection = Pick<
  Selection,
  | 'itemGroupGuid'
  | 'itemGuid'
  | 'quantity'
  | 'modifierGroups'
  | 'specialInstructions'
>

export enum JoinOrCreatePartyMode {
  JOIN = 'JOIN',
  CREATE = 'CREATE'
}

export enum PartyVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}
