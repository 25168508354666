import { Maybe } from '@local/shared-types'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { rankMenuItems } from '../../components/Menus/MenuItems/helpers'
import { MenusLoader } from '../../components/Menus/MenusLoader'
import { PoweredByToast } from '../../components/Menus/PoweredByToast/PoweredByToast'
import { RestaurantHeaderContainer } from '../../components/Menus/RestaurantHeaderContainer/RestaurantHeaderContainer'
import { useFlag } from '../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../launchdarkly/flags'
import { Footer } from './Footer/Footer'
import { MenuToRender } from './MenuPage.helper'
import {
  calculateActiveGroup,
  getInitialActiveGroupName,
  getVirtualData,
  getVirtualElementHeight
} from './VirtualMenu.helpers'
import { VirtualMenuItems } from './VirtualMenuItems'
import { default as styles } from './VirtualMenuPage.module.css'
import { MenuVirtualizerContext } from './VirtualizerContext'

interface VirtualMenuPageProps {
  menus?: Maybe<MenuToRender[]>
  featuredItems?: Maybe<FinalMenuItemV2[]>
}

export const VirtualMenuPage = ({
  menus,
  featuredItems
}: VirtualMenuPageProps) => {
  const [activeGroup, setActiveGroup] = React.useState(
    getInitialActiveGroupName(menus)
  )

  const showFeaturedCarousel = useFlag(LDFlags.OPT_MENU_EXPERIMENT)

  const restaurantHeaderRef = React.useRef<HTMLDivElement>(null)

  const rankedItemsMenus = React.useMemo(() => {
    return rankMenuItems(menus)
  }, [menus])

  const { virtualMenus, virtualizedMenuItems, virtualGroups } = React.useMemo(
    () => getVirtualData(rankedItemsMenus),
    [rankedItemsMenus]
  )

  const offset = restaurantHeaderRef.current
    ? -restaurantHeaderRef.current.clientHeight - 72
    : 0

  const menuVirtualizer = useWindowVirtualizer({
    count: virtualizedMenuItems.length,
    scrollPaddingStart: offset,
    onChange: (virtualizer) => {
      const newActiveGroup = calculateActiveGroup(
        virtualGroups,
        virtualizer.range,
        showFeaturedCarousel
      )
      if (newActiveGroup) {
        setActiveGroup(newActiveGroup)
      }
    },
    getItemKey: (index) => virtualizedMenuItems[index]?.guid || index,
    estimateSize: (index) => {
      return getVirtualElementHeight(virtualizedMenuItems[index]!)
    },
    overscan: 10
  })

  return (
    <MenuVirtualizerContext.Provider value={{ menuVirtualizer, offset }}>
      <RestaurantHeaderContainer
        headerRef={restaurantHeaderRef}
        virtualMenus={virtualMenus}
        activeGroup={activeGroup}
        featuredItems={featuredItems}
      />
      {menus ? (
        <VirtualMenuItems
          virtualItems={menuVirtualizer.getVirtualItems()}
          size={menuVirtualizer.getTotalSize()}
          virtualizedMenuItems={virtualizedMenuItems}
        />
      ) : (
        <MenusLoader />
      )}
      <div className='h-[50vh] flex justify-center items-center'>
        <PoweredByToast className='w-[60%] block' />
      </div>
      <Footer />
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position='top-center'
        className={styles.toastNotificationContainer}
        toastClassName={styles.toastNotificationWrapper}
      />
    </MenuVirtualizerContext.Provider>
  )
}
