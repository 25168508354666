import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { phoneNumberMinLength } from '@toasttab/buffet-pui-phone-utilities'
import { CreditCard, User } from 'cornucopia-apis'
import * as yup from 'yup'
import {
  ContactFormValues,
  PaymentFormValues,
  PaymentMethods
} from './StartTabForms.types'

export const getRawPhoneNumber = (value: any) => {
  if (!value) {
    return ''
  }
  return value
    .replace(/\D/g, '')
    .replace(/1?(\d{10})$/, (_: any, p1: string) => p1)
}

const testPhone = (value: any, rxCountry: CountryIsoCode) => {
  if (!value) {
    return false
  }

  return value.length >= phoneNumberMinLength(rxCountry, value)
}

const getPhoneSchema = (rxCountry: CountryIsoCode) =>
  yup
    .string()
    .required('required')
    .trim()
    .transform(getRawPhoneNumber)
    .test({
      name: 'validate',
      exclusive: false,
      params: {},
      message: 'must be valid phone number',
      test: (value) => testPhone(value, rxCountry)
    })

export const getContactValidationSchema = (rxCountry: CountryIsoCode) =>
  yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    phone: getPhoneSchema(rxCountry)
  })

export const getPaymentContactValidationSchema = (rxCountry: CountryIsoCode) =>
  yup.object().shape({
    firstName: yup.string().when('paymentMethod', {
      is: PaymentMethods.CREDIT_CARD,
      then: (schema) => schema.required('required')
    }),
    lastName: yup.string().when('paymentMethod', {
      is: PaymentMethods.CREDIT_CARD,
      then: (schema) => schema.required('required')
    }),
    email: yup.string().when('paymentMethod', {
      is: PaymentMethods.CREDIT_CARD,
      then: (schema) =>
        schema.email('must be a valid email address').required('required')
    }),
    phone: yup
      .string()
      .trim()
      .transform(getRawPhoneNumber)
      .when('paymentMethod', {
        is: PaymentMethods.CREDIT_CARD,
        then: (schema) =>
          schema.test('validate', 'Must be valid phone number', (value) => {
            return testPhone(value, rxCountry)
          })
      })
  })

export const getValidationSchema = (rxCountry: CountryIsoCode) =>
  getPaymentContactValidationSchema(rxCountry).concat(
    yup.object().shape(
      {
        paymentMethod: yup
          .string()
          .trim()
          .required('required')
          .oneOf([PaymentMethods.APPLE_PAY, PaymentMethods.CREDIT_CARD]),

        selectedCard: yup.string().when(['paymentMethod', 'encryptedCard'], {
          is: (paymentMethod: PaymentMethods, encryptedCard: any) => {
            return (
              paymentMethod === PaymentMethods.CREDIT_CARD && !encryptedCard
            )
          },
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable()
        }),
        encryptedCard: yup.object().when(['paymentMethod', 'selectedCard'], {
          is: (paymentMethod: PaymentMethods, selectedCard: string) => {
            return paymentMethod === PaymentMethods.CREDIT_CARD && !selectedCard
          },
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable()
        })
      },
      [['encryptedCard', 'selectedCard']]
    )
  )

// If card is not expired and not AmEx when the rx does not take AmEx
// return the card, but prioritize returning the primary card if there is one
export const findSelectedCard = (
  creditCards: CreditCard[],
  amexAccepted?: boolean
) =>
  creditCards.find(
    (card) =>
      !card.expired &&
      (amexAccepted || card.cardType !== 'AMEX') &&
      (card.isPrimary || true)
  )

export const isContactInfoSame = (
  authUser: User,
  formikValues: PaymentFormValues | ContactFormValues
) => {
  const { firstName, lastName, phone, email } = authUser

  if ('email' in formikValues) {
    return (
      firstName === formikValues.firstName &&
      lastName === formikValues.lastName &&
      phone === formikValues.phone &&
      email === formikValues.email
    )
  }
  return (
    firstName === formikValues.firstName &&
    lastName === formikValues.lastName &&
    phone === formikValues.phone
  )
}
