import { track } from '@toasttab/do-secundo-analytics'
import { PartyMember, splitPayment } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultPaymentOptions } from './DefaultPaymentOptions'
import { fireSplit } from './PaymentOptions.helper'
import { TabOwnerPaymentOptions } from './TabOwnerPaymentOptions'

export interface PaymentOptionsListProps {
  members: PartyMember[]
  currentUser: PartyMember
  onComplete?(): void
  replaceCurrentPage?: boolean
}

export const PaymentOptionsList = ({
  members,
  currentUser,
  replaceCurrentPage,
  onComplete // if the payment options drawer is opened on the checkout page it will not close without being prompted
}: PaymentOptionsListProps) => {
  const navigate = useNavigate()

  const tabOwner = members.find((member) => !!member.preauthCardGuid)

  const handleOnSplit = async () => {
    await fireSplit(
      splitPayment,
      () => {
        navigate('/tab/close?paymentMode=SPLIT', {
          replace: replaceCurrentPage
        })
      },
      () => onComplete && onComplete()
    )
  }
  const handlePayBalance = () => {
    track('Click "Pay tab balance"')
    navigate('/tab/close?paymentMode=PARTY', { replace: replaceCurrentPage })
    onComplete && onComplete()
  }

  const isCurrentUserTabOwner =
    tabOwner?.partyMemberGuid === currentUser.partyMemberGuid

  const currentUserHasSelections = currentUser.orderSelectionIds.length > 0

  return (
    <>
      <div className='mx-6 mb-6 bg-white border mt-8 border-solid rounded-lg'>
        <table className='w-full border-collapse'>
          <tbody>
            {isCurrentUserTabOwner ? (
              <TabOwnerPaymentOptions handlePayBalance={handlePayBalance} />
            ) : (
              <DefaultPaymentOptions
                hasItems={currentUserHasSelections}
                handleOnSplit={handleOnSplit}
                handlePayBalance={handlePayBalance}
              />
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
