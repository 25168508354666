import { CardRadio } from '@local/card-radio'
import { Guid } from '@local/shared-types'
import cx from 'classnames'
import { CreditCard } from 'cornucopia-apis'
import { FieldProps } from 'formik'
import * as React from 'react'
import { NewCardIcon } from '../../../../src/assets/credit-cards'
import { CreditCardContainer } from '../../../../src/components/StartTabForms/CreditCardContainer'

export interface CardSelectorProps extends FieldProps<Guid> {
  cards: CreditCard[]
  canMakeApplePayPayment: boolean
}

export const CardSelector = ({
  cards,
  canMakeApplePayPayment,
  field
}: CardSelectorProps) => {
  const showHeader = !canMakeApplePayPayment && cards.length > 0

  const { name, value, onChange, onBlur } = field

  const cardRadios = cards.map((card) => {
    return <CardRadio key={card.guid} card={card} field={field} />
  })

  return (
    <div className={cx('mt-0', { 'mb-6': field.value === '' })}>
      {showHeader && (
        <div
          className='mt-10 font-semibold font-sans text-corn-header-sm text-gray-75'
          data-testid='card-selector-header'
        >
          TAB PAYMENT
        </div>
      )}
      {cards.length !== 0 && (
        <div data-testid='saved-card-list' className='mb-4'>
          {cardRadios}
          <div className='py-4'>
            <label>
              <div className='flex items-center gap-4'>
                <input
                  type='radio'
                  name={name}
                  value=''
                  checked={value === ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  className='w-5 h-5'
                />
                <div className='flex'>
                  <NewCardIcon />
                </div>
                <div className='font-semibold type-subhead'>
                  Add debit or credit
                </div>
              </div>
            </label>
          </div>
        </div>
      )}
      {value === '' && (
        <CreditCardContainer
          showHeading={cards.length === 0}
          isSubheading={canMakeApplePayPayment}
        />
      )}
    </div>
  )
}
