import * as React from 'react'

export interface OrderingDisabledBannerProps {
  rxName: string
}

export const OrderingDisabledBanner = ({
  rxName
}: OrderingDisabledBannerProps) => {
  return (
    <div
      data-testid='ordering-disabled-banner'
      className='flex gap-[10px] items-center p-6 bg-gray-25 w-full fixed bottom-0 z-[20] border-0 border-t border-solid border-gray-30'
    >
      <span className='text-gray-75 type-subhead font-semibold'>
        {`${rxName} has disabled digital ordering. If you wish to order, please speak to staff.`}
      </span>
    </div>
  )
}
