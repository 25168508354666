import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { Loading } from '@toasttab/do-secundo-loading'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../../../src/app/OrderingRoutes'
import { CheckReviewOption } from '../../../../src/components/CheckReviewOption/CheckReviewOption'
import { PaymentOptionsList } from './PaymentOptionsList'

export const PaymentOptionsWrapper = ({
  onComplete,
  open,
  isGroupOrder
}: {
  onComplete?(): void
  open: boolean
  isGroupOrder: boolean
}) => {
  const { partyRefresh, refetch } = useParty()
  const { currentUser } = useCurrentUser()
  const location = useLocation()
  const replaceCurrentPage = location.pathname === ORDERING_ROUTES.CONFIRM

  React.useEffect(() => {
    if (open && !partyRefresh && refetch) refetch()
  }, [refetch, partyRefresh, open])

  React.useEffect(() => {
    if (partyRefresh?.order?.isClosed) {
      onComplete && onComplete()
    }
  }, [refetch, partyRefresh, onComplete])

  if (!partyRefresh || !currentUser) {
    return (
      <div
        data-testid='payment-options-loading'
        className='flex items-center justify-center w-full h-full'
      >
        <Loading />
      </div>
    )
  }

  return (
    <div data-testid='payment-options-group' className='pb-24'>
      <PaymentOptionsList
        replaceCurrentPage={replaceCurrentPage}
        currentUser={currentUser}
        members={partyRefresh.party.members}
        onComplete={onComplete}
      />
      <CheckReviewOption
        isGroupOrder={isGroupOrder}
        replaceCurrentPage={replaceCurrentPage}
      />
    </div>
  )
}
