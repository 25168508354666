import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { Field, FieldProps, useFormikContext } from 'formik'
import * as React from 'react'
import { PaymentFormValues } from '../StartTabForms.types'

export const SaveCardCheckbox = () => {
  const { setFieldValue } = useFormikContext<PaymentFormValues>()

  // if component mounted we assume the user is allowed to save the card
  React.useEffect(() => {
    setFieldValue('saveCard', true)
  }, [setFieldValue])

  return (
    <div>
      <Field name='saveCard'>
        {(formikProps: FieldProps) => {
          return (
            <div data-testid='save-card-checkbox' className='ml-1 -mt-6'>
              <Checkbox
                {...formikProps.field}
                checked={formikProps.field.value}
                label={
                  <div className='type-default text-gray-75'>
                    Remember this card
                  </div>
                }
              />
            </div>
          )
        }}
      </Field>
    </div>
  )
}
