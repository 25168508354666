import * as React from 'react'

import GreyHorizontal from '../../../assets/grey-horizontal.svg'

interface PoweredByToastProps {
  className?: string
}

export const PoweredByToast = React.memo<PoweredByToastProps>(
  ({ className, ...props }) => {
    return <GreyHorizontal className={className} {...props} />
  }
)
