import * as React from 'react'
import CloseIcon from '../../assets/close-thin.svg'

export interface CloseIconDrawerHeaderProps {
  onClose: () => void
  title?: string
}

export const CloseIconDrawerHeader = ({
  onClose,
  title
}: CloseIconDrawerHeaderProps) => {
  return (
    <div className='z-10 flex items-center justify-center h-16 border-t-0 border-b border-l-0 border-r-0 border-solid'>
      <span className='text-gray-100 !uppercase type-subhead h-16 items-center flex'>
        {title}
      </span>
      <div
        tabIndex={1}
        data-testid='close-button'
        className='absolute right-[20px] h-10 w-10'
        onClick={onClose}
      >
        <span className='flex items-center justify-center w-full h-full'>
          <CloseIcon />
        </span>
      </div>
    </div>
  )
}
