import { FormatCurrency } from '@local/format-currency'
import { Maybe } from '@local/shared-types'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDDIGlobals } from '../../../components/DDIGlobalsProvider'
import { SelectionMapEntry } from '../../../utils/cart-helpers'
import { imgInPreprod } from '../../../utils/img-url/image-utils'
import { MenuItemWidget } from '../MenuItem'

interface FeatureMenuImageItemProps {
  item: FinalMenuItemV2
  selectionsMap?: Maybe<{ [key: string]: SelectionMapEntry }>
}

export const FeaturedItem = ({
  item,
  selectionsMap
}: FeatureMenuImageItemProps) => {
  const { guid, itemGroupGuid, name, price } = item
  const {
    restaurantConfig: { orderingAvailable }
  } = useDDIGlobals()

  const isSelected =
    selectionsMap &&
    selectionsMap[guid] &&
    selectionsMap[guid]?.itemGroupGuid === itemGroupGuid

  return (
    <div>
      <li data-testid='featured-item' className='inline-block mb-2 list-none'>
        <Link to={`/add/${guid}/${itemGroupGuid}`}>
          <div className='relative'>
            <img
              className='object-cover mb-2 rounded-md'
              height='120'
              width='120'
              src={imgInPreprod(item.imageUrls!.small)}
              alt={item.name}
            />
            {orderingAvailable && (
              <MenuItemWidget
                cartSelection={isSelected ? selectionsMap[guid] : undefined}
                item={item}
                imageStyles='absolute right-[12px] bottom-[32px] z-[1]'
              />
            )}
          </div>
        </Link>
        <div className='w-32 font-semibold truncate '>{name}</div>

        {price > 0 && (
          <div className='mt-1'>
            <FormatCurrency amount={price} />
          </div>
        )}
      </li>
    </div>
  )
}
