import { Guid } from '@local/shared-types'
import { Button } from '@toasttab/buffet-pui-buttons'
import cx from 'classnames'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar, ReloadGroupsButton } from '../../components/PageToolbar'
import {
  TransitionDirection,
  useTransitionContext
} from '../../components/TransitionProvider/TransitionContext'
import { AvailableGroupsTable } from './AvailableGroupsTable'
import joinGroupStyles from './JoinAGroupPage.module.css'

export interface AvailableGroupsProps {
  refetch?: () => Promise<void>
  partiesByTable: any
}

export const AvailableGroups = ({
  refetch,
  partiesByTable
}: AvailableGroupsProps) => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()

  const onGroupSelected = (guid: Guid) => {
    setTransition(TransitionDirection.ForwardDefault)

    navigate(ORDERING_ROUTES.CONTACT_INFO, { state: { guid } })
  }

  return (
    <div data-testid='available-groups'>
      <PageToolbar right={<ReloadGroupsButton refetch={refetch} />} />
      <div className='mt-28'>
        <PageHeader
          header='Create or join a group tab'
          subheader="Group tabs can be split at meal's end."
        />
      </div>
      <div className='flex flex-col mx-6 my-7'>
        <div
          className={cx(
            'bg-white border border-solid rounded-lg',
            joinGroupStyles.boxShadow
          )}
        >
          <AvailableGroupsTable
            parties={partiesByTable}
            onGroupSelected={onGroupSelected}
          />
        </div>
        <Button
          testId='start-group-order-button'
          variant='primary'
          className='mt-4'
          onClick={() => {
            setTransition(TransitionDirection.ForwardDefault)
            navigate(`${ORDERING_ROUTES.CONTACT_INFO}?start=true`)
          }}
        >
          <span className=' type-subhead'>Create new group tab +</span>
        </Button>
      </div>
    </div>
  )
}
