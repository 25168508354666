import { OptionButton } from '@local/option-button'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import {
  TransitionDirection,
  useTransitionContext
} from '../TransitionProvider/TransitionContext'

interface CheckReviewOptionProps {
  replaceCurrentPage?: boolean
  isGroupOrder: boolean
}

export const CheckReviewOption = ({
  replaceCurrentPage,
  isGroupOrder
}: CheckReviewOptionProps) => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()

  const header = isGroupOrder ? 'View group check' : 'View check'

  return (
    <div className='mx-6 mb-6 bg-white border border-solid rounded-lg'>
      <OptionButton
        className='rounded-lg'
        text={header}
        onClick={() => {
          setTransition(TransitionDirection.ForwardDefault)
          navigate(ORDERING_ROUTES.CHECK_REVIEW, {
            replace: Boolean(replaceCurrentPage)
          })
        }}
        data-testid='view-check'
      />
    </div>
  )
}
