import { useCreditCards } from '@local/cornucopia-subscriptions'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useApplePayPreauthAvailable } from '../../utils/apple-pay-utils/apple-pay-utils'
import { ContactInfo } from '../../utils/auth-utils/auth-utils'

import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { useDDIGlobals } from '../DDIGlobalsProvider'
import { PaymentForm } from './PaymentForm/PaymentForm'
import { findSelectedCard, getValidationSchema } from './StartTabForms.helpers'
import { PaymentFormValues, PaymentMethods } from './StartTabForms.types'

export interface PaymentProps {
  initialContactInfo: ContactInfo
  onSubmit: (values: PaymentFormValues) => Promise<void>
}

export const PaymentFormWrapper = ({
  initialContactInfo,
  onSubmit
}: PaymentProps) => {
  const {
    restaurantConfig: { amexAccepted, country }
  } = useDDIGlobals()
  const validationSchema = getValidationSchema(country as CountryIsoCode)
  const applePayPreauthAvailable = useApplePayPreauthAvailable()
  const { creditCards } = useCreditCards()
  const formikRef = React.useRef<FormikProps<PaymentFormValues>>(null)

  const getSelectedInitialValue = React.useCallback(
    () => findSelectedCard(creditCards, amexAccepted)?.guid || '',
    [amexAccepted, creditCards]
  )

  // update selectedCard (credit card(s) saved to the guest's account) only if
  // encryptedCard (new credit card input) does not have any filled-in values
  React.useEffect(() => {
    if (formikRef.current?.values.encryptedCard === null) {
      formikRef.current?.setFieldValue(
        'selectedCard',
        getSelectedInitialValue()
      )
    }
  }, [getSelectedInitialValue])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        paymentMethod: applePayPreauthAvailable
          ? PaymentMethods.APPLE_PAY
          : PaymentMethods.CREDIT_CARD,
        ...initialContactInfo,
        encryptedCard: null,
        selectedCard: getSelectedInitialValue(),
        saveCard: false
      }}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <PaymentForm
        canMakeApplePayPayment={applePayPreauthAvailable}
        creditCards={creditCards}
      />
    </Formik>
  )
}
