import { Location } from 'react-router'
import { Mode, OptConfig } from './types'

export const checkIfStartInSearchParams = (searchParams: URLSearchParams) => {
  const startParam = searchParams.get('start')
  if (!startParam) {
    return false
  }
  return startParam.toLowerCase() === 'true'
}

/**
 * To reset scroll position on page change(except menu).
 * https://v5.reactrouter.com/web/guides/scroll-restoration
 * Timeout is used to ensure that the scroll position is reset after the transition is complete.
 */
export const resetScrollPosition = (location: Location) => {
  if (location.pathname === '/') {
    return
  }

  setTimeout(() => {
    window.scrollTo(0, 0)
  })
}

export const getMenuEnabled = ({
  mode,
  optConfig,
  payAndGoEnabled,
  isUnifiedOnboarding
}: {
  mode: Mode
  optConfig: OptConfig
  payAndGoEnabled: boolean
  isUnifiedOnboarding: boolean
}) => {
  if (mode === 'TTS') {
    return false
  }
  if (payAndGoEnabled) {
    // this flag will only target selected rx for now, so we dont have to worry about opt/mnp modes
    return false
  }
  return optConfig.menuConfig.menusEnabled || !isUnifiedOnboarding
}

export const getPayAndGoEnabled = ({
  mode,
  optConfig,
  payAndGoEnabled,
  isUnifiedOnboarding
}: {
  mode: Mode
  optConfig: OptConfig
  payAndGoEnabled: boolean
  isUnifiedOnboarding: boolean
}) => {
  if (mode === 'TTS') {
    return false
  }
  return (
    payAndGoEnabled ||
    (!optConfig.menuConfig.menusEnabled && isUnifiedOnboarding)
  )
}
