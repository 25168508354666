import * as React from 'react'

import { useParty } from '@local/cornucopia-subscriptions'
import { useLocation } from 'react-router'
import { ORDERING_ROUTES } from '../../../app/OrderingRoutes'

export const tabSpaPagesRequiringRefetch = {
  [ORDERING_ROUTES.START]: true,
  [ORDERING_ROUTES.ADD]: true,
  [ORDERING_ROUTES.CONTACT_INFO]: true,
  [ORDERING_ROUTES.JOIN]: true,
  [ORDERING_ROUTES.CHECK_REVIEW]: true,
  '/': true
}
export const useRefreshPartyOnNavigation = () => {
  const location = useLocation()
  const { refetch } = useParty()

  React.useEffect(() => {
    if (location.pathname in tabSpaPagesRequiringRefetch) {
      refetch && refetch()
    }
  }, [location.pathname, refetch])
}
