import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'

import * as yup from 'yup'

export interface ContactInfoValues {
  fullName: string
  phone: string
}

export const getContactFormInitialValues = (): ContactInfoValues => {
  const contactInitial = getContactInfoInitialValues()

  try {
    const sessionStoredInfo = window.sessionStorage.getItem(
      'tabs_spa_contact_form_info'
    )

    if (sessionStoredInfo) {
      const parsedStorage = JSON.parse(sessionStoredInfo)
      return {
        fullName: parsedStorage.fullName,
        phone: parsedStorage.phone
      }
    }
  } catch (err) {}

  if (!contactInitial.firstName && !contactInitial.lastName) {
    return {
      fullName: '',
      phone: contactInitial.phone
    }
  }

  if (contactInitial.firstName && !contactInitial.lastName) {
    return {
      fullName: contactInitial.firstName,
      phone: contactInitial.phone
    }
  }

  return {
    fullName: `${contactInitial.firstName} ${contactInitial.lastName}`,
    phone: contactInitial.phone
  }
}

export const validationSchema = yup.object().shape({
  fullName: yup.string().required('required'),
  phone: yup.string().test('len', 'Must be at least 10 digits', (value) => {
    if (!value) {
      return false
    }
    const sanitizedValue = value.replace(/\D/g, '')
    return sanitizedValue.length === 10 || sanitizedValue.length === 11
  })
})
