import { Maybe } from '@local/shared-types'
import { PartiesByCheckResponse, partiesByCheck$ } from 'cornucopia-apis'
import * as React from 'react'

export const usePartiesByCheck = () => {
  const [partiesByCheckResponse, setPartiesByCheckResponse] =
    React.useState<Maybe<PartiesByCheckResponse>>(null)

  React.useEffect(() => {
    const subscription = partiesByCheck$.subscribe((response: any) => {
      setPartiesByCheckResponse(response)
    })

    return () => subscription.unsubscribe()
  })

  return {
    loading: partiesByCheckResponse?.loading,
    partiesByCheckResponse,
    partiesByCheck:
      partiesByCheckResponse?.data?.lookupPartiesByCheck.parties || null,
    refetch: partiesByCheckResponse?.refetch
  }
}
