import { Maybe } from '@local/shared-types'
import { Restaurant, restaurant$ } from 'cornucopia-apis'
import * as React from 'react'

export const useRestaurant = () => {
  const [restaurantConfig, setRestaurantConfig] =
    React.useState<Maybe<Restaurant>>(null)

  React.useEffect(() => {
    const subscription = restaurant$.subscribe((config) => {
      setRestaurantConfig(config)
    })

    return () => subscription.unsubscribe()
  })

  return { loading: !restaurantConfig, restaurant: restaurantConfig }
}
