import { FinalMenuItemV2 } from 'cornucopia-apis'
import { createSearchParams } from 'react-router-dom'

export const getDataTestIdForItem = (item: FinalMenuItemV2) => {
  const { groupName, isReorder } = item
  if (groupName === 'Featured') {
    return 'featured-menu-item-link'
  }
  if (groupName === 'Popular') {
    return 'popular-menu-item-link'
  }
  if (isReorder) {
    return 'reorder-item-link'
  }
  return 'menu-item-link'
}

export const constructSearchParams = (groupName: string) => {
  if (groupName === 'Popular' || groupName === 'Featured') {
    return createSearchParams({ referrer: groupName }).toString()
  }
  return ''
}
