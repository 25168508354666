import * as React from 'react'

import { ContactInfo } from '../../../utils/auth-utils/auth-utils'

import { useAuthContext } from '@local/cornucopia-subscriptions'
import {
  PasswordlessAuthUI,
  PasswordlessAuthUIVariant
} from '@toasttab/do-secundo-passwordless-authentication-ui'

import {
  trackAccountVerificationEvent,
  trackCompleteIdentity,
  trackFailedCodeEntry,
  trackSendCodeEvent
} from '../../../utils/auth-utils/heap-events'

export const LoginButton = ({ contactInfo }: { contactInfo?: ContactInfo }) => {
  const { authContext, loading: authContextloading } = useAuthContext()

  return (
    <>
      {!authContextloading && (
        <PasswordlessAuthUI
          auth={authContext!}
          variant={PasswordlessAuthUIVariant.LINK}
          trackAccountVerificationEvent={() =>
            trackAccountVerificationEvent(authContext!)
          }
          trackCompleteIdentity={trackCompleteIdentity}
          trackFailedCodeEntry={trackFailedCodeEntry}
          trackSendCodeEvent={trackSendCodeEvent}
          initialPhoneNumber={contactInfo?.phone}
        />
      )}
    </>
  )
}
