import * as React from 'react'
export interface PageWrapperProps {
  children: React.ReactNode
  testId?: string
}

export const PageWrapper = ({ children, testId }: PageWrapperProps) => {
  return (
    <div
      className='top-0 bottom-0 left-0 right-0 z-[100] bg-white'
      data-testid={testId}
    >
      {children}
    </div>
  )
}
