import { useDDIGlobals } from '../../../components/DDIGlobalsProvider'
import { LDFlags } from '../../../launchdarkly/flags'
import { flagOverrides } from '../../../launchdarkly/flagsOverrides'

// Returns whether the provided flag is enabled
export const useFlag = (name: LDFlags, defaultValue = false): boolean => {
  const hasOverride = flagOverrides[name] !== undefined
  const { enabledFeatureFlags } = useDDIGlobals()

  if (hasOverride) {
    return Boolean(flagOverrides[name])
  }

  if (
    Array.isArray(enabledFeatureFlags) &&
    enabledFeatureFlags.includes(name)
  ) {
    return true
  }

  return defaultValue
}

// Returns whether every flag provided is true
export const useFlags = (names: LDFlags[], defaultValue = false) => {
  const { enabledFeatureFlags } = useDDIGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.every((name) =>
    checkIfFlagEnabled(name, enabledFeatureFlags, defaultValue)
  )
}

// Returns whether at least one of the provided flags is true
export const useAtLeastOneFlag = (names: LDFlags[], defaultValue = false) => {
  const { enabledFeatureFlags } = useDDIGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.some((name) =>
    checkIfFlagEnabled(name, enabledFeatureFlags, defaultValue)
  )
}

const checkIfFlagEnabled = (
  name: LDFlags,
  enabledFeatureFlags: string[],
  defaultValue: boolean
) => {
  if (
    Array.isArray(enabledFeatureFlags) &&
    enabledFeatureFlags.includes(name)
  ) {
    return true
  }
  return defaultValue
}
