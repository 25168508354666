import { OptionButton } from '@local/option-button'
import * as React from 'react'
import { getRandomEmoji, PartyIcon } from './AvailableGroups.helpers'

import { Guid } from '@local/shared-types'
import buttonStyles from '../../components/CheckReviewOption/CheckReviewOption.module.css'

export interface AvailableGroupsTableProps {
  parties: {
    // update once real data is passed in
    guid: string
    members: { name: string }[]
  }[]
  onGroupSelected: (guid: Guid) => void
}

export const AvailableGroupsTable = ({
  parties,
  onGroupSelected
}: AvailableGroupsTableProps) => {
  return (
    <table
      data-testid='available-groups-table'
      className='w-full border-collapse'
    >
      <tbody>
        {parties.map(({ guid, members }) => {
          const emoji = getRandomEmoji()
          const moreThanOne = members.length > 1
          const icon = moreThanOne ? members.length : emoji
          const personPeopleText =
            members.length - 1 === 1 ? 'person' : 'people'

          const buttonText = moreThanOne ? (
            <div className='flex flex-col gap-[2px] items-start'>
              <span className='font-semibold type-subhead'>
                {members[0].name}
              </span>
              <span className='t text-gray-75 type-caption'>{`+ ${
                members.length - 1
              } other ${personPeopleText}`}</span>
            </div>
          ) : (
            <span className='font-semibold type-subhead'>
              {members[0].name}
            </span>
          )

          const joinIcon = (
            <span className='font-semibold text-primary-75'>Join</span>
          )

          return (
            <tr
              key={guid}
              className={buttonStyles.optionRow}
              data-testid='join-button-row'
            >
              <td>
                <OptionButton
                  icon={<PartyIcon icon={icon} />}
                  text={buttonText}
                  onClick={() => onGroupSelected(guid)}
                  data-testid={`${guid}-join-party-button`}
                  rightIcon={joinIcon}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
