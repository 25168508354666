import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { ContactInfoValues } from '../Contact.helpers'

export const BottomContinueButton = () => {
  const { isSubmitting, isValid } = useFormikContext<ContactInfoValues>()
  const disabled = isSubmitting || !isValid
  return (
    <div className='fixed bottom-0 left-0 w-full bg-white py-4 pr-6 border-t-[0.75px] border-solid border-b-0 border-r-0 border-l-0 border-gray-30'>
      <div className='flex justify-end'>
        <Button
          data-testid='submit-contact-info-button'
          type='submit'
          disabled={disabled}
          iconRight={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
