import { Button } from '@local/button'
import { track } from '@toasttab/do-secundo-analytics'
import * as React from 'react'
import {
  TransitionDirection,
  useTransitionContext
} from '../../TransitionProvider/TransitionContext'

export interface FormButtonProps {
  disabled: boolean
  loading?: boolean
}

export const FormButton = ({ disabled, loading }: FormButtonProps) => {
  const { setTransition } = useTransitionContext()

  return (
    <Button
      data-testid='create-preauth-tab-submit-credit-card-button'
      type='submit'
      loading={loading}
      disabled={disabled}
      onClick={() => {
        track('Attempt Preauth Credit Card')
        setTransition(TransitionDirection.ForwardWithExit)
      }}
    >
      Start tab & place order
    </Button>
  )
}
