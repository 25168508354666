import * as React from 'react'
import { InView } from 'react-intersection-observer'

interface LazyLoadV2Props {
  rootMargin?: string
  children: (inView: boolean) => React.ReactElement<any>
}

export const LazyLoad = ({ rootMargin, children }: LazyLoadV2Props) => {
  const [hasIntersected, setHasIntersected] = React.useState(false)

  const handleChange = (inView: boolean) => {
    if (inView) {
      setHasIntersected(true)
    }
  }

  return (
    <InView onChange={handleChange} rootMargin={rootMargin}>
      {children(hasIntersected)}
    </InView>
  )
}
