import { useAuthContext } from '@local/cornucopia-subscriptions'
import * as React from 'react'
import { Link, createSearchParams } from 'react-router-dom'

import styles from './NavigationDrawerHeader.module.css'

export const NavigationDrawerHeader = ({
  onClose
}: {
  onClose: () => void
}) => {
  const { authContext } = useAuthContext()
  const user = authContext?.user
  const authenticated = authContext?.authenticated

  if (authenticated && user) {
    return (
      <h2 data-testid='drawer-header-user-name' className={styles.header}>
        Hi{user.firstName ? ` ${user.firstName}` : ''}!
      </h2>
    )
  }

  return (
    <>
      <h2 data-testid='drawer-header' className={styles.header}>
        Create account
      </h2>
      <p data-testid='drawer-text-after-header' className={styles.text}>
        Get your food faster. Save order info and payment details across all of
        your favorite restaurants with a Toast account.
      </p>

      <div className={styles.flex}>
        <Link
          data-testid='drawer-button-create-account'
          className='flex items-center justify-center py-3 font-semibold bg-[#2b4fb9] text-[#fff] rounded-md'
          onClick={onClose}
          to={{ search: createSearchParams({ mode: 'login' }).toString() }}
        >
          Log in &amp; Create account
        </Link>
      </div>
    </>
  )
}
