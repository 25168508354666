import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { Guid, Maybe, Selection } from '@local/shared-types'
import {
  AppliedServiceCharge,
  Cart,
  Order,
  PartyMember,
  PartyRefresh
} from 'cornucopia-apis'

export const calculateServiceChargeTotal = (
  appliedServiceCharges: AppliedServiceCharge[]
) => {
  return appliedServiceCharges.reduce<number>(
    (chargeTotal: number, appliedServiceCharge: AppliedServiceCharge) => {
      return chargeTotal + (appliedServiceCharge.chargeAmount || 0)
    },
    0
  )
}

export const calculateSelectionsTotal = (selections: Selection[]) =>
  selections.reduce<number>(
    (total, selection) =>
      Number((total + (selection.preDiscountPrice || 0)).toFixed(2)),
    0
  )

export const calculateCartSubtotal = (
  cart: Cart,
  appliedServiceCharges: AppliedServiceCharge[] = []
) => {
  if (!cart || !cart.order || !cart.order.checks) {
    return 0
  }

  const serviceChargeTotal = calculateServiceChargeTotal(appliedServiceCharges)

  return (
    cart.order.checks.reduce<number>((accCart: number, check) => {
      return accCart + calculateSelectionsTotal(check.selections)
    }, 0) + serviceChargeTotal
  )
}

export const calculateOrderSubtotal = (
  carts: Cart[] = [],
  appliedServiceCharges: AppliedServiceCharge[] = []
) => {
  const orderSubtotal = carts.reduce((total, cart) => {
    return total + calculateCartSubtotal(cart, [])
  }, 0)

  const serviceChargeTotal = calculateServiceChargeTotal(appliedServiceCharges)

  return orderSubtotal + serviceChargeTotal
}

interface GetPartyMemberCartResponseLoading {
  loading: true
  cart: null
}
interface GetPartyMemberCartResponse {
  loading: false
  cart: Cart
}

export const useGetPartyMemberCart = ():
  | GetPartyMemberCartResponseLoading
  | GetPartyMemberCartResponse => {
  const { partyRefresh } = useParty()
  const { currentUser } = useCurrentUser()

  const isLoading = !partyRefresh || !currentUser

  if (isLoading) {
    return { loading: true, cart: null }
  }

  const cart = partyRefresh.carts.find(
    (currentCart) => currentCart.guid === currentUser.cartGuid
  )!

  return {
    loading: false,
    cart: cart
  }
}

export const getPartyMemberCart = (
  carts: Maybe<Cart[]>,
  partyMember?: Maybe<PartyMember>
) => {
  if (!carts || !partyMember) {
    return null
  }

  return carts.find((cart) => cart.guid === partyMember.cartGuid)
}

export const getPartyMemberOrderSelections = (
  selections: Maybe<Selection[]>,
  partyMember?: Maybe<PartyMember>
) => {
  if (!selections || !partyMember) {
    return null
  }

  return selections.filter((selection) =>
    partyMember.orderSelectionIds.includes(selection.externalId)
  )
}

export const getSelectionsFromCart = (cart: Maybe<Cart>) => {
  if (!cart) {
    return []
  }
  return cart.order.checks[0].selections
}

export const getTotalNumberOfSelections = (selections: Selection[]) => {
  return selections.reduce((totalCount, selection) => {
    return totalCount + selection.quantity
  }, 0)
}

export const getServiceChargesFromCart = (cart: Maybe<Cart>) => {
  if (!cart) {
    return []
  }
  return cart.order.checks[0].appliedServiceCharges || []
}

export const getServiceChargesFromOrder = (
  order: Maybe<Order>,
  checkGuid: Maybe<Guid>
) => {
  if (!order || !checkGuid) {
    return []
  }
  const check = order.checks.find((chk) => chk.guid === checkGuid)
  if (!check) {
    return []
  }
  return check.appliedServiceCharges || []
}

export const getCartsWithItems = (partyRefresh: Maybe<PartyRefresh>) => {
  if (!partyRefresh) {
    return null
  }
  return partyRefresh?.carts.filter(
    (cart) => cart.order.checks[0].selections.length > 0
  )
}

export const getPartyMembers = (partyRefresh: PartyRefresh | null) => {
  return partyRefresh?.party?.members
}

export const getServerAddedItems = (
  allSelections: Selection[],
  partyMembers: PartyMember[]
) =>
  allSelections.filter(({ externalId }) =>
    partyMembers.every(
      ({ orderSelectionIds }) => !orderSelectionIds.includes(externalId)
    )
  )
