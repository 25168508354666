import { Button } from '@toasttab/buffet-pui-buttons'
import cx from 'classnames'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import ReloadArrows from '../../assets/arrows-counter-clockwise-sm-white.svg'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'

import styles from './JoinAGroupPage.module.css'
import {
  TransitionDirection,
  useTransitionContext
} from '../../components/TransitionProvider/TransitionContext'

export interface NoAvailableGroupsProps {
  refetch?: () => Promise<void>
}

export const NoAvailableGroups = ({ refetch }: NoAvailableGroupsProps) => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (refetch) {
      setLoading(true)
      refetch().then(() => {
        setLoading(false)
      })
    }
  }, [refetch])

  const handleRefresh = async () => {
    if (refetch) {
      setLoading(true)
      await refetch()
      setLoading(false)
    }
  }

  return (
    <div data-testid='no-available-groups'>
      <PageToolbar right={null} />
      <div className='mt-28'>
        <PageHeader
          header='No open groups at this table'
          subheader='Refresh the page to see new groups.'
        />
      </div>
      <div className='flex flex-col gap-2 mx-6 mt-9'>
        <Button
          testId='refresh-button'
          variant='primary'
          onClick={handleRefresh}
        >
          <span className='flex flex-row items-center'>
            <span>Refresh page</span>
            <span
              data-testid='reload-arrows'
              className={cx('w-4 h-4 flex items-center ml-2', {
                [styles.loading]: loading
              })}
            >
              <ReloadArrows />
            </span>
          </span>
        </Button>
        <Button
          testId='start-group-order-button'
          variant='secondary'
          onClick={() => {
            setTransition(TransitionDirection.ForwardDefault)
            navigate(`${ORDERING_ROUTES.CONTACT_INFO}?start=true`)
          }}
        >
          Create new group tab
        </Button>
      </div>
    </div>
  )
}
