import { useCurrentUser } from '@local/cornucopia-subscriptions'
import { FormatCurrency } from '@local/format-currency'
import { PartyRefresh } from 'cornucopia-apis'
import * as React from 'react'

import { LoadingPage } from '../../../../src/pages/LoadingPage/LoadingPage'
import {
  calculateSelectionsTotal,
  getServerAddedItems
} from '../../../../src/utils/cart-utils/helpers'
import { ServerAddedItems } from '../ServerAddedItems/ServerAddedItems'

import { PartyMemberOrderReview } from './PartyMemberOrderReview'
import {
  getAllCheckSelections,
  getFormattedGroupCheck
} from './ReviewCheckTable.helper'

/**
 * Used in View check page (CheckReviewPage.tsx)
 * @param partyRefresh
 * @returns table with current group members and their orders on the existing tab
 */
export const ReviewCheckTable = ({
  partyRefresh
}: {
  partyRefresh: PartyRefresh
}) => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return <LoadingPage dataTestId='group-check-loading' />
  }

  const partyMembers = partyRefresh.party.members
  const sortedGroupCheck = getFormattedGroupCheck({
    partyRefresh,
    partyMembers,
    currentUser
  })

  const allSelections = getAllCheckSelections(partyRefresh)
  const serverAddedItems = getServerAddedItems(allSelections, partyMembers)
  const groupSubtotal = calculateSelectionsTotal(allSelections)
  const isGroupOrder = partyMembers.length > 1

  return (
    <div data-testid='review-check-table' className='flex flex-col gap-6'>
      {sortedGroupCheck.map(({ partyMember, check }) => {
        const isCurrentUser =
          partyMember.partyMemberGuid === currentUser.partyMemberGuid

        return (
          <PartyMemberOrderReview
            isGroupOrder={isGroupOrder}
            key={partyMember.partyMemberGuid}
            groupMemberCheck={check}
            isCurrentUser={isCurrentUser}
            userName={partyMember.name}
          />
        )
      })}
      {serverAddedItems.length > 0 && (
        <ServerAddedItems
          header='Added by server'
          selections={serverAddedItems}
        />
      )}
      {isGroupOrder && (
        <div className='px-4 py-3 border border-solid rounded-lg bg-corn-gray-25'>
          <div
            className={
              'flex font-bold items-center justify-between bg-corn-gray-25'
            }
            data-testid='group-subtotal'
          >
            <div>Group Subtotal</div>
            <div>
              <FormatCurrency amount={groupSubtotal} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
