import cx from 'classnames'
import * as React from 'react'
import Spinner from '../../../../assets/spinner-28-icon.svg'

import styles from './QuickAddItem.module.css'

const {
  pill,
  pillOpen,
  removeButton,
  counter,
  animateCount,
  animateRemoveButton,
  quickAddButton,
  loadingState
} = styles

interface StepperProps {
  onAdd: () => void
  onRemove: () => void
  quantity: number
  loading: boolean
  closeAfter?: number
  outOfStock?: boolean
}

type Timeout = ReturnType<typeof setTimeout>

export const Stepper = ({
  onAdd,
  onRemove,
  quantity,
  loading,
  closeAfter = 2000,
  outOfStock
}: StepperProps) => {
  const [open, setOpen] = React.useState(false)
  const closeTimeoutRef = React.useRef<Timeout | null>(null)

  React.useEffect(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    if (!loading && open) {
      closeTimeoutRef.current = setTimeout(() => {
        setOpen(false)
      }, closeAfter)
    }
  }, [loading, open, closeAfter])

  const handleAdd = () => {
    // to ensure we dont add by clicking the quantity when pill is collapsed
    if (open || !quantity) {
      onAdd()
    }
    setOpen(true)
  }

  return (
    <div
      className={cx(pill, open && pillOpen, loading && loadingState, {
        'shadow-none': outOfStock
      })}
    >
      <button
        disabled={loading}
        onClick={onRemove}
        className={cx(quickAddButton, removeButton, {
          [animateRemoveButton]: open,
          [loadingState]: loading
        })}
        data-testid='quick-remove-button'
      >
        -
      </button>

      <div
        data-testid='quick-add-quantity'
        className={cx(counter, {
          [animateCount]: open,
          [loadingState]: loading
        })}
      >
        {loading ? <Spinner /> : quantity || 0}
      </div>

      <button
        data-testid='quick-add-button'
        className={cx(
          quickAddButton,
          { [loadingState]: loading },
          'disabled:bg-gray-25 disabled:text-gray-50'
        )}
        disabled={loading || outOfStock}
        onClick={handleAdd}
      >
        {quantity > 0 && !open ? quantity : '+'}
      </button>
    </div>
  )
}
