import { CollapsibleList } from '@toasttab/do-secundo-collapsible-list'
import { Loading } from '@toasttab/do-secundo-loading'
import cx from 'classnames'
import { GroupMemberCheck } from 'cornucopia-apis'
import * as React from 'react'

import { getTotalNumberOfSelections } from '../../../../src/utils/cart-utils/helpers'
import { SelectionCard } from '../../../selection-card'

import { SelectionsSubtotal } from './SelectionsSubtotal'

export interface PartyMemberOrderReviewProps {
  groupMemberCheck: GroupMemberCheck
  isCurrentUser: boolean
  loading?: boolean
  userName: string
  isGroupOrder: boolean
}

export const PartyMemberOrderReview = ({
  groupMemberCheck,
  isCurrentUser,
  loading = false,
  userName,
  isGroupOrder
}: PartyMemberOrderReviewProps) => {
  const userDisplayName = isCurrentUser ? 'Me' : userName
  const { selections, hasPaid } = groupMemberCheck
  const hasSelections = selections.length > 0 && !hasPaid
  const hasPaidText = hasPaid ? 'Paid' : 'No items'

  const totalNumberOfItems = getTotalNumberOfSelections(selections)

  const selectionCards = hasSelections
    ? selections.map((selection) => {
        return (
          <div
            key={`${userName}-${selection.externalId}`}
            data-testid='selection-card'
            className='border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-30'
          >
            <SelectionCard
              isCurrentUser={isCurrentUser}
              disabled={loading}
              data-testid={`selection-${selection.externalId}`}
              selection={selection}
              userName={userName}
            />
          </div>
        )
      })
    : [
        <div
          key={`${userName}-no-items`}
          data-testid='no-items'
          className={cx(
            'p-4 border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-30',
            { 'text-success font-bold': hasPaid }
          )}
        >
          {hasPaidText}
        </div>
      ]

  return (
    <div
      key={userName}
      data-testid='party-member-order-review'
      className={cx('relative', { 'opacity-50': loading })}
    >
      {loading ? (
        <div
          data-testid='loading'
          className='absolute m-auto left-0 right-0 text-center top-[50%]'
        >
          <Loading />
        </div>
      ) : (
        <div
          data-testid='review-list'
          className='space-y-4 border border-solid rounded-lg'
        >
          <CollapsibleList
            key={`${userName}-list`}
            header={
              <div
                data-testid='user-display-name'
                className='flex justify-between w-full px-2 font-bold uppercase type-subhead'
              >
                {userDisplayName}
                {hasSelections ? ` (${totalNumberOfItems})` : ''}
              </div>
            }
            defaultCollapsed={!isCurrentUser}
            indentList={false}
            backgroundColor='gray'
            isCollapsible={isGroupOrder}
            containerStyles=''
          >
            {selectionCards}
            {hasSelections && <SelectionsSubtotal selections={selections} />}
          </CollapsibleList>
        </div>
      )}
    </div>
  )
}
