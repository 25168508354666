import * as React from 'react'
import { useLocation } from 'react-router-dom'

interface LocationContextShape {
  previousLocations: string[]
}

export const PreviousLocationProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const location = useLocation()
  const [previousLocations, setPreviousLocations] = React.useState(['/'])
  const currLocationRef = React.useRef<null | string>()

  React.useEffect(() => {
    const currPath = `${location.pathname}${location.search}`
    if (currLocationRef.current !== currPath) {
      setPreviousLocations([
        ...previousLocations,
        currLocationRef.current || '/'
      ])
      currLocationRef.current = currPath
    }
  }, [location, previousLocations])

  return (
    <LocationContext.Provider value={{ previousLocations }}>
      {children}
    </LocationContext.Provider>
  )
}

export const LocationContext = React.createContext({} as LocationContextShape)

export const usePreviousLocation = () => React.useContext(LocationContext)
