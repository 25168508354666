import { track } from '@toasttab/do-secundo-analytics'
import { AuthContextType, isProfileCreated } from 'cornucopia-apis'

const trackCompleteIdentity = () => {
  track('New Pw- Account Created')
}

const trackAccountVerificationEvent = (authContext: AuthContextType) => {
  // login and acc creationg
  track('Successful Code Submission')
  if (isProfileCreated(authContext.authClient)) {
    // when logging in
    track('Successful Log In')
  }
}

const trackSendCodeEvent = () => {
  track('Send SMS Code')
}

const trackFailedCodeEntry = (message: string) => {
  track('Failed Code Entry', { message })
}

export {
  trackAccountVerificationEvent,
  trackCompleteIdentity,
  trackFailedCodeEntry,
  trackSendCodeEvent
}
