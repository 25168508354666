import { ReviewOrderTable } from '@local/review-order-table'
import * as React from 'react'
import { ContinueToCheckoutButton } from '../../components/ContinueToCheckoutButton'
import { Divider } from '../../components/Divider'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { Upsells } from '../../components/Upsells/Upsells'
import { useRedirectIfEmptyCart } from '../../hooks/core/redirects/useRedirectIfEmptyCart'
import { useGetPartyMemberCart } from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const YourOrderPage = () => {
  const { loading, cart } = useGetPartyMemberCart()

  useRedirectIfEmptyCart()
  if (loading) {
    return <LoadingPage dataTestId='payg-page-loading' />
  }

  return (
    <div data-testid='your-order-page'>
      <PageToolbar left={<BackToSafeLocationButton />} />
      <div className='mt-28'>
        <PageHeader header='Your order' />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewOrderTable cart={cart} />
        </div>
        <div className='pb-24'>
          <Upsells />
        </div>

        <ContinueToCheckoutButton
          cta='Continue to place order'
          url='/tab/new'
        />
      </div>
    </div>
  )
}
