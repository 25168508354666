import cx from 'classnames'
import * as React from 'react'

import { track } from '@toasttab/do-secundo-analytics'
import { PreauthDisclaimer } from '../../PreauthDisclaimer/PreauthDisclaimer'
import { PaymentMethods } from '../StartTabForms.types'
import { FormButton } from './FormButton'

import styles from './PaymentForm.module.css'

export interface PaymentFormButtonProps {
  paymentMethod: PaymentMethods
  disabled: boolean
  loading?: boolean
  isGroupOrder?: boolean
}

export const PaymentFormButton = ({
  paymentMethod,
  disabled,
  loading,
  isGroupOrder
}: PaymentFormButtonProps) => {
  if (paymentMethod === PaymentMethods.APPLE_PAY) {
    return (
      <div
        className={cx(
          'bg-white fixed bottom-0 left-0 w-full p-4',
          styles.sticky
        )}
      >
        {isGroupOrder && (
          <div className='flex items-center gap-4 px-1 mb-4'>
            <PreauthDisclaimer testId='preauth-disclaimer-apple-pay' />
          </div>
        )}
        <button
          disabled={disabled}
          type='submit'
          className={cx(styles.applePay, styles.applePayPreauth)}
          data-testid='create-preauth-tab-submit-apple-pay-button'
          onClick={() => {
            track('Attempt Preauth Apple Pay')
          }}
        />
      </div>
    )
  }

  return <FormButton disabled={disabled} loading={loading} />
}
