import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { getSentryConfig } from 'do-cornucopia-root'
import * as React from 'react'
import ReactDOM from 'react-dom'
import ResizeObserver from 'resize-observer-polyfill'
import { App } from './app/App'

import './index.css'
import { registerExperiments } from './utils/experiments/experiment-definitions'

const sentryConfig = getSentryConfig(window.OO_GLOBALS.mode)

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

registerExperiments()

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-corn-tabs',
  rootComponent: App as any,
  portalContainers: ['banquetPortalsContainer'],
  sentry: sentryConfig
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'corn-tabs-spa'
