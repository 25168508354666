import { CollapsibleList } from '@toasttab/do-secundo-collapsible-list'
import { Loading } from '@toasttab/do-secundo-loading'
import cx from 'classnames'
import * as React from 'react'

import { Guid, Selection } from '@local/shared-types'
import { Cart } from 'cornucopia-apis'
import {
  getSelectionsFromCart,
  getTotalNumberOfSelections
} from '../../../../src/utils/cart-utils/helpers'
import { SelectionCard } from '../../../selection-card'

export interface PartyMemberOrderPreviewProps {
  cart: Cart
  isCurrentUser: boolean
  loading?: boolean
  handleDelete: (selection: Selection, cartGuid: Guid) => void
  handleEdit: (selection: Selection, cart: Cart) => void
  userName: string
  isGroupCheck?: boolean
}

export const PartyMemberOrderPreview = ({
  cart,
  isCurrentUser,
  loading = false,
  handleDelete,
  handleEdit,
  userName
}: PartyMemberOrderPreviewProps) => {
  const userDisplayName = isCurrentUser ? 'Me' : userName
  const selections = getSelectionsFromCart(cart)
  const hasSelections = Boolean(selections.length)

  const totalNumberOfItems = getTotalNumberOfSelections(selections)

  const selectionCards = hasSelections
    ? selections.map((selection) => {
        return (
          <div
            key={selection.externalId}
            data-testid='selection-card'
            className='border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-30'
          >
            <SelectionCard
              isCurrentUser={isCurrentUser}
              disabled={loading}
              onEdit={() => handleEdit(selection, cart)}
              onDelete={() => handleDelete(selection, cart.guid)}
              data-testid={`selection-${selection.externalId}`}
              selection={selection}
            />
          </div>
        )
      })
    : [
        <div
          key='no-items'
          data-testid='no-items'
          className='p-4 border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-30'
        >
          No items
        </div>
      ]

  return (
    <div
      key={cart.guid}
      data-testid='party-member-order-preview'
      className={cx('relative', { 'opacity-50': loading })}
    >
      {loading && (
        <div
          data-testid='loading'
          className='absolute m-auto left-0 right-0 text-center top-[50%]'
        >
          <Loading />
        </div>
      )}
      <div
        data-testid='preview-list'
        className='space-y-4 border border-solid rounded-lg'
      >
        <CollapsibleList
          key={`${cart.guid}-list`}
          header={
            <div
              data-testid='user-display-name'
              className='flex justify-between w-full px-2 font-bold uppercase type-subhead'
            >
              {userDisplayName}
              {hasSelections ? ` (${totalNumberOfItems})` : ''}
            </div>
          }
          defaultCollapsed={!isCurrentUser}
          indentList={false}
          backgroundColor='gray'
          isCollapsible
          containerStyles=''
        >
          {selectionCards}
        </CollapsibleList>
      </div>
    </div>
  )
}
