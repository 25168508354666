import * as React from 'react'
import { getCloudfrontUrl } from '../../../utils/img-url/image-utils'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { BannerHeader } from './BannerHeader'
import { BasicHeader } from './BasicHeader'

export const RestaurantHeaderV2 = () => {
  const {
    restaurantConfig: { bannerImage, name, image },
    uniqueIdentifier
  } = useDDIGlobals()

  return bannerImage ? (
    <BannerHeader
      bannerUrl={getCloudfrontUrl(bannerImage, 'large')!}
      logoUrl={getCloudfrontUrl(image, 'small')}
      restaurantName={name}
      tableName={uniqueIdentifier}
    />
  ) : (
    <BasicHeader
      logoUrl={getCloudfrontUrl(image, 'small')}
      restaurantName={name}
      tableName={uniqueIdentifier}
    />
  )
}
