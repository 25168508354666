import { useParty } from '@local/cornucopia-subscriptions'
import * as React from 'react'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { PageWrapper } from '../../components/PageWrapper'
import { useConditionalRedirect } from '../../hooks/core/redirects/useConditionalRedirect'
import { getCartsWithItems } from '../../utils/cart-utils/helpers'
import { AddToTabPage } from '../AddToTabPage/AddToTabPage'
import { GroupAddToTabPage } from '../GroupAddToTabPage'

export const AddToTabPageContainer = () => {
  const {
    optConfig: { orderingConfig, paymentConfig }
  } = useDDIGlobals()
  const { partyRefresh } = useParty()

  const [skipRedirect, setSkipRedirect] = React.useState(false)

  const cartsWithItems = getCartsWithItems(partyRefresh)
  const allCartsEmpty = cartsWithItems && cartsWithItems.length === 0
  const hasOrder = Boolean(partyRefresh && partyRefresh.order)
  const dataAvailable = Boolean(partyRefresh)

  const renderGroupPage = orderingConfig.allowJoinableParty

  //remove preauth check when noauth splash is GA
  const shouldRedirect =
    allCartsEmpty || (paymentConfig.allowPreauth && !hasOrder)

  useConditionalRedirect({
    condition: !skipRedirect && dataAvailable && shouldRedirect
  })

  return (
    // animated routes require stable reference to animate, keep the wrapper on top level
    <PageWrapper>
      {renderGroupPage ? (
        <GroupAddToTabPage setSkipRedirect={setSkipRedirect} />
      ) : (
        <AddToTabPage setSkipRedirect={setSkipRedirect} />
      )}
    </PageWrapper>
  )
}
