import { Maybe } from '@local/shared-types'
import { PartiesByTableResponse, partiesByTable$ } from 'cornucopia-apis'
import * as React from 'react'

export const usePartiesByTable = () => {
  const [partiesByTableResponse, setPartiesByTableResponse] =
    React.useState<Maybe<PartiesByTableResponse>>(null)

  React.useEffect(() => {
    const subscription = partiesByTable$.subscribe((response: any) => {
      setPartiesByTableResponse(response)
    })

    return () => subscription.unsubscribe()
  })

  return {
    loading: partiesByTableResponse?.loading,
    partiesByTable:
      partiesByTableResponse?.data?.lookupPartiesByTable.parties || null,
    refetch: partiesByTableResponse?.refetch
  }
}
