import { track } from '@toasttab/do-secundo-analytics'
import * as React from 'react'
import { SpotlightConfig } from '../../app/types'
import { SpotlightDrawer } from './SpotlightDrawer'

export interface SpotlightBannerProps {
  spotlightConfig: SpotlightConfig
}

export const SpotlightBanner = ({ spotlightConfig }: SpotlightBannerProps) => {
  const { header, body } = spotlightConfig

  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const onClick = () => {
    track('Spotlight message viewed')
    setDrawerOpen(true)
  }
  const onClose = () => setDrawerOpen(false)

  return (
    <>
      <div
        data-testid='spotlight-message'
        onClick={onClick}
        className='flex border-solid border-[0.5px] border-[#DCDCDC] rounded-lg px-4 h-[56px] items-center justify-between mx-4 bg-[#FAFAFA] mb-6 gap-x-8'
      >
        <div className='flex items-center gap-x-4'>
          <span role='img' aria-label='spolight' className='type-large'>
            📣
          </span>
          <div
            data-testid='banner-header'
            className='type-subhead !tracking-[0.4px] text-[#3C3C3C] line-clamp-2'
          >
            {header}
          </div>
        </div>
        <div className='font-semibold type-subhead text-gray-125'>More</div>
      </div>
      <SpotlightDrawer
        open={drawerOpen}
        onClose={onClose}
        header={header}
        message={body}
      />
    </>
  )
}
