import { FormatCurrency } from '@local/format-currency'
import { MenuItem } from 'cornucopia-apis'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { imgInPreprod } from '../../utils/img-url/image-utils'

export const UpsellItem = ({ item }: { item: MenuItem }) => {
  const { guid, itemGroupGuid, name, price, imageUrls } = item
  return (
    <div>
      <li className='inline-block mb-2 list-none'>
        <Link to={`/add/${guid}/${itemGroupGuid}`}>
          <img
            className='object-cover mb-2 rounded-md'
            height='120'
            width='120'
            src={imgInPreprod(imageUrls?.small!)}
            alt={item.name}
          />
        </Link>
        <div className='w-32 font-semibold truncate text-primary-75 '>
          {name}
        </div>
        <FormatCurrency amount={price} />
      </li>
    </div>
  )
}
