import * as React from 'react'
import { useLocation } from 'react-router'
import { usePreviousLocation } from '../../../components/PreviousLocationProvider/PreviousLocationProvider'
import { useMenuVirtualizer } from '../VirtualizerContext'

export const scrollPositionMap: { [key: string]: number | null } = {}

export const clearScrollPosition = (route: string) => {
  scrollPositionMap[route] = null
}

/**
 * Utility hook that keeps track of scroll location of menu virtualizer its used at and restores it on browser navigation.
 * The only exception to restoring the position is on navigating from /confirm (checkout success) because
 * now it is a brand new order and party.
 */
export const useRestoreVirtualizerScrollPosition = () => {
  const { menuVirtualizer: virtualizer, offset } = useMenuVirtualizer()
  const { pathname, search } = useLocation()
  const { previousLocations } = usePreviousLocation()

  React.useEffect(() => {
    const storedScrollPosition = scrollPositionMap[pathname]
    const shouldScroll =
      !previousLocations[previousLocations.length - 1].startsWith('/confirm')

    if (shouldScroll && storedScrollPosition) {
      virtualizer.scrollToOffset(storedScrollPosition)
    } else {
      virtualizer.scrollToOffset(0)
    }
  }, [virtualizer, pathname, offset, previousLocations])

  React.useEffect(() => {
    return () => {
      scrollPositionMap[pathname + search] = virtualizer.scrollOffset
    }
  }, [pathname, virtualizer, virtualizer?.scrollOffset, search])
}
