import { FormatCurrency } from '@local/format-currency'
import { AppliedServiceCharge } from 'cornucopia-apis'
import * as React from 'react'

interface ServiceChargeProps {
  serviceCharge: AppliedServiceCharge
  className?: string
}

export const ServiceCharge = ({
  serviceCharge,
  className = 'flex justify-between px-4'
}: ServiceChargeProps) => {
  const percentString =
    serviceCharge.chargeType === 'FIXED' || serviceCharge.name.includes('%')
      ? ''
      : ` ${serviceCharge.serviceChargeDetails?.percent}%`
  return (
    <div className={className}>
      <div className='italic type-default'>
        {serviceCharge.name}
        {percentString}
      </div>

      <div className='pl-2 italic text-right' data-testid='service-charge'>
        <FormatCurrency amount={serviceCharge.chargeAmount} />
      </div>
    </div>
  )
}
