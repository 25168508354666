import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { getPartyMemberOrderSelections } from '../../utils/cart-utils/helpers'
import { FullScreenError } from './FullScreenError/FullScreenError'
import { useCalculateMenuData } from './MenuPage.helper'
import { getPrimaryCheckFromOrder } from './PartyReviewOrderButton/helpers'
import { VirtualMenuPage } from './VirtualMenuPage'

export const MenuPageContainer = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { partyRefresh } = useParty()
  const party = partyRefresh?.party
  const { restaurantConfig, optConfig } = useDDIGlobals()
  const joinablePartyEnabled = optConfig.orderingConfig.allowJoinableParty
  const { currentUser } = useCurrentUser()
  const myReorderSelections = getPartyMemberOrderSelections(
    partyRefresh?.order?.checks[0]?.selections,
    currentUser
  )
  const primaryCheck = getPrimaryCheckFromOrder(partyRefresh?.order)

  const restaurantName = restaurantConfig.name || 'the restaurant'

  const inviteCode = location.state && location.state.inviteCode

  useEffect(() => {
    // clear invite guid once user has loaded into the party
    if (inviteCode && inviteCode === party?.guid) {
      navigate('/', { state: { inviteCode: null } })
    }
  }, [navigate, inviteCode, party?.guid])

  const { finalMenus, featuredItems, refetch, error } = useCalculateMenuData(
    !!joinablePartyEnabled,
    myReorderSelections,
    primaryCheck?.selections
  )

  if (error) {
    return (
      <FullScreenError
        retry={() => {
          refetch()
        }}
      >
        Sorry, we encountered a problem loading menus from {restaurantName}.
      </FullScreenError>
    )
  }

  return (
    <div data-testid='menu-page-container'>
      <VirtualMenuPage
        key={finalMenus?.length}
        menus={finalMenus}
        featuredItems={featuredItems}
      />
    </div>
  )
}
