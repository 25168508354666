import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { phoneNumberPlaceholder } from '@toasttab/buffet-pui-phone-utilities'

export const defaultMask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const getPhoneMaskByCountry = (country: CountryIsoCode) => {
  const examplePhoneNumber = phoneNumberPlaceholder(country)

  return examplePhoneNumber
    ? examplePhoneNumber
        .split('')
        .map((char) => (/\d/.test(char) ? /\d/ : char)) // replace all digits with a regex
    : defaultMask
}
