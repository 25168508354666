import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import {
  getCartsWithItems,
  getPartyMemberCart,
  getSelectionsFromCart
} from '../../../utils/cart-utils/helpers'
import { useConditionalRedirect } from './useConditionalRedirect'

export const useRedirectIfEmptyCart = ({ skip } = { skip: false }) => {
  const { currentUser } = useCurrentUser()
  const { partyRefresh } = useParty()

  const cart = getPartyMemberCart(partyRefresh?.carts, currentUser)
  const selections = getSelectionsFromCart(cart)

  const cartIsEmpty = Boolean(cart && selections.length === 0)
  useConditionalRedirect({
    to: '/',
    condition: !skip && Boolean(cartIsEmpty)
  })
}

export const useRedirectIfEmptyGroupCarts = ({ skip } = { skip: false }) => {
  const { partyRefresh } = useParty()

  const cartsWithItems = getCartsWithItems(partyRefresh)
  const allCartsEmpty = cartsWithItems && cartsWithItems.length === 0

  useConditionalRedirect({
    to: '/',
    condition: !skip && Boolean(allCartsEmpty)
  })
}
