import { useAuthContext } from '@local/cornucopia-subscriptions'
import { analyticsIdentify } from '@toasttab/do-secundo-analytics'
import * as React from 'react'

export const useTrackIdentifyUser = () => {
  const { authContext } = useAuthContext()

  React.useEffect(() => {
    if (authContext?.user?.guid) {
      analyticsIdentify(authContext?.user.guid)
    }
  }, [authContext])
}
