import cx from 'classnames'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import * as React from 'react'
import { buildSelectionsMap } from '../../../utils/cart-helpers'
import { useGetPartyMemberCart } from '../../../utils/cart-utils/helpers'
import { FeaturedItem } from './FeaturedItem'
import styles from './FeaturedMenuImageBanner.module.css'

interface FeaturedMenuImageBannerProps {
  featuredItems?: FinalMenuItemV2[]
}

export const FeaturedMenuImageBanner = ({
  featuredItems
}: FeaturedMenuImageBannerProps) => {
  const { loading, cart } = useGetPartyMemberCart()

  if (!featuredItems?.length) {
    return null
  }

  const selections = Boolean(loading || !cart)
    ? []
    : cart?.order?.checks[0]?.selections

  const selectionsMap = buildSelectionsMap(selections)
  return (
    <div>
      <div data-testid='featured-wrapper'>
        <div className='flex flex-row'>
          <div className='w-full mt-3 border border-b-0 border-l-0 border-r-0' />
          <div className='min-w-[128px] px-2 py-1 mb-5 font-sans text-center border border-solid rounded-full text-hint type-overline'>
            FEATURED ITEMS
          </div>
          <div className='w-full mt-3 border border-b-0 border-l-0 border-r-0' />
        </div>
        <ul
          className={cx(
            styles.carousel,
            'overflow-auto whitespace-nowrap flex gap-2'
          )}
        >
          {featuredItems.map((item) => (
            <FeaturedItem
              key={item.guid}
              item={item}
              selectionsMap={selectionsMap}
            />
          ))}
        </ul>

        <div className='flex flex-row'>
          <div className='w-full mt-3 mb-4 border border-b-0 border-l-0 border-r-0' />
        </div>
      </div>
    </div>
  )
}
