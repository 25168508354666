import { PaymentOptionsWrapper } from '@local/payment-options'
import { StockBox } from 'cornucopia-apis'
import * as React from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  matchPath,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import {
  DDIGlobalsProvider,
  useDDIGlobals
} from '../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { Progress } from '../components/Menus/Progress/Progress'
import { PreviousLocationProvider } from '../components/PreviousLocationProvider/PreviousLocationProvider'
import {
  TransitionDirection,
  TransitionProvider,
  getTransitionClass,
  useTransitionContext
} from '../components/TransitionProvider/TransitionContext'
import { TransitionalNavigate } from '../hooks/core/redirects/TransitionalRedirect'
import { useFlag } from '../hooks/core/useFlag/useFlag'
import { useRefreshPartyOnNavigation } from '../hooks/core/useRefreshPartyOnNavigation/useRefreshPartyOnNavigation'
import { LDFlags } from '../launchdarkly/flags'
import { CheckReviewPage } from '../pages/CheckReviewPage/CheckReviewPage'
import { MenuPageContainer } from '../pages/MenuPage/MenuPage'
import { PayOnlyLandingPage } from '../pages/PayOnlyLandingPage/PayOnlyLandingPage'
import { SplashScreenPage } from '../pages/SplashScreenPage'
import { ORDERING_ROUTES, getOrderingRoutes } from './OrderingRoutes'
import { getLegacyRoutes } from './getLegacyRoutes'
import {
  getMenuEnabled,
  getPayAndGoEnabled,
  resetScrollPosition
} from './helpers'
import { DDIGlobals } from './types'

export interface AppProps {
  ooGlobals: DDIGlobals
}

export function App({ ooGlobals }: AppProps) {
  const shelves = [
    {
      name: 'payment-options-shelf',
      components: [
        {
          componentId: 'payment-options-shelf',
          component: PaymentOptionsWrapper,
          priority: 1
        }
      ]
    }
  ] as any

  return (
    <DDIGlobalsProvider ddiGlobals={ooGlobals}>
      <BrowserRouter basename={ooGlobals.routerBasename}>
        <TransitionProvider>
          <StockBox
            stockConfig={{
              shelves
            }}
            data-testid='tabs-spa-payment-options-stockbox'
          />
          <AnimatedRoutes />
        </TransitionProvider>
      </BrowserRouter>
    </DDIGlobalsProvider>
  )
}

const ConfirmationPageContainer = React.lazy(
  () => import('../pages/ConfirmationPage/ConfirmationPageContainer')
)

export const AnimatedRoutes = () => {
  const { mode, optConfig, restaurantConfig } = useDDIGlobals()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { transition, setTransition } = useTransitionContext()
  useRefreshPartyOnNavigation()

  const noauthNoSplashEnabled = useFlag(LDFlags.NO_SPLASH_NOAUTH)
  const payAndGoEnabled = useFlag(LDFlags.OPT_PAY_AND_GO)
  const menuAndOrderEnabled = useFlag(LDFlags.MENU_AND_ORDER)
  const isUnifiedOnboarding = useFlag(LDFlags.UNIFIED_ONBOARDING)

  const addItemRoute = '/add/:itemGuid/:itemGroupGuid'
  const menuRoute = '/'

  const isMenuRoute =
    matchPath(addItemRoute, location.pathname) ||
    matchPath(menuRoute, location.pathname)

  const isMNO = menuAndOrderEnabled && mode === 'OPT'

  const getPaymentEnabled = () => {
    if (isMNO) {
      return false
    }
    return optConfig.paymentConfig.paymentEnabled
  }

  const orderingEnabled = mode === 'OPT' && restaurantConfig.orderingAvailable

  return (
    <PreviousLocationProvider>
      <SplashScreenPage />
      <TransitionGroup data-testid='animated-routes'>
        <CSSTransition
          // prevent menu page re-render when closing item modal
          key={isMenuRoute ? '1' : location.pathname}
          onEntered={() => resetScrollPosition(location)}
          onExited={() => setTransition(TransitionDirection.None)}
          timeout={300}
          classNames={getTransitionClass(location.pathname, transition)}
        >
          <React.Suspense fallback={<Progress />}>
            <Routes location={location}>
              {getMenuEnabled({
                mode,
                optConfig,
                payAndGoEnabled,
                isUnifiedOnboarding
              }) && (
                <>
                  <Route path='/' element={<MenuPageContainer />} />
                  <Route path={addItemRoute} element={<MenuPageContainer />} />
                  <Route
                    path={ORDERING_ROUTES.CHECK_REVIEW}
                    element={<CheckReviewPage />}
                  />
                  <Route
                    path={ORDERING_ROUTES.CONFIRM}
                    element={<ConfirmationPageContainer />}
                  />
                </>
              )}

              {getPayAndGoEnabled({
                mode,
                optConfig,
                payAndGoEnabled,
                isUnifiedOnboarding
              }) && <Route path='/' element={<PayOnlyLandingPage />} />}

              {orderingEnabled &&
                getOrderingRoutes({
                  optConfig,
                  searchParams,
                  noauthNoSplashEnabled
                })}
              {
                /* These routes are currently being rendered via opt-web */
                getLegacyRoutes({
                  paymentEnabled: getPaymentEnabled()
                })
              }
              <Route
                path='*'
                element={
                  <TransitionalNavigate
                    to={{ pathname: '/', search: searchParams.toString() }}
                    replace
                  />
                }
              />
            </Routes>
          </React.Suspense>
        </CSSTransition>
      </TransitionGroup>
    </PreviousLocationProvider>
  )
}
