import { Maybe } from '@local/shared-types'
import cx from 'classnames'
import * as React from 'react'
import { imgInPreprod } from '../../../../utils/img-url/image-utils'
import { LazyLoad } from '../../LazyLoad/LazyLoad'

export interface MenuItemImageProps {
  imageUrl: Maybe<string>
  outOfStock: boolean
}

export const MenuItemImage = ({ imageUrl, outOfStock }: MenuItemImageProps) => {
  if (!imageUrl) {
    return <div data-testid='no-image-spacer' className='w-[86px]' />
  }

  return (
    <LazyLoad rootMargin={'0px 0px 100% 0px'}>
      {(loaded) => (
        <div
          data-testid='menu-item-image'
          style={{
            backgroundImage: loaded ? `url(${imgInPreprod(imageUrl)})` : 'hmmm'
          }}
          className={cx(
            'flex-shrink-0 w-[104px] h-[104px] bg-cover bg-center relative rounded-lg border border-solid border-gray-30',
            'before:z-0 before:absolute before:top-0 before:bottom-0 before:right-0 before:left-0 before:bg-gradient-to-br before:from-transparent before:to-darken-8 dark before:rounded-[7px]',
            { 'saturate-0 opacity-50': outOfStock }
          )}
        />
      )}
    </LazyLoad>
  )
}
