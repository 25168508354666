import * as React from 'react'

import { ContactInfo } from '../../utils/auth-utils/auth-utils'
import { ShowForUS } from '../ShowForUS'
import { BackButton } from './BackButton'
import { LoginButton } from './LoginButton'

export interface PageToolbarProps {
  contactInfo?: ContactInfo
  left?: React.ReactNode
  right?: React.ReactNode
}

export const PageToolbar = ({
  contactInfo,
  left = <BackButton />,
  right = (
    <ShowForUS>
      <LoginButton contactInfo={contactInfo} />
    </ShowForUS>
  )
}: PageToolbarProps) => {
  return (
    <div className='fixed top-0 h-16 z-50 flex justify-between items-center w-full pr-4 pl-6 bg-white border-b-[0.75px] border-solid border-t-0 border-r-0 border-l-0 border-gray-30'>
      {left}
      {right}
    </div>
  )
}
