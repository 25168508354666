import { ErrorResponse, Response } from 'cornucopia-apis'
import { Observable } from 'rxjs'

export const fireSplit = async (
  splitCallback: () => Observable<Response | ErrorResponse>,
  onSuccess: () => void,
  onComplete: () => void
) => {
  return new Promise((resolve) => {
    splitCallback().subscribe((result) => {
      if (result.kind === 'OK' || result.code === '409') {
        onSuccess()
        onComplete()
      }
      resolve(result)
    })
  })
}
