import * as React from 'react'
import { Route } from 'react-router-dom'
import { AddToTabPageContainer } from '../pages/AddToTabPageContainer'
import { GroupContactInfoPageContainer } from '../pages/GroupContactInfoPageContainer'
import { JoinAGroupPage } from '../pages/JoinAGroupPage/JoinAGroupPage'
import { StartTabPageContainer } from '../pages/StartTabPage'
import { checkIfStartInSearchParams } from './helpers'
import { OptConfig } from './types'

export enum ORDERING_ROUTES {
  START = '/tab-management/start',
  ADD = '/tab-management/add',
  JOIN = '/tab-management/join',
  CHECK_REVIEW = '/tab-management/check-review',
  CONTACT_INFO = '/tab-management/contact-info',
  CONFIRM = '/tab-management/confirm'
}

export const getOrderingRoutes = ({
  optConfig,
  searchParams,
  noauthNoSplashEnabled
}: {
  optConfig: OptConfig
  searchParams: URLSearchParams
  noauthNoSplashEnabled: boolean
}) => {
  const {
    orderingConfig: { allowJoinableParty, allowTabs },
    paymentConfig: { allowPreauth }
  } = optConfig

  const tabsSpaRoutes = [
    {
      condition: allowJoinableParty,
      path: ORDERING_ROUTES.CONTACT_INFO,
      element: (
        <GroupContactInfoPageContainer
          isStartingTab={checkIfStartInSearchParams(searchParams)}
        />
      )
    },
    {
      condition: noauthNoSplashEnabled || allowPreauth || !allowTabs,
      path: ORDERING_ROUTES.START,
      element: <StartTabPageContainer />
    },
    {
      condition: true,
      path: ORDERING_ROUTES.ADD,
      element: <AddToTabPageContainer />
    },
    {
      condition: true,
      path: ORDERING_ROUTES.JOIN,
      element: <JoinAGroupPage />
    }
  ]

  return tabsSpaRoutes.map((route: any) => {
    return (
      route.condition && (
        <Route key={route.path} path={route.path} element={route.element} />
      )
    )
  })
}
