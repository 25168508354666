import { OptionButton } from '@local/option-button'
import * as React from 'react'
import PayTabBalanceIcon from '../../../../src/assets/pay-tab-balance-icon.svg'

import styles from './PaymentOptions.module.css'

interface TabOwnerPaymentOptionsProps {
  handlePayBalance: () => void
}

export const TabOwnerPaymentOptions = ({
  handlePayBalance
}: TabOwnerPaymentOptionsProps) => {
  return (
    <>
      <tr className={styles.paymentOptionRow}>
        <td>
          <OptionButton
            icon={<PayTabBalanceIcon />}
            text='Pay tab balance'
            onClick={handlePayBalance}
            data-testid='pay-tab-balance-button'
            className='rounded-tl rounded-tr'
          />
        </td>
      </tr>
      <tr className={styles.paymentOptionRow}>
        <td>
          <div
            data-testid='pay-tab-balance-description'
            className='flex justify-center pr-2 m-4 text-secondary type-subhead'
          >
            Since you put your card down for the tab, you are responsible for
            the balance of the bill (whatever remains after others contribute).
          </div>
        </td>
      </tr>
    </>
  )
}
