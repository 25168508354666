import { BottomButton } from '@local/button'
import { FormatCurrency } from '@local/format-currency'
import * as React from 'react'
import {
  TransitionDirection,
  useTransitionContext
} from '../TransitionProvider/TransitionContext'

interface OrderButtonProp {
  subtotal: number
  onClick: () => Promise<void>
  submitting: boolean
}

export const OrderButton = ({
  subtotal,
  onClick,
  submitting
}: OrderButtonProp) => {
  const { setTransition } = useTransitionContext()
  return (
    <BottomButton
      data-testid='add-to-tab-button'
      disabled={submitting}
      loading={submitting}
      onClick={() => {
        setTransition(TransitionDirection.ForwardWithExit)
        onClick()
      }}
    >
      <div className='whitespace-nowrap'>Place order</div>
      <div className='mx-1'>{`\u00B7`}</div>
      <FormatCurrency amount={subtotal} />
    </BottomButton>
  )
}
