import { JoinOrCreatePartyMode } from '@local/shared-types'
import { track } from '@toasttab/do-secundo-analytics'
import { joinOrCreateParty } from 'cornucopia-apis'
import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BottomContinueButton,
  ContactForm,
  ContactHeader,
  ContactInfoValues,
  getContactFormInitialValues,
  validationSchema
} from '../../components/Contact'
import {
  TransitionDirection,
  useTransitionContext
} from '../../components/TransitionProvider/TransitionContext'

export const ContactInfoStartGroupPage = () => {
  const navigate = useNavigate()
  const { setTransition } = useTransitionContext()

  const initialValues = getContactFormInitialValues()

  const onSubmit = (
    values: ContactInfoValues,
    helpers: FormikHelpers<ContactInfoValues>
  ) => {
    joinOrCreateParty({
      user: { name: values.fullName, phoneNumber: values.phone },
      mode: JoinOrCreatePartyMode.CREATE
    }).subscribe((result) => {
      track('Party started')
      if (result.kind === 'OK') {
        setTransition(TransitionDirection.ForwardWithExit)
        navigate('/')
      } else {
        helpers.setSubmitting(false)
      }
    })
  }

  return (
    <div data-testid='group-contact-info-page-start'>
      <ContactHeader headerText='Contact info needed to get started' />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <ContactForm submitButton={<BottomContinueButton />} />
      </Formik>
    </div>
  )
}
