import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { Formik } from 'formik'
import * as React from 'react'
import { useDDIGlobals } from '../DDIGlobalsProvider'

import { ContactForm } from './ContactForm/ContactForm'
import { getContactValidationSchema } from './StartTabForms.helpers'
import { ContactFormValues } from './StartTabForms.types'

type ContactInfo = {
  firstName: string
  lastName: string
  phone: string
}

export interface ContactProps {
  initialContactInfo: ContactInfo
  onSubmit: (values: ContactFormValues) => Promise<void>
}

export const ContactFormWrapper = ({
  initialContactInfo,
  onSubmit
}: ContactProps) => {
  const { restaurantConfig } = useDDIGlobals()
  const rxCountry = restaurantConfig.country as CountryIsoCode
  const contactValidationSchema = getContactValidationSchema(rxCountry)

  return (
    <Formik
      initialValues={initialContactInfo}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={contactValidationSchema}
    >
      <ContactForm contactValidationSchema={contactValidationSchema} />
    </Formik>
  )
}
