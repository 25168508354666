export type ContactInfo = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

type LocalStorageAuth = {
  // guestInfo gets stored when unauthenticated users provide info somewhere in-app (ex: splash page, checkout page)
  guestInfo?: {
    value: {
      name?: string
      lastName?: string
      phone?: string
      email?: string
    }
  }
  // recognizedCustomer gets stored when users are logged in
  recognizedCustomer?: {
    value: {
      firstName?: string
      lastName?: string
      phone?: string
      email?: string
    }
  }
  // unverifiedCustomer get stored when PW+ users elect to create an account at checkout, but have not verified
  // We're not going to bother adding support for PW+ at this point in time
  unverifiedCustomer?: {}
}

const splitNameIntoFirstAndLast = (
  name: string
): { firstName?: string; lastName?: string } => {
  const splitName = name.split(' ')
  const firstName = splitName.shift()
  const lastName = splitName.join(' ')

  return {
    firstName,
    lastName
  }
}

export const removeUSCountryCodeFromPhone = (
  phone?: string
): string | undefined => {
  if (!phone) {
    return undefined
  }

  return phone.slice(1)
}

const sanitizeGuestInfoName = (name?: string) => {
  if (!name || name.match(/Guest [0-9]+/)) {
    return null
  }

  return name
}

export const getContactInfoInitialValues = (): ContactInfo => {
  try {
    const auth: LocalStorageAuth = JSON.parse(
      localStorage?.getItem('toast-opt-auth') ?? '{}'
    )

    const guestInfo = auth.guestInfo?.value ?? {}
    const guestInfoName = sanitizeGuestInfoName(guestInfo.name)
    const splitGuestInfoName = splitNameIntoFirstAndLast(guestInfoName ?? '')
    const recognizedCustomer = auth.recognizedCustomer?.value ?? {}
    recognizedCustomer.phone = removeUSCountryCodeFromPhone(
      recognizedCustomer.phone
    )

    return {
      firstName:
        recognizedCustomer.firstName || splitGuestInfoName.firstName || '',
      lastName:
        recognizedCustomer.lastName ||
        guestInfo.lastName ||
        splitGuestInfoName.lastName ||
        '',
      phone: recognizedCustomer.phone || guestInfo.phone || '',
      email: recognizedCustomer.email || guestInfo.email || ''
    }
  } catch (error) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    }
  }
}
