import { Maybe } from '@local/shared-types'
import { Cart, Check, Order } from 'cornucopia-apis'
import { ORDERING_ROUTES } from '../../../app/OrderingRoutes'

const getCTA = (
  carts: Cart[],
  allowJoinableParty: boolean,
  allowTabs: boolean
) => {
  if (!allowTabs) {
    return 'Review order'
  }
  const hasMultipleCartSelections =
    carts.filter((cart) => cart.order?.checks[0].numberOfSelections > 0)
      .length > 1
  if (hasMultipleCartSelections && allowJoinableParty) {
    return 'Continue to place group order'
  }

  return 'Continue to place order'
}

export interface GetTabSpaRedirectUrlProps {
  primaryCheck: Maybe<Check>
  allowPreauth: boolean
  allowTabs: boolean
  allowJoinableParty: boolean
  noauthNoSplashEnabled: boolean
  name?: string | undefined
}

const getTabSpaRedirectUrl = ({
  primaryCheck,
  allowPreauth,
  allowTabs,
  allowJoinableParty,
  noauthNoSplashEnabled,
  name
}: GetTabSpaRedirectUrlProps) => {
  const hasSelections = Number(primaryCheck?.numberOfSelections) > 0
  const addToTabCondition = hasSelections || primaryCheck?.appliedPreauthInfo

  // We will replace this with a check if the party is private
  // after OPTPartyV2 is updated to include it
  const nameIsSet = name && !name.startsWith('Guest')

  // payg
  if (!allowTabs) {
    return ORDERING_ROUTES.START
  }

  // noauth: tabs and groups
  if (!allowPreauth && allowJoinableParty && nameIsSet) {
    return ORDERING_ROUTES.ADD
  }

  // noauth: tabs and feature flag off
  if (!allowPreauth && !noauthNoSplashEnabled) {
    return ORDERING_ROUTES.ADD
  }

  // preauth: tabs and groups and noauth when feature flag is on
  return addToTabCondition ? ORDERING_ROUTES.ADD : ORDERING_ROUTES.START
}

const getPrimaryCheckFromOrder = (order?: Order) => {
  if (!order || !order.checks || !order.checks.length) {
    return null
  }
  return order.checks.find((check: any) => check.guid === order.checkGuid)
}

const partyCartsHaveItems = (carts: Cart[]) =>
  carts.some((cart: any) =>
    Boolean(
      cart.order && getPrimaryCheckFromOrder(cart.order)?.numberOfSelections
    )
  )

const countItemsInPartyCarts = (carts: Cart[]) =>
  carts.reduce(
    (acc, cart) =>
      acc +
      ((cart.order &&
        getPrimaryCheckFromOrder(cart.order)?.numberOfSelections) ||
        0),
    0
  )

export {
  countItemsInPartyCarts,
  getCTA,
  getPrimaryCheckFromOrder,
  getTabSpaRedirectUrl,
  partyCartsHaveItems
}
