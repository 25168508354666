import { Maybe } from '@local/shared-types'
import { SearchIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { useInView } from 'react-intersection-observer'

import { MenuItem as MenuItemType } from 'cornucopia-apis'
import { MenuItem } from '../../../pages/MenuPage/MenuItem'
import { SelectionMapEntry } from '../../../utils/cart-helpers'

interface MenuSearchBodyProps {
  notFound: boolean
  hasInput: boolean
  results: MenuItemType[]
  selectionsMap: Maybe<{ [key: string]: SelectionMapEntry }>
  orderingAvailable: boolean
}
export const MenuSearchBody = ({
  notFound,
  hasInput,
  results,
  orderingAvailable,
  selectionsMap
}: MenuSearchBodyProps) => {
  const [numberOfItemsToRender, setNumberOfItemsToRender] = React.useState(10)
  const { ref } = useInView({
    onChange: () => setNumberOfItemsToRender((prev) => prev + 10)
  })

  if (notFound) {
    return (
      <div
        data-testid='not-found'
        className='w-full h-3/5 flex flex-col justify-center items-center'
      >
        <SearchIcon size='2xl' />
        <div className='text-center'>
          We couldn't find any items
          <br />
          matching your search.
        </div>
      </div>
    )
  }

  if (!hasInput) {
    return (
      <div
        data-testid='no-term'
        className='w-full h-3/5 flex flex-col justify-center items-center'
      >
        <SearchIcon size='2xl' />
        <div>Begin your search.</div>
      </div>
    )
  }

  const itemsToRender = results.slice(0, numberOfItemsToRender)

  return (
    <>
      <ul data-testid='search-result-list' className='p-0 mb-16 list-none'>
        {itemsToRender.map((item, i) => {
          const cartSelection =
            selectionsMap &&
            selectionsMap[item.guid]?.itemGroupGuid === item.itemGroupGuid
              ? selectionsMap[item.guid]
              : undefined

          return (
            <li
              data-testid='search-result-item'
              key={`${item.guid}-${item.itemGroupGuid}-${i}`}
              className='w-full py-4 border-b-[1px] border-solid border-t-0 border-l-0 border-r-0'
            >
              <MenuItem
                cartSelection={cartSelection}
                orderingAvailable={orderingAvailable}
                item={item}
              />
            </li>
          )
        })}
        <div ref={ref} />
      </ul>
    </>
  )
}
