import { CreditCard } from '@toasttab/do-secundo-credit-card'
import cx from 'classnames'
import { Field } from 'formik'
import * as React from 'react'
import { useDDIGlobals } from '../../DDIGlobalsProvider'

export interface CreditCardContainerProps {
  isSubheading?: boolean
  showHeading: boolean
}

export const CreditCardContainer = ({
  isSubheading = false,
  showHeading = true
}: CreditCardContainerProps) => {
  const { restaurantGuid, shortUrl, guestExpressBaseUri, toastwebBaseUri } =
    useDDIGlobals()
  const {
    restaurantConfig: { amexAccepted }
  } = useDDIGlobals()

  return (
    <div className='space-y-4'>
      {showHeading && (
        <div
          className={cx(
            'font-semibold text-gray-75 font-sans',
            isSubheading ? 'text-corn-header-xs' : 'text-corn-header-sm'
          )}
        >
          DEBIT OR CREDIT
        </div>
      )}
      <Field
        component={CreditCard}
        name={'encryptedCard'}
        amexAccepted={amexAccepted}
        toastwebBaseUri={toastwebBaseUri}
        shortUrl={shortUrl}
        variant='chunky'
        guestExpressBaseUri={guestExpressBaseUri}
        guestExpressMicrospasEnabled={true}
        restaurantGuid={restaurantGuid}
      />
    </div>
  )
}
