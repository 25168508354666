import cx from 'classnames'
import * as React from 'react'
import ArrowRightIcon from '../../../../src/assets/arrow-right.svg'
import Spinner from '../../../../src/assets/spinner-32-icon.svg'

export interface OptionButtonProps {
  icon?: React.ReactNode
  text: string | React.ReactNode
  onClick: () => void
  'data-testid'?: string
  className?: string
  disabled?: boolean
  loading?: boolean
  rightIcon?: React.ReactNode
}

export const OptionButton = ({
  icon,
  text,
  onClick,
  disabled,
  className,
  loading,
  'data-testid': testId,
  rightIcon = <ArrowRightIcon data-testid='arrow-right' />
}: OptionButtonProps) => (
  <button
    disabled={disabled || loading}
    className={cx(
      className,
      'flex bg-white border-none items-center w-full justify-between p-5 text-default',
      { 'opacity-50': disabled }
    )}
    onClick={onClick}
    data-testid={testId}
  >
    <div className='flex items-center gap-3'>
      {icon && <div className='flex items-center'>{icon}</div>}
      <div className='mb-0.5'>{text}</div>
    </div>
    <div className='flex'>
      {loading ? (
        <Spinner data-testid='spinner' className='-mr-2' />
      ) : (
        rightIcon
      )}
    </div>
  </button>
)
