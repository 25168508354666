import { Order } from 'cornucopia-apis'

export const isEmpty = (order: Order) => {
  const { checkGuid } = order
  const mainCheck = order.checks.find((check) => check.guid === checkGuid)
  if (!mainCheck) {
    return true
  }
  return mainCheck.numberOfSelections === 0
}
