import { Badge } from '@toasttab/buffet-pui-badge'
import { OPTPartyV3 } from 'cornucopia-apis'
import * as React from 'react'

interface PartyMemberBadgesProps {
  party: OPTPartyV3 | undefined
}

export const PartyMemberBadges = ({ party }: PartyMemberBadgesProps) => {
  if (!party || !party?.members || party?.members.length === 0) {
    return null
  }
  return (
    <div
      data-testid='badge-container'
      className='mt-3 mx-2 flex flex-wrap justify-center gap-[6px]'
    >
      {party?.members
        ?.slice(0, Math.min(party.members.length, 5))
        .map(({ name }, index) => {
          const showFillerText = party.members.length > 5 && index === 4
          const displayText = showFillerText
            ? `+${party.members.length - 4} more`
            : name

          return (
            <Badge
              key={`group-name-badge-${index}`}
              data-testid='group-name-badge'
              color='neutral'
              variant='statusSm'
              className='rounded-lg'
            >
              {displayText}
            </Badge>
          )
        })}
    </div>
  )
}
