import { Guid } from '@local/shared-types'
import cx from 'classnames'
import { CardType, CreditCard } from 'cornucopia-apis'
import { FieldInputProps } from 'formik'
import * as React from 'react'
import {
  AmexIcon,
  CreditCardIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon
} from '../../../../src/assets/credit-cards'
import { useDDIGlobals } from '../../../../src/components/DDIGlobalsProvider'

export const getSvg = (cardType: CardType) => {
  switch (cardType) {
    case CardType.Amex:
      return <AmexIcon />
    case CardType.Discover:
      return <DiscoverIcon />
    case CardType.Mastercard:
      return <MastercardIcon />
    case CardType.Visa:
      return <VisaIcon />
    default:
      return <CreditCardIcon />
  }
}

export interface CardRadioProps {
  card: CreditCard
  field: FieldInputProps<Guid>
}

export const CardRadio = ({ card, field }: CardRadioProps) => {
  const {
    restaurantConfig: { amexAccepted }
  } = useDDIGlobals()
  const amexNotAccepted = !amexAccepted && card.cardType === 'AMEX'
  const expirationMessage = `${card.expired ? 'Expired' : 'Expires'} ${
    card.expirationMonth
  }/${card.expirationYear}`
  const amexMessage = amexNotAccepted
    ? ' \u00B7 AmEx unavailable at this restaurant'
    : null
  const disabled = card.expired || amexNotAccepted

  return (
    <div
      data-testid={`card-${card.guid}`}
      className='border-b border-t-0 border-l-0 border-r-0 border-solid py-4 mt-0'
    >
      <div>
        <label data-testid={`card-radio-${card.guid}`}>
          <div className='flex gap-4 items-center'>
            <input
              type='radio'
              name={field.name}
              value={card.guid}
              checked={field.value === card.guid}
              onChange={field.onChange}
              onBlur={field.onBlur}
              className={cx('h-5 w-5', { 'opacity-40': card.expired })}
              disabled={disabled}
            />
            <div
              className={cx('flex', { 'opacity-40': disabled || card.expired })}
            >
              {getSvg(card.cardType)}
            </div>
            <div>
              <div className='type-subhead font-semibold'>
                {card.maskedPan.replace('XXXX-', '**** ')}
              </div>
              <div className='type-subhead text-gray-100 font-normal'>
                <span
                  className={cx('type-subhead', {
                    'text-brand-75 font-bold': card.expired
                  })}
                >
                  {expirationMessage}
                </span>
                {amexMessage}
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  )
}
