import React from 'react'
import { OrderingDisabledBanner } from '../../../components/OrderingDisabledBanner/OrderingDisabledBanner'
import { PartyReviewOrderButton } from '../PartyReviewOrderButton/PartyReviewOrderButton'
import { useDDIGlobals } from '../../../components/DDIGlobalsProvider'
import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { useFlag } from '../../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../../launchdarkly/flags'
import { getCartsWithItems } from '../../../utils/cart-utils/helpers'

export const Footer = () => {
  const { mode, optConfig, restaurantConfig } = useDDIGlobals()
  const { partyRefresh } = useParty()
  const { currentUser } = useCurrentUser()

  const unifiedOnboardingEnabled = useFlag(LDFlags.UNIFIED_ONBOARDING)
  const cartsWithItems = getCartsWithItems(partyRefresh)
  const allCartsEmpty = cartsWithItems?.length === 0
  const orderingDisabled =
    unifiedOnboardingEnabled && !restaurantConfig.orderingAvailable

  const showOrderingDisabledBanner =
    mode === 'OPT' && orderingDisabled && allCartsEmpty
  const showPartyReviewOrderButton = Boolean(
    currentUser && partyRefresh && !allCartsEmpty
  )

  return (
    <>
      {showOrderingDisabledBanner && (
        <OrderingDisabledBanner rxName={restaurantConfig.name} />
      )}
      {showPartyReviewOrderButton && (
        <div
          data-testid='review-order-button'
          className='fixed bottom-0 left-0 right-0 flex z-[41] bg-white content-center shadow- text-center drop'
          style={{ boxShadow: '0 -2px 4px 0 var(--darken-12)' }}
        >
          <PartyReviewOrderButton
            optConfig={optConfig}
            partyRefresh={partyRefresh}
          />
        </div>
      )}
    </>
  )
}
