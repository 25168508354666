import * as React from 'react'
import { VirtualMenuGroup } from './VirtualMenu.helpers'

interface GroupHeaderProps {
  virtualGroup: VirtualMenuGroup
}

export const GroupHeader = ({ virtualGroup }: GroupHeaderProps) => {
  const { isMonoMenu, name, menuName, guid } = virtualGroup

  const heading =
    isMonoMenu || name === menuName ? name : `${menuName} · ${name}`

  return (
    <span
      className='mx-6 py-2 !uppercase !tracking-[1.2px] !leading-[16px] font-semibold type-subhead text-gray-75'
      data-testid={`group-header-${guid}`}
    >
      {heading}
    </span>
  )
}
