import { useGetPopularItems, useMenus } from '@local/cornucopia-subscriptions'
import { Maybe, Selection } from '@local/shared-types'
import {
  FinalMenuItemV2,
  ItemTag,
  Menu,
  MenuGroup,
  MenuItem
} from 'cornucopia-apis'
import * as React from 'react'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { useFlag } from '../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../launchdarkly/flags'
import { addReorderGroupToMenus } from './helpers'

const PopularMenuName = 'Popular'

export type MenuItemToRender = Omit<
  MenuItem,
  | 'pricingStrategy'
  | 'pricingRules'
  | 'isDiscountable'
  | 'taxInfo'
  | 'stockQuantity'
  | 'modifierGroups'
  | 'type'
>

export type MenuGroupToRender = Omit<MenuGroup, 'items' | 'type'> & {
  items: MenuItemToRender[] | FinalMenuItemV2[]
  itemTags?: ItemTag[]
  dietaryItemTags?: ItemTag[]
  isReorder?: boolean
  __typename?: string
}

export type MenuToRender = Omit<Menu, 'groups' | 'type'> & {
  groups: MenuGroupToRender[]
  isReorder?: boolean
  __typename?: string
}

export const getFeaturedItems = (menus: Maybe<Menu[]>) => {
  if (!menus) {
    return menus
  }

  const featuredItems = menus
    .flatMap((menuToFlatten) => menuToFlatten.groups)
    .reduce((acc, group) => {
      return [
        ...acc,
        ...group.items
          .filter((item: any) => {
            const tagNames = item.itemTags?.map((tags: any) => tags?.name)
            const isFeatured = !!tagNames?.find(
              (name: any) => name?.toLowerCase() === 'featured'
            )
            const hasImage = !!item.imageUrl
            const isInStock = !item.outOfStock
            return isFeatured && hasImage && isInStock
          })
          .map((item) => ({
            ...item,
            groupName: group.name
          }))
      ]
    }, [] as FinalMenuItemV2[])
    .map((item: any) => ({ ...item })) // To avoid two groups pointing at the same item instance

  const deduplicatedFeaturedItems: Map<string, FinalMenuItemV2> = new Map(
    featuredItems.map((item: any) => [item.guid, item])
  )

  return [...deduplicatedFeaturedItems.values()]
}

const createGroup = (
  name: string,
  items: MenuItemToRender[] | FinalMenuItemV2[]
): MenuGroupToRender => ({
  guid: name,
  name,
  items,
  groups: [],
  itemTags: [],
  dietaryItemTags: []
})

const createMenu = (
  name: string,
  items: MenuItemToRender[] | FinalMenuItemV2[]
): MenuToRender => ({
  id: name,
  name,
  groups: [createGroup(name, items)],
  description: ''
})

export const prependPopularItems = (
  menus: Menu[],
  popularItems?: FinalMenuItemV2[]
): MenuToRender[] => {
  if (!popularItems) {
    return menus
  }
  if (
    !menus.length ||
    !popularItems.length ||
    menus.find(
      (menu) =>
        menu.name === PopularMenuName ||
        menus
          .flatMap((menuToFlatten) => menuToFlatten.groups)
          .find((group) => group.name === PopularMenuName)
    )
  ) {
    return menus
  }

  const items = popularItems.filter((item) => !item.outOfStock).slice(0, 5)

  // prepend
  if (menus.length > 1) {
    const popularMenu = createMenu(PopularMenuName, items)

    return [popularMenu, ...menus]
  } else {
    const popularGroup = createGroup(PopularMenuName, items)

    const groupsToPrependTo = menus[0]!.groups as unknown as MenuGroupToRender[]
    const firstMenu = menus[0]! as unknown as MenuToRender
    const newGroups = [popularGroup].concat(groupsToPrependTo)
    firstMenu.groups = newGroups

    return [...menus]
  }
}

export const constructFeaturedFromPopular = (
  popularItems?: FinalMenuItemV2[]
) => {
  if (!popularItems || popularItems.length === 0) {
    return []
  }

  return popularItems
    .filter(
      ({ imageUrl, outOfStock, price }) =>
        price && price < 50 && !outOfStock && !!imageUrl
    )
    .sort((a, b) => (b?.price || 0) - (a?.price || 0))
    .slice(0, 3)
}

export const useCalculateMenuData = (
  joinablePartyEnabled: boolean,
  mySelections: Selection[] | null,
  allSelections?: Selection[]
) => {
  const {
    data: menus,
    loading: menusLoading,
    error,
    refetch: refetchMenus
  } = useMenus()
  const menuExperimentEnabled = useFlag(LDFlags.OPT_MENU_EXPERIMENT)
  const {
    optConfig: { menuConfig },
    restaurantConfig
  } = useDDIGlobals()

  const { popularMenuItems, loading: popItemsLoading } = useGetPopularItems()
  const featuredItems = useFeaturedItems(menus, popularMenuItems)

  const isLoading = menusLoading || popItemsLoading

  const finalMenus = React.useMemo(() => {
    if (!menus || isLoading) {
      return null
    }
    const menusWithPopular =
      menuExperimentEnabled && menuConfig.showPopularItems
        ? prependPopularItems(menus, popularMenuItems)
        : menus

    return addReorderGroupToMenus({
      orderingAvailable: restaurantConfig.orderingAvailable,
      menus: menusWithPopular,
      selections: joinablePartyEnabled ? mySelections : allSelections
    })
  }, [
    mySelections,
    allSelections,
    joinablePartyEnabled,
    restaurantConfig,
    menus,
    popularMenuItems,
    isLoading,
    menuExperimentEnabled,
    menuConfig.showPopularItems
  ])

  const refetch = () => {
    refetchMenus && refetchMenus()
  }

  return { finalMenus, featuredItems, refetch, error }
}

export const useFeaturedItems = (
  menus: Maybe<Menu[]>,
  popItems: FinalMenuItemV2[]
) => {
  const itemsFromMenu = React.useMemo(() => getFeaturedItems(menus), [menus])

  if (itemsFromMenu?.length === 0) {
    return constructFeaturedFromPopular(popItems)
  } else {
    return itemsFromMenu
  }
}
