import { Maybe } from '@local/shared-types'
import { VirtualItem } from '@tanstack/react-virtual'
import cx from 'classnames'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import * as React from 'react'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { QuickAddSelection } from '../../components/Menus/MenuItems/InstaAddItem/QuickAddItem'
import { NoMenus } from '../../components/Menus/NoMenus'
import { buildSelectionsMap } from '../../utils/cart-helpers'
import { useGetPartyMemberCart } from '../../utils/cart-utils/helpers'
import { GroupHeader } from './GroupHeader'
import { MenuItem } from './MenuItem'
import { VirtualItemWrapper } from './VirtualItemWrapper'
import { VirtualElementKind, VirtualMenuGroup } from './VirtualMenu.helpers'
import { useRestoreVirtualizerScrollPosition } from './useRestoreVirtualierScrollPosition/useRestoreVirtualizerScrollPosition'

interface VirtualMenuItemsProps {
  virtualItems: VirtualItem[]
  size: number
  virtualizedMenuItems: (FinalMenuItemV2 | VirtualMenuGroup)[]
}

export const VirtualMenuItems = ({
  virtualItems,
  size,
  virtualizedMenuItems
}: VirtualMenuItemsProps) => {
  const { cart } = useGetPartyMemberCart()
  const {
    restaurantConfig: { orderingAvailable }
  } = useDDIGlobals()
  useRestoreVirtualizerScrollPosition()

  const selectionsMap: Maybe<{ [key: string]: QuickAddSelection }> =
    React.useMemo(
      () => buildSelectionsMap(cart?.order?.checks[0]?.selections || []),
      [cart]
    )

  if (virtualItems.length === 0) {
    return <NoMenus />
  }

  return (
    <ul
      className='relative flex items-center p-0 list-none'
      style={{
        height: `${size}px`
      }}
    >
      {virtualItems.map((virtualItem) => {
        const { index } = virtualItem
        const listItem = virtualizedMenuItems[index]

        const nextItem = virtualizedMenuItems[index + 1]
        const endOfGroup =
          nextItem && nextItem.kind === VirtualElementKind.GroupHeader

        if (!listItem) {
          return null
        }

        if (listItem.kind === VirtualElementKind.GroupHeader) {
          return (
            <VirtualItemWrapper
              key={`group-header-${listItem.guid}`}
              virtualItem={virtualItem}
            >
              <div
                className={`flex items-end w-full h-full border-b-0 border-l-0 border-r-0 border-solid ${
                  index === 0 ? 'border-t-0' : 'border-t-[1px]'
                }`}
              >
                <GroupHeader virtualGroup={listItem} />
              </div>
            </VirtualItemWrapper>
          )
        }

        const { guid, itemGroupGuid } = listItem
        const cartSelection =
          selectionsMap && selectionsMap[guid]?.itemGroupGuid === itemGroupGuid
            ? selectionsMap[guid]
            : undefined

        const borderClass =
          'border-b-[1px] border-solid border-t-0 border-l-0 border-r-0'

        return (
          <VirtualItemWrapper
            key={`${guid}-${itemGroupGuid}-${index}`}
            virtualItem={virtualItem}
          >
            <li
              className={cx('flex items-center w-full h-full mx-6', {
                [borderClass]: !endOfGroup
              })}
              data-testid={`menu-item-${guid}-${itemGroupGuid}`}
            >
              <MenuItem
                cartSelection={cartSelection}
                orderingAvailable={orderingAvailable}
                item={listItem}
              />
            </li>
          </VirtualItemWrapper>
        )
      })}
    </ul>
  )
}
