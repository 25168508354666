import { OPTPartyV3 } from 'cornucopia-apis'
import * as React from 'react'
import ToastIcon from '../../assets/toast-icon.svg'
import { getCloudfrontUrl } from '../../utils/img-url/image-utils'
import { useDDIGlobals } from '../DDIGlobalsProvider'
import { PartyMemberBadges } from './PartyMemberBadges'

export interface JoinCheckHeaderProps {
  party: OPTPartyV3 | undefined
}

export const JoinCheckHeader = ({ party }: JoinCheckHeaderProps) => {
  const {
    restaurantConfig: { image }
  } = useDDIGlobals()
  const logoUrl = getCloudfrontUrl(image)
  const tableText =
    party && party.tableName ? `Table ${party?.tableName}` : 'Welcome!'

  return (
    <div className='flex flex-col items-center mb-8 px-6'>
      {logoUrl ? (
        <img
          aria-label='restaurant logo'
          data-testid='restaurant-logo'
          className='mt-14 w-14 h-14 drop-shadow-md border border-light rounded-lg object-cover'
          src={logoUrl}
        />
      ) : (
        <ToastIcon
          data-testid='fallback-logo'
          className='mt-14 w-14 h-14 drop-shadow-md border border-light rounded-lg object-cover'
        />
      )}
      <span
        data-testid='header-table-name'
        className='mt-9 type-overline text-gray-75'
      >
        {tableText}
      </span>
      <h1 className='mt-1 text-[34px] leading-none font-normal'>
        Join open tab
      </h1>
      <p className='mt-2 mx-2 text-hint type-default text-center'>
        Those who join can continue ordering on this check & contribute to the
        final bill.
      </p>
      <PartyMemberBadges party={party} />
    </div>
  )
}
