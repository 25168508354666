import {
  PaymentOptionsWrapper,
  SoloDinerPaymentOptions
} from '@local/payment-options'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import * as React from 'react'
import { CloseIconDrawerHeader } from '../../../../src/components/CloseIconDrawerHeader/CloseIconDrawerHeader'

export interface PaymentOptionsDrawerProps {
  open: boolean
  onClose: () => void
  isGroupOrder: boolean
  customRootId?: string
}

export const PaymentOptionsDrawer = ({
  open,
  onClose,
  isGroupOrder,
  customRootId
}: PaymentOptionsDrawerProps) => {
  const header = isGroupOrder ? 'GROUP TAB' : 'MY TAB'

  return (
    <BottomDrawer
      data-corn-tabs
      isOpen={open}
      onClose={onClose}
      customHeader={<CloseIconDrawerHeader title={header} onClose={onClose} />}
      customRootId={customRootId || 'corn-tabs-payment-options-drawer'}
      data-testid='payment-options-drawer'
    >
      {isGroupOrder ? (
        <PaymentOptionsWrapper
          isGroupOrder={isGroupOrder}
          open={open}
          onComplete={onClose}
        />
      ) : (
        <SoloDinerPaymentOptions />
      )}
    </BottomDrawer>
  )
}
