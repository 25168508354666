import { NavigateProps } from 'react-router-dom'
import { useConditionalRedirect } from './useConditionalRedirect'

export interface UseConditionalRedirectInput {
  to: string | NavigateProps['to']
  condition: boolean
  replace?: boolean
  state?: NavigateProps['state']
}

/*
  Wrapper around useConditionalRedirect that allows us to use it in a component. Mimics <Navigate /> functionality while handling not being unmounted during animated transitions.
  https://github.com/remix-run/react-router/issues/8733
*/
export const TransitionalNavigate = ({ to, replace, state }: NavigateProps) => {
  useConditionalRedirect({ to, condition: true, replace, state })
  return null
}
