import cx from 'classnames'
import { Field, FieldInputProps } from 'formik'
import * as React from 'react'

import styles from './PaymentTypeField.module.css'
const checkedStyles =
  'text-primary-75 font-semibold bg-primary-0 border-2 border-primary-75'
const uncheckedStyles = cx(
  'bg-white border border-gray-30',
  styles['PaymentType__shadow-selectable']
)

interface PaymentTypeFieldProps {
  name: string
  value: string
  label: React.ReactNode
  testId?: string
}

export const PaymentTypeField = ({
  name,
  value,
  label,
  testId
}: PaymentTypeFieldProps) => {
  return (
    <Field
      name={name}
      component={PaymentType}
      label={label}
      value={value}
      testId={testId}
    />
  )
}

interface FormProps {
  form: HTMLFormElement
  field: FieldInputProps<string>
}

type PaymentTypeProps = PaymentTypeFieldProps & FormProps

const PaymentType = ({
  form,
  field,
  value,
  label,
  testId
}: PaymentTypeProps) => {
  const checked = field.value === value

  return (
    <div>
      <label
        className={cx(
          'type-default rounded-lg w-full flex justify-center items-center p-4 border-solid',
          checked ? checkedStyles : uncheckedStyles
        )}
      >
        <input
          onChange={(...a) => {
            form.setFieldTouched(field.name, true)
            field.onChange(...a)
          }}
          name='paymentMethod'
          type='radio'
          className='sr-only'
          checked={checked}
          value={value}
          data-testid={testId}
        />
        {label}
      </label>
    </div>
  )
}
