import { CardSelector } from '@local/card-selector'
import { useAuthContext, useParty } from '@local/cornucopia-subscriptions'
import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import {
  PasswordlessAuthUI,
  PasswordlessAuthUIVariant
} from '@toasttab/do-secundo-passwordless-authentication-ui'
import cx from 'classnames'
import { CreditCard } from 'cornucopia-apis'
import { Field, Form, useFormikContext } from 'formik'
import * as React from 'react'

import { useTrackIdentifyUser } from '../../../hooks/core/useTrackIdentifyUser/useTrackIdentifyUser'
import {
  trackAccountVerificationEvent,
  trackCompleteIdentity,
  trackFailedCodeEntry,
  trackSendCodeEvent
} from '../../../utils/auth-utils/heap-events'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { DisclaimerText } from '../../DisclaimerText/DisclaimerText'
import { PreauthDisclaimer } from '../../PreauthDisclaimer/PreauthDisclaimer'
import { ContactInfo } from '../ContactInfo'
import {
  getPaymentContactValidationSchema,
  isContactInfoSame
} from '../StartTabForms.helpers'
import { PaymentFormValues, PaymentMethods } from '../StartTabForms.types'

import { PaymentFormButton } from './PaymentFormButton'
import { PaymentTypeSelector } from './PaymentTypeSelector'
import { SaveCardCheckbox } from './SaveCardCheckbox'

export interface PaymentFormProps {
  canMakeApplePayPayment: boolean
  creditCards: CreditCard[]
}

export const PaymentForm = ({
  canMakeApplePayPayment,
  creditCards
}: PaymentFormProps) => {
  const { partyRefresh } = useParty()
  const { restaurantConfig } = useDDIGlobals()
  const rxCountry = restaurantConfig.country as CountryIsoCode
  useTrackIdentifyUser()

  const { values, isSubmitting, isValid, setFieldValue } =
    useFormikContext<PaymentFormValues>()

  const { paymentMethod, firstName, lastName, email, phone, selectedCard } =
    values

  const { authContext, loading: authContextLoading } = useAuthContext()
  const { user, authenticated } = authContext || {}

  React.useEffect(() => {
    if (selectedCard) {
      setFieldValue('encryptedCard', null)
    }
  }, [setFieldValue, selectedCard])

  React.useEffect(() => {
    if (!user) {
      return
    }

    const setContactFields = async () => {
      await setFieldValue('firstName', user.firstName)
      await setFieldValue('lastName', user.lastName)
      await setFieldValue('phone', user.phone)
      await setFieldValue('email', user.email)
    }

    if (!isContactInfoSame(user, values)) {
      setContactFields()
    }
  }, [setFieldValue, user, values])

  const isValidContactInfo = getPaymentContactValidationSchema(
    rxCountry
  ).isValidSync({
    paymentMethod: paymentMethod,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone
  })

  const shouldRenderContactInfo = !authenticated || !isValidContactInfo

  const allowSavingCard = authenticated && !selectedCard

  const isGroupOrder = Boolean(
    partyRefresh && partyRefresh.party.members.length > 1
  )

  return (
    <Form>
      <div
        data-testid='payment-form'
        className={`px-6 pb-6 space-y-4 ${canMakeApplePayPayment && 'mb-48'}`}
      >
        {canMakeApplePayPayment && (
          <div className='font-sans font-semibold text-gray-75 text-corn-header-sm'>
            TAB PAYMENT
          </div>
        )}
        <div
          className={cx({
            'border border-solid rounded-lg bg-corn-gray-25':
              canMakeApplePayPayment
          })}
        >
          <div
            className={cx('space-y-8', {
              'p-4': canMakeApplePayPayment
            })}
          >
            {canMakeApplePayPayment && (
              <PaymentTypeSelector paymentMethod={paymentMethod} />
            )}
            {paymentMethod === PaymentMethods.CREDIT_CARD && (
              <>
                {shouldRenderContactInfo && (
                  <ContactInfo isSubheading={canMakeApplePayPayment} />
                )}
                <div>
                  <Field
                    component={CardSelector}
                    name='selectedCard'
                    cards={creditCards}
                    canMakeApplePayPayment={canMakeApplePayPayment}
                  />

                  {allowSavingCard && <SaveCardCheckbox />}
                  {isGroupOrder && (
                    <div className='flex gap-4 !-mb-2 !mt-2 px-5 py-4 items-start border border-[#FED850] border-corn-yellow-25 border-solid rounded-lg bg-corn-yellow-25'>
                      <PreauthDisclaimer testId='preauth-disclaimer' />
                    </div>
                  )}
                </div>
              </>
            )}
            {authContextLoading ? null : (
              <div className='my-4'>
                <PasswordlessAuthUI
                  initialFirstName={firstName}
                  initialLastName={lastName}
                  initialPhoneNumber={phone?.replace(/\D/g, '')}
                  initialEmail={email}
                  auth={authContext!}
                  variant={PasswordlessAuthUIVariant.CHECKOUT_BOX}
                  trackSendCodeEvent={trackSendCodeEvent}
                  trackCompleteIdentity={trackCompleteIdentity}
                  trackAccountVerificationEvent={() =>
                    trackAccountVerificationEvent(authContext!)
                  }
                  trackFailedCodeEntry={trackFailedCodeEntry}
                />
              </div>
            )}
            <PaymentFormButton
              isGroupOrder={isGroupOrder}
              paymentMethod={paymentMethod}
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </div>
        <div className='mb-24'>
          <DisclaimerText />
        </div>
      </div>
    </Form>
  )
}
