import * as React from 'react'
import { getContactInfoInitialValues } from '../../../utils/auth-utils/auth-utils'
import { PageHeader } from '../../PageHeader'
import { PageToolbar } from '../../PageToolbar'

export const ContactHeader = ({ headerText }: { headerText: string }) => {
  const contactInfo = getContactInfoInitialValues()
  return (
    <div>
      <PageToolbar contactInfo={contactInfo} />
      <div className='mt-28'>
        <PageHeader
          header={headerText}
          subheader='By providing your name, you will be making it public to others joining a group at your table.'
        />
      </div>
    </div>
  )
}
