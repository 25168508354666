import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import cx from 'classnames'
import * as React from 'react'
import {
  VirtualMenu,
  VirtualMenuGroup
} from '../../../pages/MenuPage/VirtualMenu.helpers'
import { useMenuVirtualizer } from '../../../pages/MenuPage/VirtualizerContext'
import { CloseIconDrawerHeader } from '../../CloseIconDrawerHeader/CloseIconDrawerHeader'

interface VirtualMenuNavigationDrawerProps {
  isOpen: boolean
  handleClose: () => void
  virtualMenus: VirtualMenu[]
  activeGroup: string | null
}

export const VirtualMenuNavigationDrawer = ({
  isOpen,
  handleClose,
  virtualMenus,
  activeGroup
}: VirtualMenuNavigationDrawerProps) => {
  const { menuVirtualizer } = useMenuVirtualizer()
  const hasMultipleMenus = virtualMenus.length > 1

  const navigateToGroup = (group: VirtualMenuGroup) => {
    menuVirtualizer.scrollToIndex(group.start, {
      align: 'start'
    })
    handleClose()
  }

  return (
    <BottomDrawer
      data-corn-tabs
      customRootId='menu-nav-drawer-root'
      data-testid='menu-nav-drawer'
      isOpen={isOpen}
      onClose={handleClose}
      customHeader={
        <CloseIconDrawerHeader title='BROWSE MENU' onClose={handleClose} />
      }
    >
      <div className='flex flex-col pt-4 pb-20 px-3 h-[70vh] overflow-y-scroll'>
        {virtualMenus.map((menu: VirtualMenu) => {
          const isMonoGroup = menu.groups.length === 1

          return (
            <div
              key={`menu-nav-group-${menu.id}`}
              className={isMonoGroup ? 'mb-0' : 'mb-4'}
            >
              {hasMultipleMenus && !isMonoGroup && (
                <div
                  onClick={() => navigateToGroup(menu.groups[0]!)}
                  className='px-3 pt-4 pb-3 font-semibold text-gray-125'
                  data-testid={`${menu.id}-header-link`}
                >
                  {menu.name}
                </div>
              )}
              {menu.groups.map((group) => {
                const isActiveItem = group.name === activeGroup

                return (
                  <div key={`menu-nav-group-${group.guid}`}>
                    <button
                      data-testid={`${group.guid}-drawer-button`}
                      className={cx(
                        'flex flex-row items-center justify-between text-left p-3 bg-transparent border-none w-full',
                        {
                          '!text-[#2B4FB9] !bg-[#E7EAF6] font-semibold rounded-lg':
                            isActiveItem
                        },
                        isMonoGroup && !isActiveItem
                          ? 'font-semibold text-default'
                          : 'text-gray-75'
                      )}
                      onClick={() => navigateToGroup(group)}
                    >
                      <span>{group.name}</span>
                      <span>{group.length}</span>
                    </button>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </BottomDrawer>
  )
}
