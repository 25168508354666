import { useParty } from '@local/cornucopia-subscriptions'
import { useSpotlightMessage } from '@local/cornucopia-subscriptions/src/hooks/useSpotlightMessage'
import { Maybe } from '@local/shared-types'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import throttle from 'lodash/throttle'
import * as React from 'react'
import { useFlag } from '../../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../../launchdarkly/flags'
import { FeaturedMenuImageBanner } from '../../../pages/MenuPage/FeaturedMenuImageBanner'
import { getPrimaryCheckFromOrder } from '../../../pages/MenuPage/PartyReviewOrderButton/helpers'
import { VirtualMenu } from '../../../pages/MenuPage/VirtualMenu.helpers'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { SpotlightBanner } from '../../SpotlightMessage/SpotlightBanner'
import { ManageTabHeader } from '../ManageTabHeader/ManageTabHeader'
import { VirtualMenuNavigationBar } from '../MenuNavigationBar/MenuNavigationBar'
import { RestaurantHeaderV2 } from '../RestaurantHeaderV2/RestaurantHeaderV2'
interface RestaurantHeaderContainerProp {
  activeGroup: string | null
  virtualMenus: VirtualMenu[]
  headerRef: React.RefObject<HTMLDivElement>
  featuredItems?: Maybe<FinalMenuItemV2[]>
}

export const RestaurantHeaderContainer = ({
  activeGroup,
  virtualMenus,
  headerRef,
  featuredItems
}: RestaurantHeaderContainerProp) => {
  const {
    optConfig: { menuConfig }
  } = useDDIGlobals()
  const featuredCarouselEnabled = useFlag(LDFlags.OPT_MENU_EXPERIMENT)
  const { partyRefresh } = useParty()
  const stickyRef = React.useRef<HTMLDivElement>(null)
  const spotlightConfig = useSpotlightMessage()

  const primaryCheck = getPrimaryCheckFromOrder(partyRefresh?.order)

  const [navBarIntersected, setNavBarIntersected] = React.useState(false)

  const hasPrimaryCheck = primaryCheck && primaryCheck.numberOfSelections > 0
  const shouldRenderFeaturedCarousel =
    featuredCarouselEnabled &&
    menuConfig.showFeaturedItems &&
    featuredItems?.length

  /**
   * scroll listener on the menuNav to determine its location
   * and move the ManageTabHeader based on menuNav location in the viewport
   */
  React.useEffect(() => {
    const scrollCallback = () => {
      const menuNav = stickyRef.current
      const menuNavRectangle = menuNav ? menuNav.getBoundingClientRect() : null
      const crossingCondition =
        menuNavRectangle &&
        menuNavRectangle.top > -1 &&
        menuNavRectangle.top < 1

      setNavBarIntersected(Boolean(crossingCondition))
    }

    const throttledCallback = throttle(scrollCallback, 300)

    window.addEventListener('scroll', throttledCallback)

    return () => window.removeEventListener('scroll', throttledCallback)
  }, [navBarIntersected])
  return (
    <>
      {hasPrimaryCheck && <ManageTabHeader below={navBarIntersected} />}
      <div ref={headerRef}>
        <RestaurantHeaderV2 />
        {spotlightConfig.visible && (
          <SpotlightBanner spotlightConfig={spotlightConfig} />
        )}
        {Boolean(shouldRenderFeaturedCarousel) && (
          <FeaturedMenuImageBanner featuredItems={featuredItems!} />
        )}
      </div>
      <VirtualMenuNavigationBar
        navBarIntersected={navBarIntersected}
        stickyRef={stickyRef}
        virtualMenus={virtualMenus}
        activeGroup={activeGroup}
      />
    </>
  )
}
