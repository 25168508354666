import cx from 'classnames'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  QuickAddItem,
  QuickAddSelection
} from '../../components/Menus/MenuItems/InstaAddItem/QuickAddItem'
import { MenuItemImage } from '../../components/Menus/MenuItems/MenuItemImage/MenuItemImage'
import { MenuItemPrice } from '../../components/Menus/MenuItems/MenuItemPrice/MenuItemPrice'
import { ReorderQuickAdd } from '../../components/Menus/ReorderQuickAdd/ReorderQuickAdd'
import { constructSearchParams, getDataTestIdForItem } from './MenuItem.helpers'
import styles from './MenuItem.module.css'

export interface MenuItemWidgetProps {
  item: FinalMenuItemV2
  cartSelection?: QuickAddSelection
  imageStyles?: string
}
export const MenuItemWidget = ({
  item,
  cartSelection,
  imageStyles = 'absolute right-1 bottom-[20px] z-[1]'
}: MenuItemWidgetProps) => {
  const {
    isReorder,
    selectionGuid,
    guid,
    itemGroupGuid,
    hasModifiers,
    imageUrl,
    outOfStock
  } = item as any

  const quickAddText = cartSelection ? cartSelection.quantity : '+'

  const quickAddStyles = imageUrl ? imageStyles : 'relative mr-1'

  if (isReorder && selectionGuid) {
    return (
      <ReorderQuickAdd selectionGuid={selectionGuid} outOfStock={outOfStock} />
    )
  }

  if (!hasModifiers) {
    return (
      <div
        className={quickAddStyles}
        onClick={(e) => e.preventDefault()} // prevents menu item details page from opening when clicking quick add
      >
        <QuickAddItem
          menuItem={cartSelection}
          guid={guid}
          itemGroupGuid={itemGroupGuid}
          outOfStock={outOfStock}
        />
      </div>
    )
  }

  return (
    <div className={quickAddStyles}>
      <div className='flex items-center justify-center px-6'>
        <div className={styles.quickButtonWrapper}>
          <button
            data-testid='quick-add-link'
            disabled={outOfStock}
            className={styles.quickAddButton}
          >
            {quickAddText}
          </button>
        </div>
      </div>
    </div>
  )
}

interface MenuItemProps {
  item: FinalMenuItemV2
  cartSelection?: QuickAddSelection
  orderingAvailable: boolean
}

export const MenuItem = ({
  item,
  orderingAvailable,
  cartSelection
}: MenuItemProps) => {
  const {
    guid,
    itemGroupGuid,
    name,
    outOfStock,
    description,
    usesFractionalQuantity,
    unitOfMeasure,
    groupName
  } = item

  const price = item.price ?? 0
  const calories = item.calories ?? 0

  const searchParams = constructSearchParams(groupName)
  const addItemPath = `/add/${guid}/${itemGroupGuid}`

  const dataTestId = getDataTestIdForItem(item)

  return (
    <Link
      data-testid={dataTestId}
      className={cx('flex items-center justify-between w-full no-underline', {
        'pointer-events-none': outOfStock
      })}
      to={{ pathname: addItemPath, search: searchParams }}
      rel='nofollow'
    >
      <div data-testid='menu-item-content' className='pr-6'>
        <span
          data-testid='menu-item-name'
          className={cx(
            'font-semibold type-default text-default mr-4 line-clamp-1',
            { 'text-gray-100': outOfStock }
          )}
        >
          {name}
        </span>
        {description && (
          <p
            data-testid='menu-item-description'
            className='mt-1 mb-2 font-normal type-subhead text-gray-75 line-clamp-2'
          >
            {description}
          </p>
        )}
        <div className='flex flex-row'>
          {price > 0 && (
            <MenuItemPrice
              price={price}
              usesFractionalQuantity={usesFractionalQuantity}
              unitOfMeasure={unitOfMeasure}
              outOfStock={outOfStock}
            />
          )}
          {outOfStock && (
            <span
              data-testid='menu-item-out-of-stock-label'
              className={cx('text-gray-75 type-subhead', { 'pl-1': price })}
            >
              {`${price ? '· ' : ''}Out of Stock`}
            </span>
          )}
          {calories > 0 && (
            <span
              data-testid='menu-item-calories'
              className={cx(
                outOfStock
                  ? 'text-gray-75 type-subhead'
                  : 'text-gray-125 type-default',
                {
                  'pl-1': price || outOfStock
                }
              )}
            >
              {`${price || outOfStock ? '· ' : ''}${calories} Cal`}
            </span>
          )}
        </div>
      </div>

      <div className='relative'>
        <MenuItemImage
          imageUrl={item.imageUrls?.small}
          outOfStock={outOfStock}
        />
        {orderingAvailable && (
          <MenuItemWidget cartSelection={cartSelection} item={item} />
        )}
      </div>
    </Link>
  )
}
