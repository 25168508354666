import { Maybe } from '@local/shared-types'
import { Range } from '@tanstack/react-virtual'
import { FinalMenuItemV2 } from 'cornucopia-apis'
import { MenuToRender } from './MenuPage.helper'

export interface VirtualMenu {
  name: string
  groups: VirtualMenuGroup[]
  id: string
}
export interface VirtualMenuGroup {
  name: string
  menuName: string
  start: number
  end: number
  length: number
  isMonoMenu: boolean
  guid: string
  kind: VirtualElementKind.GroupHeader
}

export enum VirtualElementHeight {
  GroupHeader = 56,
  EmptyMenuItem = 96,
  MenuItem = 135
}

export enum VirtualElementKind {
  GroupHeader = 'GroupHeader',
  MenuItem = 'MenuItem'
}

export const getVirtualElementHeight = (
  item: VirtualMenuGroup | FinalMenuItemV2
) => {
  if (item.kind === VirtualElementKind.GroupHeader) {
    return VirtualElementHeight.GroupHeader
  }
  if (Boolean(item.imageUrl) || Boolean(item.description)) {
    return VirtualElementHeight.MenuItem
  }
  return VirtualElementHeight.EmptyMenuItem
}

export const getVirtualData = (menus?: Maybe<MenuToRender[]>) => {
  if (!menus) {
    return {
      virtualMenus: [],
      virtualGroups: [],
      virtualizedMenuItems: []
    }
  }

  const virtualMenus: VirtualMenu[] = []

  const virtualGroups: VirtualMenuGroup[] = []

  // keep track of where we are in the iteration to mark beginning/end of each group
  let currentIndex = 0

  const virtualizedMenuItems = menus.reduce<
    (FinalMenuItemV2 | VirtualMenuGroup)[]
  >((flatItems, menu) => {
    const { name: menuName } = menu

    const virtualMenu: VirtualMenu = {
      name: menuName,
      groups: [],
      id: menu.id
    }

    const flatGroupsItems = menu.groups.reduce<
      (FinalMenuItemV2 | VirtualMenuGroup)[]
    >((acc, group) => {
      const { name, guid, items } = group

      const virtualGroup: VirtualMenuGroup = {
        name,
        menuName: menuName,
        start: currentIndex,
        end: currentIndex + group.items.length,
        length: items.length,
        isMonoMenu: menus.length === 1,
        kind: VirtualElementKind.GroupHeader,
        guid
      }

      virtualMenu.groups.push(virtualGroup)
      virtualGroups.push(virtualGroup)

      // increment by how many items we added +1 for the group header
      currentIndex += group.items.length + 1

      acc.push(virtualGroup)

      return acc.concat(
        (group.items as unknown as FinalMenuItemV2[]).map(
          (item: FinalMenuItemV2) => {
            item.groupName = virtualGroup.name
            return item
          }
        )
      )
    }, [])

    virtualMenus.push(virtualMenu)

    return flatItems.concat(flatGroupsItems)
  }, [])

  return { virtualGroups, virtualMenus, virtualizedMenuItems }
}

export const calculateActiveGroup = (
  virtualGroups: VirtualMenuGroup[],
  visibleRange: Pick<Range, 'startIndex' | 'endIndex'>,
  shouldOffset?: boolean
) => {
  const contentOffset = shouldOffset ? 7 : 5 // eyeball how much to offset when restaurantHeader is rendered
  const { startIndex, endIndex } = visibleRange

  const middle = Math.round(
    startIndex + (endIndex - startIndex) / 2 - contentOffset
  )

  let activeMenu: string

  for (let virtualGroup of virtualGroups) {
    const { start, end, name } = virtualGroup
    // account for single groups with <=2 items
    if (
      (middle > start && middle < end) ||
      middle === start ||
      middle === end
    ) {
      activeMenu = name
      return activeMenu
    }
  }
}

export const getInitialActiveGroupName = (
  menus: MenuToRender[] | undefined | null
) => {
  if (!menus) {
    return 'Loading...'
  }

  return menus?.[0]?.groups[0]?.name || null
}

export const menuGroupDescriptionMap: { [key: string]: string } = {
  'Popular items': 'Our most commonly ordered items'
}
