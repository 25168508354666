import cx from 'classnames'
import { Field, FieldProps } from 'formik'
import * as React from 'react'
import MaskedInput, { Mask } from 'react-text-mask'

import styles from './Input.module.css'
import Warning from './warning.svg'

export interface InputProps extends React.HTMLProps<MaskedInput> {
  label?: string
  field: {
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onBlur: React.FocusEventHandler<HTMLInputElement>
    value: string
    name: string
  }
  meta: {
    error?: string
    touched: boolean
  }
  message?: { text: string; height: string }
  testId?: string
  props?: any[]
  className?: string
  mask?: Mask
}

/*
  https://github.com/text-mask/text-mask/issues/806
  There's a bug in react-mask-input that requires a mask to be present for controlled inputs to update value.
  This is a non functional mask that does nothing.
  */
const acceptAllMask = Array.from(Array(256)).map(() => /./)

export const Input = ({
  field,
  meta,
  label,
  id,
  className,
  message,
  mask = acceptAllMask,
  ...props
}: InputProps) => {
  const [focus, setFocus] = React.useState(false)

  const { error, touched } = meta
  const { onBlur, value } = field

  const showError = !focus && touched && error
  const filled = String(value).length > 0

  return (
    <div className={cx('relative w-full', className)}>
      {showError && <Warning className={styles.warning} />}
      {label && (
        <label
          className={cx(
            { [styles.focusAnimation]: focus || filled },
            'absolute top-4.5 left-4 w-fit h-fit text-left transition-all text-gray-100'
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {/* @ts-ignore */}
      <MaskedInput
        mask={mask}
        guide={false}
        {...props}
        className={cx([
          'w-full px-4 pt-6 pb-2 text-default border-solid border rounded-lg appearance-none',
          { [styles.focused]: focus },
          { [styles.inputError]: showError }
        ])}
        {...field}
        onBlur={(event) => {
          onBlur(event)
          setFocus(false)
        }}
        onFocus={() => setFocus(true)}
        id={id}
      />
      {message && (
        <div
          className={cx(
            'px-1',
            styles.legalInitial,
            focus && 'pt-1',
            focus && styles.legalExpanded
          )}
          style={{ height: focus ? message.height : 0 }}
        >
          {message.text}
        </div>
      )}
      {showError && (
        <div className={styles.errorMessage} role='alert'>
          {error}
        </div>
      )}
    </div>
  )
}

interface InputFieldProps {
  name: string
  inputProps?: React.HTMLProps<MaskedInput> & {
    mask?: (string | RegExp)[] | false
    message?: { text: string; height: string }
  }
}

export const InputField = ({ name, inputProps }: InputFieldProps) => {
  return (
    <Field name={name}>
      {(formikProps: FieldProps) => {
        return (
          <Input
            {...inputProps}
            field={formikProps.field}
            meta={formikProps.meta}
          />
        )
      }}
    </Field>
  )
}
