import { useParty } from '@local/cornucopia-subscriptions'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import * as React from 'react'
import {
  PaymentOptionsDrawer,
  usePaymentOptionsHandler
} from '../../../../packages/payment-options-drawer'
import { useFlag } from '../../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../../launchdarkly/flags'
import { useDDIGlobals } from '../../DDIGlobalsProvider'
import { MenuAndOrderTabManagementDrawer } from '../../TabManagement/MenuAndOrderTabManagement/MenuAndOrderTabManagement'

export interface ManageTabHeaderProps {
  below: boolean
}

export const ManageTabHeader = ({ below }: ManageTabHeaderProps) => {
  const isMNO = useFlag(LDFlags.MENU_AND_ORDER)
  const {
    optConfig: { paymentConfig }
  } = useDDIGlobals()
  const { partyRefresh } = useParty()
  const members = partyRefresh?.party?.members
  const isGroupOrder = members && members.length > 1

  const { paymentOptionsOpen, openPaymentOptions, closePaymentOptions } =
    usePaymentOptionsHandler()

  const headerText = isGroupOrder ? 'Manage & pay group tab' : 'Pay my tab'

  return (
    <>
      <div
        data-testid='manage-tab-header-container'
        className={cx(
          'w-full sticky transition-all duration-500 z-[2] top-0',
          below && 'top-[72px]'
        )}
      >
        <button
          data-testid='manage-tab-header-button'
          onClick={openPaymentOptions}
          className='flex items-center justify-between w-full px-5 py-4 text-white border-none cursor-pointer bg-primary-75 type-default'
        >
          {headerText}
          <div>
            <span className='px-2 py-1 mr-4 font-semibold rounded bg-darken-20 type-overline'>
              Tab open
            </span>
            <ChevronRightIcon size='xs' />
          </div>
        </button>
      </div>
      {isMNO || !paymentConfig.paymentEnabled ? (
        <MenuAndOrderTabManagementDrawer
          isGroupOrder={Boolean(isGroupOrder)}
          open={paymentOptionsOpen}
          onClose={closePaymentOptions}
        />
      ) : (
        <PaymentOptionsDrawer
          customRootId='menu-page-payment-options-drawer'
          isGroupOrder={Boolean(isGroupOrder)}
          open={paymentOptionsOpen}
          onClose={closePaymentOptions}
        />
      )}
    </>
  )
}
