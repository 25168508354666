import { FormatCurrency } from '@local/format-currency'
import { Guid, Selection } from '@local/shared-types'
import cx from 'classnames'
import {
  AppliedServiceCharge,
  Cart,
  PartyMember,
  dispatchEditItemUserRequest,
  useUpdateCart
} from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { calculateOrderSubtotal } from '../../../../src/utils/cart-utils/helpers'

import { PartyMemberOrderPreview } from './PartyMemberOrderPreview'

/**
 * Used on the Start Group Tab and Add to Group Tab pages (GroupStartTabPage.tsx, GroupAddToTabPage.tsx)
 * @param carts
 * @param currentUser
 * @param users
 * @param serviceCharges
 * @returns table with current group members and their orders that have not yet been added to the tab
 */
export const ReviewGroupOrderTable = ({
  carts,
  currentUser,
  users,
  serviceCharges
}: {
  carts: Cart[]
  currentUser: PartyMember
  users: PartyMember[]
  serviceCharges: AppliedServiceCharge[]
}) => {
  const updateCart = useUpdateCart()
  const navigate = useNavigate()

  const [mutatedCartGuid, setMutatedCartGuid] = React.useState<null | string>(
    null
  )

  const handleDelete = (selection: Selection, cartGuid: Guid) => {
    setMutatedCartGuid(cartGuid)
    const response$ = updateCart(undefined, selection.externalId)
    response$.subscribe(() => {
      // opt handles error message
      setMutatedCartGuid(null)
    })
  }

  const handleEdit = (selection: Selection, cart: Cart) => {
    const { itemGuid, itemGroupGuid, externalId } = selection
    dispatchEditItemUserRequest({
      cartGuid: cart.guid,
      selectionGuid: externalId,
      itemGuid,
      itemGroupGuid,
      cartApi: 'OPT',
      visibility: 'KIOSK'
    })
    navigate('/edit')
  }

  // make currentUser's cart first in the list
  const sortedCarts = carts
    .slice()
    .sort((cart) => (cart.guid === currentUser.cartGuid ? -1 : 1))

  const hasServiceCharges = serviceCharges.length > 0

  return (
    <div className='flex flex-col gap-6 mx-6'>
      <div className='font-sans font-semibold text-hint text-corn-header-sm'>
        {users.length > 1 ? 'REVIEW GROUP ORDER' : 'REVIEW ORDER'}
      </div>

      {sortedCarts.map((cart) => {
        const user = users.find(
          (partyMember) => partyMember.cartGuid === cart.guid
        )

        // if user is already on the tab and isn't adding to the order, don't show
        const userHasNoItems =
          Boolean(user?.orderSelectionIds?.length) &&
          Boolean(
            cart.order.checks.find((check) => check.numberOfSelections === 0)
          )

        if (!user || userHasNoItems) return null
        const { name: userName } = user

        const isCurrentUser = cart.guid === currentUser.cartGuid

        const isMutating = cart.guid === mutatedCartGuid

        return (
          <PartyMemberOrderPreview
            key={cart.guid}
            cart={cart}
            isCurrentUser={isCurrentUser}
            loading={isMutating}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            userName={userName}
          />
        )
      })}
      <div className='px-4 py-3 border border-solid rounded-lg bg-corn-gray-25'>
        {hasServiceCharges && (
          <div className='italic type-subhead'>
            Additional service charge applied at checkout.
          </div>
        )}
        <div
          className={cx(
            'flex font-bold items-center justify-between bg-corn-gray-25',
            hasServiceCharges && 'mt-1'
          )}
        >
          <div>Order subtotal</div>
          <div>
            <FormatCurrency amount={calculateOrderSubtotal(carts)} />
          </div>
        </div>
      </div>
    </div>
  )
}
