import { Selection } from '@local/shared-types'
import { Loading } from '@toasttab/do-secundo-loading'
import cx from 'classnames'
import {
  Cart,
  dispatchEditItemUserRequest,
  useUpdateCart
} from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  calculateCartSubtotal,
  getSelectionsFromCart,
  getServiceChargesFromCart
} from '../../../../src/utils/cart-utils/helpers'
import { SelectionCard } from '../../../selection-card'
import styles from './ReviewOrderTable.module.css'
import { ServiceCharge } from './ServiceCharge'
import { Subtotal } from './Subtotal'

interface ReviewOrderTableProps {
  cart: Cart
  ignoreFixedCharges?: boolean
}

export const ReviewOrderTable = ({
  cart,
  ignoreFixedCharges
}: ReviewOrderTableProps) => {
  const updateCart = useUpdateCart()
  const navigate = useNavigate()

  const [mutating, setMutating] = React.useState(false)

  const selections = getSelectionsFromCart(cart)
  const serviceCharges = getServiceChargesFromCart(cart).filter(
    (appliedServiceCharge) => {
      return !ignoreFixedCharges || appliedServiceCharge.chargeType !== 'FIXED'
    }
  )

  const subtotal = calculateCartSubtotal(cart, serviceCharges)

  const handleDelete = (selection: Selection) => {
    setMutating(true)
    const response$ = updateCart(undefined, selection.externalId)
    response$.subscribe(() => {
      // opt handles error message
      setMutating(false)
    })
  }

  const handleEdit = (selection: Selection) => {
    const { itemGuid, itemGroupGuid, externalId } = selection
    dispatchEditItemUserRequest({
      cartGuid: cart.guid,
      selectionGuid: externalId,
      itemGuid,
      itemGroupGuid,
      cartApi: 'OPT',
      visibility: 'KIOSK'
    })
    navigate('/edit')
  }

  const selectionCards = selections.map((selection) => {
    return (
      <tr className={styles.selectionCardRow} key={selection.externalId}>
        <td>
          <SelectionCard
            isCurrentUser
            disabled={mutating}
            onEdit={() => handleEdit(selection)}
            onDelete={() => handleDelete(selection)}
            data-testid={selection.externalId}
            selection={selection}
          />
        </td>
      </tr>
    )
  })

  return (
    <div
      data-testid='review-order-wrapper'
      className={cx('relative', { 'opacity-50': mutating })}
    >
      <div className='mx-6 space-y-4'>
        <div className='font-sans font-semibold text-hint text-corn-header-sm'>
          REVIEW ORDER
        </div>
        {mutating && (
          <div
            data-testid='loader'
            className='absolute m-auto left-0 right-0 text-center top-[50%]'
          >
            <Loading />
          </div>
        )}
        <div className='border border-solid rounded-lg'>
          <table className='w-full border-collapse font-effra'>
            <tbody>
              {selectionCards}
              {serviceCharges.map((serviceCharge, index) => {
                return (
                  <tr
                    key={
                      serviceCharge.serviceChargeDetails?.guid ||
                      `service-charge-${index}`
                    }
                  >
                    <td>
                      <ServiceCharge
                        className={
                          index === 0
                            ? 'flex justify-between px-4 pt-3'
                            : undefined
                        }
                        serviceCharge={serviceCharge}
                      />
                    </td>
                  </tr>
                )
              })}

              {serviceCharges.length > 0 ? (
                <Subtotal
                  subtotal={subtotal}
                  className='flex justify-between px-4 pt-1 pb-3 bg-corn-gray-25'
                  subtotalString='Subtotal + Fees'
                />
              ) : (
                <Subtotal subtotal={subtotal} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
