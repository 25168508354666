import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { ReviewGroupOrderTable } from '@local/review-order-table'
import { preauth } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { Divider } from '../../components/Divider'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { PaymentFormWrapper } from '../../components/StartTabForms'
import { PaymentFormValues } from '../../components/StartTabForms/StartTabForms.types'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import {
  getPartyMembers,
  getServiceChargesFromCart
} from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const GroupStartTabPage = ({
  setSkipRedirect
}: {
  setSkipRedirect: (value: boolean) => void
}) => {
  const { currentUser } = useCurrentUser()
  const { partyRefresh } = useParty()
  const navigate = useNavigate()

  if (!partyRefresh || !currentUser) {
    return <LoadingPage dataTestId='group-start-tab-loading' />
  }

  const partyMembers = getPartyMembers(partyRefresh)

  const headerText =
    partyMembers && partyMembers.length > 1 ? 'Start group tab' : 'Start a tab'

  const serviceCharges = getServiceChargesFromCart(partyRefresh.carts[0])

  const contactInfo = getContactInfoInitialValues()

  const onSubmit = async (values: PaymentFormValues) => {
    setSkipRedirect(true)
    await new Promise((resolve) => {
      preauth(values).subscribe((res) => {
        if (res.kind === 'OK') {
          navigate(ORDERING_ROUTES.CONFIRM, { replace: true })
        } else {
          setSkipRedirect(false)
        }
        resolve(res)
      })
    })
  }

  return (
    <div data-testid='group-start-tab-page'>
      <PageToolbar
        left={<BackToSafeLocationButton />}
        contactInfo={contactInfo}
      />
      <div className='mt-28'>
        <PageHeader
          header={headerText}
          subheader="It's like a real tab. A temporary hold will be placed on your card until the tab is closed."
        />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewGroupOrderTable
            currentUser={currentUser}
            users={partyRefresh.party?.members || []}
            carts={partyRefresh.carts}
            serviceCharges={serviceCharges}
          />
        </div>
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <PaymentFormWrapper
            initialContactInfo={contactInfo}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}
