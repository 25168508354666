import { usePartiesByTable } from '@local/cornucopia-subscriptions/src/hooks/usePartiesByTable'
import * as React from 'react'
import { PageWrapper } from '../../components/PageWrapper'
import { clearScrollPosition } from '../MenuPage/useRestoreVirtualierScrollPosition/useRestoreVirtualizerScrollPosition'
import { AvailableGroups } from './AvailableGroups'
import { NoAvailableGroups } from './NoAvailableGroups'

export const JoinAGroupPage = () => {
  const { partiesByTable, refetch } = usePartiesByTable()

  React.useEffect(() => {
    clearScrollPosition('/')
  }, [])

  return (
    <PageWrapper testId='join-a-group-page'>
      {Boolean(partiesByTable?.length) ? (
        <AvailableGroups refetch={refetch} partiesByTable={partiesByTable} />
      ) : (
        <NoAvailableGroups refetch={refetch} />
      )}
    </PageWrapper>
  )
}
