import { FormatCurrency } from '@local/format-currency'
import { Selection } from '@local/shared-types'
import * as React from 'react'

import { calculateSelectionsTotal } from '../../../../src/utils/cart-utils/helpers'

export const SelectionsSubtotal = ({
  selections
}: {
  selections: Selection[]
}) => {
  const selectionsSubtotal = calculateSelectionsTotal(selections)

  return (
    <div
      data-testid='individual-subtotal'
      className='flex items-center justify-between px-4 pt-4 pb-4 font-bold border-t border-b-0 border-l-0 border-r-0 border-solid'
    >
      <span>Subtotal</span>
      <FormatCurrency amount={selectionsSubtotal} />
    </div>
  )
}
