import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { usePreviousLocation } from '../../PreviousLocationProvider/PreviousLocationProvider'
import { BackButton } from '../BackButton'

const unsafePathFragments = ['/edit', '/add', '/tab/new']

export const BackToSafeLocationButton = () => {
  const location = useLocation()
  const { previousLocations } = usePreviousLocation()
  // if previous location is menu, default to -1 delta navigation
  if (previousLocations[previousLocations.length - 1] === '/') {
    return <BackButton />
  }

  let goTo = '/'

  for (let i = previousLocations.length - 1; i >= 0; i--) {
    const previousLocation = previousLocations[i]
    const isUnique = previousLocation !== location.pathname
    const isSafe = !unsafePathFragments.some((unsafePath) => {
      return previousLocation.includes(unsafePath)
    })

    if (isUnique && isSafe) {
      goTo = previousLocations[i]
      break
    }
  }

  return <BackButton goTo={goTo} />
}
