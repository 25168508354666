import { Party } from 'cornucopia-apis'
import { now } from 'lodash'

export const constructRedirectURL = ({
  party,
  uniqueIdentifier
}: {
  party: Party
  uniqueIdentifier: string
}) => {
  const splitUrlOnTts = window.location.toString().split('toast-table-service')

  const rxUrlWithoutCheckid = `${splitUrlOnTts[0]}order-and-pay${
    splitUrlOnTts[1].split(uniqueIdentifier)[0]
  }`

  const redirectBaseUrl = `${rxUrlWithoutCheckid}${party.tableName}`

  return `${redirectBaseUrl}/rejoin?pg=${party.guid}&pmg=${
    party.partyMemberGuid
  }&mat=${party.memberAuthToken}&e=${now() + 1000}`
}
