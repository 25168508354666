import { useRestaurant } from '@local/cornucopia-subscriptions'
import { checkLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import * as React from 'react'

export interface FormatCurrencyProps {
  testId?: string | number
  amount?: number
  showLeadingSign?: boolean
  renderWhenZero?: string
}

export const FormatCurrency = ({
  testId = `FormatCurrency`,
  amount = 0,
  showLeadingSign = false,
  renderWhenZero
}: FormatCurrencyProps) => {
  const data = useRestaurant()

  if (amount === 0 && renderWhenZero) {
    return <span>{renderWhenZero}</span>
  }

  const rxI18n = data?.restaurant?.i18n || {
    currency: Currency['USD'],
    locale: 'en-US',
    country: 'US'
  }

  const leadingSign = showLeadingSign && amount >= 0 ? '+' : ''

  const currency = rxI18n.currency
  const locale = checkLocale(rxI18n.locale)

  const formattedCurrency = formatCurrency(
    {
      amount,
      currency
    },
    locale,
    {
      trailingZeros: true
    }
  )

  return (
    <span data-testid={testId}>
      {leadingSign}
      {formattedCurrency}
    </span>
  )
}
