import { useParty } from '@local/cornucopia-subscriptions'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useDDIGlobals } from '../../../../src/components/DDIGlobalsProvider'
import { getCloudfrontUrl } from '../../../../src/utils/img-url/image-utils'
import { SplashScreen } from './SplashScreen'
import { SplashScreenBanner } from './SplashScreenBanner'
export interface GroupPartyModeDrawerProps {
  uniqueIdentifier: string
}

export const GroupPartyModeDrawer = ({
  uniqueIdentifier
}: GroupPartyModeDrawerProps) => {
  const [open, setOpen] = React.useState(true)
  const onClose = () => setOpen(false)

  const { partyRefresh, loading } = useParty()
  const party = partyRefresh?.party
  const location = useLocation()

  const {
    restaurantConfig: { image, name }
  } = useDDIGlobals()

  const logoUrl = getCloudfrontUrl(image)

  React.useEffect(() => {
    const userLeftParty = !Boolean(party) && !open && !loading

    if (userLeftParty) {
      setOpen(true)
    }
  }, [setOpen, party, open, loading])

  if (
    !open ||
    party ||
    loading ||
    location.pathname.includes('tab-management')
  ) {
    return null
  }

  return (
    <BottomDrawer
      data-corn-tabs
      data-testid='group-party-mode-drawer'
      isOpen={open}
      onClose={() => {
        // This timeout ensures the drawer has time to animate it's closing
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      hasBeenClosedOverride={!open}
      customHeader={<></>}
      customRootId='group-party-mode-drawer'
      disableBackgroundClose
    >
      <div className='h-fit'>
        <SplashScreenBanner name={name} logoUrl={logoUrl} />
        <div className='relative'>
          <div className='h-[50vh]' />
          <div className='absolute top-0 left-0 right-0'>
            <SplashScreen onClose={onClose} tableName={uniqueIdentifier} />
          </div>
        </div>
      </div>
    </BottomDrawer>
  )
}
