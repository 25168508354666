import { addEventProperties, track } from '@toasttab/do-secundo-analytics'
import isNil from 'lodash/isNil'
import {
  nameSpaces,
  namespacedStorage
} from '../storage-utils/namespaced-storage'

const experimentStorage = namespacedStorage(nameSpaces.TOAST_OPT_EXPERIMENTS)

/**
 * Accepts buckets weights where the first weight corresponds to the weight of the first variant(0 based count) and returns a variant.
 * Example: Input: 2, 1, 1. Variant 0 - 50%, variant 1 - 25%, variant 2 - 25%.
 */

export const createWeightedBucket = (weights: number[]) => {
  const buckets = weights.reduce<number[]>(
    (acc, val, idx) => [...acc, ...Array.from({ length: val }, () => idx)],
    []
  )

  const randomIndex = Math.floor(Math.random() * buckets.length)

  return buckets[randomIndex]
}

const trackExperiment = (experimentName: string, variant: number) => {
  track('Experimental treatment applied', {
    [`${experimentName}-variant`]: variant,
    [`${experimentName}-treatment`]: true
  })
  addEventProperties({
    [`${experimentName}-treatment`]: true
  })
}

const getOrStoreVariant = (name: string, weights: number[]) => {
  const storedVariant = experimentStorage.getItem(name)

  if (!isNil(storedVariant)) {
    return storedVariant
  }
  const variant = createWeightedBucket(weights)
  experimentStorage.setItem(name, variant)
  return variant
}

export { experimentStorage, getOrStoreVariant, trackExperiment }
