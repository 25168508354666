import {
  ChevronRightIcon,
  MoreVertIcon,
  SearchIcon
} from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import truncate from 'lodash/truncate'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import MenuIcon from '../../../assets/menu-icon.svg'
import { VirtualMenu } from '../../../pages/MenuPage/VirtualMenu.helpers'
import { MenuSearch } from '../MenuSearch/MenuSearch'
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer'
import { VirtualMenuNavigationDrawer } from './MenuNavigationDrawer'

export function consolidateActiveMenuGroups(groups: any) {
  return groups.reduce((acc: any, { guid, name, items, isReorder }: any) => {
    if (!items || !items.length) {
      return acc
    }
    const newMenuGroupAnchor = {
      id: guid,
      href: `#${guid}`,
      title: name,
      name: truncate(name, {
        length: 30,
        omission: '...',
        // break on white space, +, - characters aiming to break on whole words rather than partial words
        separator: /[\W+-/]/
      }),
      isReorder
    }

    acc.push(newMenuGroupAnchor)
    return acc
  }, [])
}
/**
 * Returns an array of all menu groups from a full restaurant menu for use in the HorizontalScrollMenu or MoreNav
 * 1. filters out groups that dont have any items
 * 2. truncates the name of the group to <= 30 characters
 * @param {MenuV2[]} menus - Array of restaurant menus
 * @returns {*} - an array of anchors for each menu group
 */
export function consolidateMenuGroups(menus: any) {
  return menus.reduce((acc: any, menu: any) => {
    const { groups } = menu
    const menuGroupAnchors = consolidateActiveMenuGroups(groups)
    acc.push(...menuGroupAnchors)
    return acc
  }, [])
}

export interface VirtualMenuNavigationBarProps {
  stickyRef: React.RefObject<HTMLDivElement>
  virtualMenus: VirtualMenu[]
  activeGroup: string | null
  navBarIntersected: boolean
}

export const VirtualMenuNavigationBar = ({
  virtualMenus,
  stickyRef,
  activeGroup,
  navBarIntersected
}: VirtualMenuNavigationBarProps) => {
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = React.useState(false)
  const [isSidebarOpen, setSidebarOpen] = React.useState(false)
  const menuNavRef = React.useRef<HTMLButtonElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const openSidebar = () => setSidebarOpen(true)
  const openSearch = () => {
    setSearchParams({ search: 'true' })
  }

  React.useEffect(() => {
    if (menuNavRef.current && navBarIntersected) {
      menuNavRef.current.focus()
    }
    if (menuNavRef.current && !navBarIntersected) {
      menuNavRef.current.blur()
    }
  }, [navBarIntersected])

  const roundButtonStyles =
    'flex items-center justify-center min-h-[48px] min-w-[48px] h-12 w-12 border-[0.75px] border-solid rounded-full border-darken-12 bg-white text-default'
  return (
    <>
      <nav
        data-testid='menu-navigation-bar'
        ref={stickyRef}
        className='sticky top-0 z-10 flex flex-row px-4 py-3 bg-lighten-80 backdrop-blur-md'
      >
        <MenuSearch
          onClose={() => {
            setSearchParams()
          }}
          isOpen={Boolean(searchParams.get('search'))}
        />
        <button
          ref={menuNavRef}
          aria-label='Menu navigation'
          disabled={!activeGroup}
          data-testid='nav-menu-button'
          onClick={() => setIsMenuDrawerOpen(true)}
          className={`flex items-center justify-between w-full border-[0.75px] border-solid rounded-full border-darken-12 mr-4 pl-5 pr-3 h-12 font-semibold type-default ${
            activeGroup ? 'text-gray-125' : 'text-gray-30'
          } bg-white`}
        >
          <div className='flex items-center gap-3'>
            <MenuIcon />
            <span data-testid='active-menu-group' className='line-clamp-1'>
              {activeGroup || 'No available menus'}
            </span>
          </div>
          <span className='transform rotate-90 flex items-center justify-center h-6 w-6 border-[0.75px] border-solid rounded-full border-darken-12 bg-gray-0 text-default'>
            <ChevronRightIcon size='sm' />
          </span>
        </button>
        <button
          aria-label='Menu search'
          data-testid='nav-search-button'
          onClick={openSearch}
          className={cx(roundButtonStyles, 'mr-2')}
        >
          <SearchIcon size='sm' />
        </button>
        <button
          aria-label='Account management and gift cards'
          data-testid='nav-sidebar-button'
          onClick={openSidebar}
          className={roundButtonStyles}
        >
          <MoreVertIcon size='sm' />
        </button>
      </nav>
      <VirtualMenuNavigationDrawer
        isOpen={isMenuDrawerOpen}
        handleClose={() => setIsMenuDrawerOpen(false)}
        virtualMenus={virtualMenus}
        activeGroup={activeGroup}
      />
      {isSidebarOpen && (
        <NavigationDrawer onClose={() => setSidebarOpen(false)} />
      )}
    </>
  )
}
