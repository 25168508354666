import cx from 'classnames'
import * as React from 'react'
import ToastLogo from '../../../../src/assets/toast-logo.svg'
import styles from './SplashScreenBanner.module.css'

export interface SplashScreenBannerProps {
  logoUrl?: string
  name?: string
}

export const SplashScreenBanner = ({
  name,
  logoUrl
}: SplashScreenBannerProps) => {
  const sharedIconStyle = cx(
    'mt-16 w-14 h-14 border border-solid rounded-lg border-gray-30',
    styles.icon
  )
  const getIcon = () => {
    if (logoUrl) {
      return (
        <img
          data-testid='restaurant-logo'
          className={sharedIconStyle}
          src={logoUrl}
          alt={name}
        />
      )
    }
    return (
      <div className={cx(sharedIconStyle, 'flex justify-center items-center')}>
        <ToastLogo data-testid='toast-logo-fallback' />
      </div>
    )
  }

  return (
    <div className='relative flex justify-center rounded-lg'>{getIcon()}</div>
  )
}
