import { CreditCard, creditCards$ } from 'cornucopia-apis'
import * as React from 'react'
import { useDDIGlobals } from '../../../../src/components/DDIGlobalsProvider'

export const useCreditCards = () => {
  const [creditCards, setCreditCards] = React.useState<CreditCard[]>([])
  const {
    restaurantConfig: { amexAccepted }
  } = useDDIGlobals()
  const amexNotAccepted = !amexAccepted

  React.useEffect(() => {
    const subscription = creditCards$.subscribe((cards) => {
      const sortedCards = cards?.sort((a, b) => {
        if (a.expired || (a.cardType === 'AMEX' && amexNotAccepted)) {
          return 1
        }
        return b.expired || (b.cardType === 'AMEX' && amexNotAccepted) ? -1 : 0
      })

      setCreditCards(sortedCards || [])
    })

    return () => subscription.unsubscribe()
  }, [amexNotAccepted, setCreditCards])

  return { creditCards }
}
