import * as React from 'react'
import { Route } from 'react-router-dom'

/* Leftover routes being rendered by opt-web or corn-menu-spa
   Render an empty fragment so we do not hit the redirect
   condition for pages being rendered by other spa's */

interface RouteConfig {
  paymentEnabled: boolean
}

export const getLegacyRoutes = ({ paymentEnabled }: RouteConfig) => {
  const legacyRoutes = [
    '/scan',
    '/tab',
    '/tab/add',
    '/tab/new',
    '/tab/confirm',
    '/confirm/:orderGuid/:checkGuid?',
    '/cart',
    '/account',
    '/edit',
    '/rejoin'
  ]

  if (paymentEnabled) {
    legacyRoutes.push('/tab/close')
  }

  return legacyRoutes.map((route: any) => {
    return <Route key={route} path={route} element={<></>} />
  })
}
