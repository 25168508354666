import { QueryResult } from '@apollo/client'
import { Maybe } from '@local/shared-types'
import { ApiData } from 'cornucopia-apis'

export type NormalizedData<T, V> = Partial<QueryResult<T>> & {
  loading: boolean
} & V

export const normalize = <T, V>(apiData: Maybe<ApiData<T, V>>) => {
  const obj = {
    ...apiData,
    ...apiData?.result,
    loading: !apiData || apiData.result?.loading,
    data: apiData?.result?.data
  }

  delete obj.result

  return obj as NormalizedData<T, V> // ts can't infer V
}
