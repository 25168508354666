import * as React from 'react'

export interface PageHeaderProps {
  header: string
  subheader?: string
  navigateTo?: string
}

export const PageHeader = ({ header, subheader }: PageHeaderProps) => {
  return (
    <div className='flex flex-col gap-2 mx-6'>
      <p data-testid='page-header' className='font-effra text-corn-header-lg'>
        {header}
      </p>
      {subheader && (
        <p
          data-testid='page-subheader'
          className='text-hint text-corn-subheader'
        >
          {subheader}
        </p>
      )}
    </div>
  )
}
