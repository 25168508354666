import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import * as React from 'react'
import { CheckReviewOption } from '../../CheckReviewOption/CheckReviewOption'
import { CloseIconDrawerHeader } from '../../CloseIconDrawerHeader/CloseIconDrawerHeader'

interface MenuAndOrderTabManagementDrawerProps {
  open: boolean
  onClose: () => void
  isGroupOrder: boolean
}

export const MenuAndOrderTabManagementDrawer = ({
  open,
  onClose,
  isGroupOrder
}: MenuAndOrderTabManagementDrawerProps) => {
  const header = isGroupOrder ? 'GROUP TAB' : 'MY TAB'

  return (
    <BottomDrawer
      data-corn-tabs
      isOpen={open}
      onClose={onClose}
      customHeader={<CloseIconDrawerHeader title={header} onClose={onClose} />}
      customRootId='tab-management-drawer-root'
      data-testid='menu-and-order-options-drawer'
    >
      <div className='pb-20 mt-8'>
        <div className='mx-6 mb-8 ml-6 p-5 border border-solid rounded-lg border-gray-30 text-gray-75'>
          Pay with your server before you leave.
        </div>
        <CheckReviewOption isGroupOrder={isGroupOrder} />
      </div>
    </BottomDrawer>
  )
}
