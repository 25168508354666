import { Selection } from '@local/shared-types'
import { CollapsibleList } from '@toasttab/do-secundo-collapsible-list'
import * as React from 'react'
import { SelectionCard } from '../../../selection-card'
import { SelectionsSubtotal } from '../ReviewCheckTable/SelectionsSubtotal'

export const ServerAddedItems = ({
  selections,
  header
}: {
  selections: Selection[]
  header: string
}) => (
  <div className='space-y-4 border border-solid rounded-lg'>
    <CollapsibleList
      key='server-added-items'
      header={
        <div className='flex justify-between w-full px-2 font-bold uppercase type-subhead'>
          {header}
        </div>
      }
      defaultCollapsed={false}
      indentList={false}
      backgroundColor='gray'
      isCollapsible
      containerStyles=''
    >
      {selections.map((selection) => (
        <div
          key={selection.externalId}
          className='border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-30'
        >
          <SelectionCard selection={selection} isCurrentUser={false} />
        </div>
      ))}
      <SelectionsSubtotal selections={selections} />
    </CollapsibleList>
  </div>
)
